/** @format */

.toggle-button-container {
  margin: 30px 0 10px 0;
  .toggle-button {
    padding: 8px;
    border: none;
    width: 90px;
    font-weight: bold;
    &:focus {
      outline: none;
    }
  }

  .toggle-active-button {
    @extend .toggle-button;
    border-bottom: 3px solid #016ff2;
    color: #016ff2;
    background-color: #fff;
    font-weight: 600;
    font-size: 14px;
  }

  .toggle-none-active-button {
    @extend .toggle-button;
    background-color: #fff;
    color: #000;
    font-weight: normal;
  }

  .toggle-button-badges {
    color: white;
    left: 8px;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    border-radius: 100%;
    font-size: 10px;
    background: #026ff2;
    text-align: center;
    line-height: 20px;
    border-radius: 10 px;
  }
}
