/** @format */

.main__dropdown--action {
  position: relative;
  display: inline-block;

  & > .btn {
    position: relative;
  }

  .dropdown-menu.show {
    display: block;
    left: -199px !important;
    right: auto !important;
    transform: translate3d(0px, -13px, 0px) !important;
    width: 200px;
    z-index: 200;
    top: 9px !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  button.action-separator-main {
    background-color: #fff;
    padding: 8px 20px;
    font-size: 12px;
    color: #000 !important;
    outline: none !important;
    border: 0px !important;
    border-radius: 0px;

    &::after {
      display: none !important;
    }

    &:hover {
      background-color: #f8f9fa !important;
    }

    &[disabled] {
      background-image: none !important;
      background-color: #f1f1f1d1 !important;
      cursor: not-allowed;
      pointer-events: inherit;

      &:hover {
        cursor: not-allowed;
      }
    }

    img {
      position: relative;
      margin-right: 15px;
    }
  }
}
