
  .menu-item a{
    padding: 5px;
    color: black;
    text-decoration: none;
  }
  .menu-item.selected a{
    border-bottom: 3px solid #007bff;
  }
  .main-menu {
    .menu-item:first-child {
      padding-left: 0!important;
    }
  }
