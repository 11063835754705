/** @format */

.filterDateRange {
  .DateRangePicker {
    width: 100%;
  }
  &.selectedBorderColor {
    #date_input_start, #date_input_end{
      color: #046ef2 !important;
    }

  }
  .DateInput{
    width: 100px !important;
  }
  .DateRangePickerInput {
    height: 38px !important;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    border-radius: 5px !important;
  }
  &.selectedDateRange {
    .DateRangePickerInput {
      color: #046ef2 !important;
      border: 1px solid #2684ff !important;
      box-shadow: 0 0 0 1px #2684ff !important;
    }
    #date_input_start,
    #date_input_end {
      color: #2684ff !important;
    }
  }
  #date_input_start,
  #date_input_end {
    font-size: 16px !important;
    padding: 0px;
    text-align: center;
    font-weight: 400 !important;
    color: #787878 !important;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .DateRangePicker_picker {
    top: 40px !important;
  }
}
