/** @format */
.properties-header-size {
  font-size: 14px;
}

.properties-container-class {
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  max-height: calc(100vh - 370px);
  overflow-x: hidden;
  overflow-y: auto;
}

.match-properties-button-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .match-properties-button {
    text-align: center;
    cursor: pointer;
    margin-right: 20px;
    .cancel {
      color: #000;
      font-size: 14px;
      width: 120px;
      border: 1px solid #eaeaea;
      background: #fff;
      padding: 8px;
      border-radius: 100px;
    }
    .selected-matches {
      @extend .cancel;
      background: #016ff2;
      border: 1px solid #016ff2;
      width: 280px;
      color: #fff;
      &:disabled {
        border: 1 px solid #fff;
        background-image: linear-gradient(#97beff, #97beff);
        color: #fff;
        border-radius: 4 px;
      }
    }
  }
}

//Gobal

button.filter__btn {
  background-color: transparent;
  border: 1px solid #046ef2;
  color: #046ef2;
  font-size: 14px;
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 0 0 !important;
  outline: none !important;

  i {
    margin-left: 5px;
  }
}

.badge-count-properties {
  background-color: #016ff2;
  color: white;
  border-radius: 100%;
  min-width: 16px;
  font-size: 11px;
  display: inline-block;
}
