/** @format */
.client-accordin{
  .card{
    border-radius: 4px !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
    border: none !important;
    background-color: #FFFFFF !important;
}
}
.client-creation-content{
    display: block;
    height: calc(100vh - 130px);
    overflow: scroll;
}
.createClientModal {
  .client-creation-content {
    height: calc(100vh - 200px);
  }
}
.addClientsButtons{
  position: fixed;
  bottom: 0px;
}
.lead-body , .client-body{
  padding: 0rem 1.25rem 1rem 1.25rem !important;
}
.postRegmodalform {
  padding: 20px;
  .row{
    margin-top: 15px ;
  }
  .feedback-row ,.or-row{
    margin-top: 40px !important;
  }
}
.createClientLeadModal{
  &.hasScroll{
    overflow-y: overlay;
    overflow-x: hidden;
  }
}
.lead-client-footer{
  padding-bottom: 25px;
}
#accordion{
  .panel-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    position: relative;
  }

  .panel-title::after {
    content: "\f107";
    color: #333;
    top: -2px;
    right: 0px;
    position: absolute;
    font-family: "FontAwesome"
  }

  .panel-title[aria-expanded="true"]::after {
    content: "\f106";
  }

  /*
   * Added 12-27-20 to showcase full title clickthrough
   */

  .panel-heading-full.panel-heading {
    padding: 0;
  }

  .panel-heading-full .panel-title {
    padding: 10px 15px;
  }

  .panel-heading-full .panel-title::after {
    top: 10px;
    right: 15px;
  }
}


.card-header{
  background-color: white !important;
  border-bottom: 0px !important;
}
.accordionHeader{
  padding: 15px;
  p{
    color: #000;
    cursor: pointer;
  }
}
.mainAddClient,.createClientModal {
  &.clientInner{
    position: relative;
    overflow-y: scroll;
    // background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    // height: calc(100vh - 14px);
    overflow-x: hidden;
    padding-bottom: 50px;
  }




  .filterInputMain {
    input {
      width: 100%;
    }

  }

  .addPageHeading {
    position: relative;

    h4 {
      position: relative;
      color: #000;
      margin-bottom: 30px;
    }
  }

  .filterInputMain {
    margin-bottom: 10px;

    input {
      width: 100% !important;
    }

    .form-control{
      border-radius: 4px!important;
      border-color: #eaeaea;
      font-size: 14px;
      &.invalid {
        border-color: red !important;
      }
    }

    .select-control div:first-child {
      border-radius: 4px!important;
      border-color: #eaeaea;
    }
  }

  .filterInputWrap {
    font-size: 14px;
    min-height: 38px;
    border-radius: 4px !important;
    border-color: #cccccc;
    z-index: 0 !important;
    position: unset !important;
    margin-bottom: 10px;

    .form-control{
      border-radius: 4px!important;
      border-color: #eaeaea;
      font-size: 14px;
      &.invalid {
        border-color: red !important;
        +.flag-dropdown{
          border-color: red !important;
        }
      }
    }

    .select-control div:first-child {
      border-radius: 4px!important;
      border-color: #eaeaea;
    }

  }

  .basicInfoContainer{
    /*display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7,1fr);
    width: 650px;
    grid-column-gap: 22px;*/
  }

  .basicInfoContainer label{
    font-size: 14px;
    margin-bottom: 5px !important;
  }

  .mailAddressContainer{
    /*display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3,1fr);
    width: 650px;
    grid-column-gap: 22px;*/
  }
  .addressGrid{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  textarea {
    resize: none;
  }
  .mailAddressContainer label{
    font-size: 14px;
    margin-bottom: 5px !important;
  }
  .permAddressContainer label{
    font-size: 14px;
  }
  .clientMainContainer{
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
    background-color: #FFFFFF !important;
    .css-26l3qy-menu{
      position: absolute !important;
      z-index: 999999 !important;
    }
    .css-g1d714-ValueContainer{
      z-index: 9999 !important;
    }

  }
  .headers{
    font-size: 22px;
  }
  .calenderContainer {
    background: white;
    position: absolute !important;
    margin-top: 35px !important;
    top: unset !important;
    width: 100%;
    left: 0;
    button[data-id="button-icon-element"]:disabled{
      color: rgba(215,217,226,1) !important;
      cursor: default;
      background: transparent;
    }
  }
  .registerButtons{
    box-shadow:inset 0px .1px 0px #a2a2a2;
    display: grid;
    grid-template-columns: 100px 100px !important;
    column-gap: 2px !important;
  }

  .clientDateContainer {
    position: relative;
    .select-control {
      // padding: 5px;
      border-radius: 4px !important;
      border-color: #eaeaea;
      font-size: 14px;
    }
    span{
      font-size: 14px;
      color: #6c757d;
    }
  }
.cancelClient , .registerClient{
  margin-top: 10px;
}
.createClientModal {
  position: relative;

  .modal-body {
    padding: 0px;

    .padding__modal {
      padding: 15px;
    }
  }

  .addPageHeading {
    padding: 15px 20px !important;
    background: #f2f2f2 !important;
    color: #454b4d !important;
    justify-content: space-between;
    border-bottom: none;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  &.hasScroll{
    height: calc(100vh - 90px);
    overflow-y: overlay;
    overflow-x: hidden;
  }
}
}
.invalidSelect {
  border-color: red !important;
}

.react-tel-input {
  min-height: 38px;
}

.react-tel-input .flag-dropdown.open {
  z-index: 99999992;
}