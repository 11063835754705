.makeStyles-root-12ReferMOdalSearchBtn{
   
    cursor: pointer;
    height: max-content;
    display: block;
    opacity: 1;
    padding: 5px;
    background: rgb(2, 111, 242);
    text-align: center;
    border-radius: 5px;
}