/** @format */
$hourColSize: 60;
$hourColSizeUnit: px;

$borderSize: 1;
$borderStyle: solid;
$borderColor: #ececec;

$noOfVisibleCols: 13;

$timeLapseColWidth: 30;

$rowSize: 40;

:root {
  --gutter: 0px;
}

.full {
  height: 100%;
}
.schedular-main {
  display: grid;
  grid-template-columns: $timeLapseColWidth + $hourColSizeUnit 100%;
  position: relative;
}
.slotContainer {
  width: (($noOfVisibleCols * ($hourColSize))) + $borderSize + ($hourColSizeUnit);
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.slotWrapper {
  display: grid;
  grid-gap: calc(var(--gutter) / 2);
  // grid-template-columns: repeat(24, ($hourColSize + $hourColSizeUnit));
  grid-template-rows: minmax(100%, 100%);
  grid-auto-flow: column;
  grid-auto-columns: calc(50px - var(--gutter) * 2);
  position: relative;
}

.slotCol {
  scroll-snap-align: start;
  padding: calc(var(--gutter) / 2 * 1.5);
  flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  background: #fff;
  //   border-right: ($borderSize + $hourColSizeUnit) solid;

  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: $rowSize + $hourColSizeUnit;
}
.slot {
  border: $borderSize + $hourColSizeUnit $borderStyle $borderColor;
  border-bottom: none;
}

.slot:nth-child(odd) {
  border-right: none;
}
.slot:nth-child(even) {
  border-right: none;
}
.slot:nth-last-child(2) ~ .slot {
  border-bottom: $borderSize + $hourColSizeUnit $borderStyle $borderColor;
}
.slotCol:nth-last-child(1) > .slot {
  border-right: $borderSize + $hourColSizeUnit $borderStyle $borderColor;
}

.selectedSlotRange {
  position: absolute;
  font-size: 9px;
  width: max-content;
  text-align: center;
  width: $hourColSize + $hourColSizeUnit;
  margin-top: -15px;
}
.head {
  display: grid;
  // grid-template-columns: repeat(24, ($hourColSize + $hourColSizeUnit));
  grid-auto-rows: $rowSize + $hourColSizeUnit;
  align-items: center;
}

.headCol {
  font-size: 10px;
  text-align: center;
  position: relative;
  .tick {
    position: absolute;
    z-index: 9;
    height: 100ch;
    background: #026ff2;
    width: 1px;
    top: 30px;
    &::before {
      content: '\25cf';
      font-size: 20px;
      width: 15px;
      height: 15px;
      color: #026ff2;
      top: -18px;
      left: -7px;
      position: absolute;
    }
  }
}
.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */
}
.leftNavigator {
  position: absolute;
  top: 12px;
}

.rightNavigator {
  position: absolute;
  top: 12px;
  left: ((($noOfVisibleCols * ($hourColSize))) + $borderSize + $timeLapseColWidth) +
    ($hourColSizeUnit);
}
.timeLapse {
  font-size: 10px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: $rowSize + $hourColSizeUnit;
  margin-top: 35px;
}
.top {
  border-top: 1px solid black !important;
}
.bottom {
  border-bottom: 1px solid black !important;
}
.left {
  border-left: 1px solid black !important;
}
.right {
  border-right: 1px solid black !important;
}

// Filters styles

$filterBorder: 1px solid #e8e9eb;
$filterBorderRadius: 5px;
$filtersHeight: 36px;

.filterMain {
  display: flex;
  margin-left: 30px;
}
.filterContainer {
  display: flex;
  width: fit-content;
  border: $filterBorder;
  border-radius: $filterBorderRadius;
}
.filterButton {
  font-size: 12px;
  border-right: $filterBorder;
  padding: 8px;
  cursor: pointer;
}
.filterButton:last-child {
  border-right: 0;
}
.filterCalenderContainer {
  display: flex;
  width: 250px;
  border: $filterBorder;
  border-radius: $filterBorderRadius;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-left: 20px;
  position: relative;
  .calNavigator {
    width: 30px;
    text-align: center;
    height: fit-content;
    cursor: pointer;
    font-size: 16px;
  }
  .calContainer {
    display: flex;
    align-content: flex-start;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    border-right: $filterBorder;
    border-left: $filterBorder;
  }
}
.calenderContainer {
  border: $filterBorder;
  background: white;
  position: absolute;
  // top: $filtersHeight;
  top: 0px;
  z-index: 99;
  box-shadow: rgb(204, 202, 202) 0px 1px 2px 0px;
  border: $filterBorder;
  padding: 5px;
}
// Filters styles end
.leftDragZone {
  position: absolute;
  height: calc(100% - 40px);
  width: 20px;
  top: ($rowSize + $hourColSizeUnit);
  left: 10px;
  cursor: w-resize;
}

.rightDragZone {
  position: absolute;
  height: calc(100% - 40px);
  width: 20px;
  top: ($rowSize + $hourColSizeUnit);
  left: (($noOfVisibleCols * ($hourColSize))) + $borderSize + 30 + ($hourColSizeUnit);
  cursor: e-resize;
}
.slotDetailsDiary {
  width: 100%;
  height: 100%;
}
