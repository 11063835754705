.reports-table {
  height: 100%;
  width: 100%;
  position: relative;
  table {
    /* border-collapse: collapse; */
    overflow-x: scroll;
    display: block;
    /* text-align: left; */
    /* position: relative; */
    border-collapse: separate;
    border-spacing: 0;
    thead tr {
      background-color: #f6f7fa;
      position: sticky;
      top: 0px;
      z-index: 0.5;
    }
    th {
      border: none !important;
      border-radius: 2px;
      text-transform: uppercase;
      font-weight: bold !important;
      height: 50px;
      font-size: 12px !important;
      color: #1d1e27;
      padding: 10px;
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
      display: inline;
    }
    td {
      border: none !important;
      border-bottom: 1px solid #f2f2f4 !important;
      color: #50525e !important;
      padding: 4px 10px;
      height: 50.5px !important;
      font-size: 11px;
    }
  }
}

.linkStylePropsure {
  color: #046ef2;
}

.hoverForTr:hover img {
  opacity: 1 !important;
}
.hoverForTr:hover .phone__button {
  opacity: 1 !important;
}


.compact-table {
  height: 100%;
  width: 100%;
  position: relative;
  table {
    /* border-collapse: collapse; */
    overflow-x: scroll;
    display: block;
    /* text-align: left; */
    /* position: relative; */
    border-collapse: separate;
    border-spacing: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    div {
      position: sticky;
      top: 0px;
      z-index: 0.5;
      background-color: #cdd3d7;
      width: fit-content;
      thead{
        tr {
          text-align: center;
        }
      }
    }
    th {
      border: none !important;
      border-radius: 2px;
      font-weight: bold !important;
      height: 40px;
      font-size: 14px;
      color: white;
      background-color: #1877f2;
      border-radius: 10px;
    }

    tbody {
      height: 100%;
      text-align: center;
      overflow-y: scroll;
      overflow-x: hidden;
      display: inline;
      .index-td {
        background-color: #3794ff;
        font-weight: bolder;
        color: white;
        border-radius: 10px;
      }
      .index-td-dull {
        background-color: #6fa2e3;
        font-weight: bolder;
        color: white;
        border-radius: 10px;
      }
      .td-border {
        border: 2px solid #c4e0ff;
        border-radius: 10px;
      }
      .td-name {
        text-align: left;
      }
      .red-percent {
        background-color: #e06666;
        border-radius: 10px;
        font-weight: bolder;
        color: white;
      }
      .green-percent {
        background-color: #93c47d;
        border-radius: 10px;
        font-weight: bolder;
      }
      .yellow-percent {
        background-color: #e7d173;
        border-radius: 10px;
        font-weight: bolder;
      }
    }
    td {
      border: none;
      height: 40px !important;
      font-size: 14px;
    }
  }
}

.ccmc-filters {
  padding: 1%;
}

.ccmc-filter {
  padding: 1%;
}

.ccmcDateRange {
  .DateRangePicker {
    width: 100%;
    text-align: center;
  }
  .DateRangePickerInput {
    height: 38px !important;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    border: 1px solid #dbdbdb !important;
    border-radius: 5px !important;
  }
  &.selectedDateRange {
    .DateRangePickerInput {
      color: #046ef2 !important;
      border: 1px solid #2684ff !important;
      box-shadow: 0 0 0 1px #2684ff !important;
    }
    #project_date_input_start,
    #project_date_input_end {
      color: #2684ff !important;
    }
  }
  #project_date_input_start,
  #project_date_input_end {
    font-size: 16px !important;
    padding: 0px;
    text-align: center;
    font-weight: 400 !important;
    color: #787878 !important;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .DateRangePicker_picker {
    top: 40px !important;
  }
}

.ccmc-clearFilter {
  display: flex;
}

.export-btn {
  background-color: #016ff2;
  height: 33px;
  width: 100%;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none !important;
  color: #fff;
  border-radius: 4px;
}


.summary-table {
  height: 100%;
  width: 38vw;
  position: relative;
  margin-left: 20vw;
  margin-top: 5vh;
  table {
    /* border-collapse: collapse; */
    overflow-x: hidden;
    display: block;
    /* text-align: left; */
    /* position: relative; */
    border-collapse: separate;
    border-spacing: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    div {
      position: sticky;
      top: 0px;
      z-index: 0.5;
      background-color: #cdd3d7;
      width: fit-content;
      thead{
        tr {
          text-align: center;
        }
      }
    }
    th {
      border: none !important;
      border-radius: 2px;
      font-weight: bold !important;
      height: 40px;
      font-size: 14px !important;
      color: white;
      background-color: #1877f2;
      border-radius: 10px;
    }

    tbody {
      text-align: center;
      overflow-y: scroll;
      overflow-x: hidden;
      display: inline;
      .idx-td {
        background-color: #1877f2;
        color: white;
        border-radius: 10px;
        font-weight: bolder;
      }
      .idx-td-dull {
        background-color: #6ba1e4;
        color: white;
        border-radius: 10px;
        font-weight: bolder;
      }
      .td-border {
        border: 2px solid #c4e0ff;
        border-radius: 10px;
        padding-left: 1%;
      }
      .td-name {
        text-align: left;
      }
      .red-percent {
        background-color: #e06666;
        border-radius: 10px;
        color: white;
        font-weight: bolder;
      }
      .green-percent {
        background-color: #93c47d;
        border-radius: 10px;
        font-weight: bolder;
      }
      .yellow-percent {
        background-color: #e7d173;
        border-radius: 10px;
        font-weight: bolder;
      }
    }
    td {
      border: none;
      height: 40px !important;
      font-size: 14px;
    }
  }
}


.summary-table-2 {
  height: 100%;
  width: 38vw;
  position: relative;
  margin-left: 20vw;
  margin-top: 5vh;
  table {
    /* border-collapse: collapse; */
    overflow-x: hidden;
    display: block;
    /* text-align: left; */
    /* position: relative; */
    border-collapse: separate;
    border-spacing: 1em 0.5em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    div {
      position: sticky;
      top: 0px;
      z-index: 0.5;
      background-color: #cdd3d7;
      width: fit-content;
      thead{
        tr {
          text-align: center;
        }
      }
    }
    th {
      border: none !important;
      border-radius: 2px;
      font-weight: bold !important;
      height: 40px;
      font-size: 14px !important;
      color: white;
      background-color: #1877f2;
      border-radius: 10px;
    }

    tbody {
      text-align: center;
      overflow-y: scroll;
      overflow-x: hidden;
      display: inline;
      .idx-td {
        background-color: #1877f2;
        color: white;
        border-radius: 10px;
        font-weight: bolder;
      }
      .idx-td-dull {
        background-color: #6ba1e4;
        color: white;
        border-radius: 10px;
        font-weight: bolder;
      }
      .td-border {
        border: 2px solid #c4e0ff;
        border-radius: 10px;
        padding-left: 1%;
      }
      .td-name {
        text-align: left;
      }
      .red-percent {
        background-color: #e06666;
        border-radius: 10px;
        color: white;
        font-weight: bolder;
      }
      .green-percent {
        background-color: #93c47d;
        border-radius: 10px;
        font-weight: bolder;
      }
      .yellow-percent {
        background-color: #e7d173;
        border-radius: 10px;
        font-weight: bolder;
      }
    }
    td {
      border: none;
      height: 40px !important;
      font-size: 14px;
    }
  }
}

.mg-table {
  height: 100%;
  width: 100%;
  position: relative;
  table {
    /* border-collapse: collapse; */
    overflow-x: scroll;
    display: block;
    /* text-align: left; */
    /* position: relative; */
    border-collapse: separate;
    border-spacing: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    div {
      position: sticky;
      top: 0px;
      z-index: 0.5;
      background-color: #cdd3d7;
      width: fit-content;
      thead{
        tr {
          text-align: center;
        }
      }
    }
    th {
      border: none !important;
      border-radius: 2px;
      font-weight: bold !important;
      height: 40px;
      font-size: 14px !important;
      color: white;
      background-color: #1877f2;
      border-radius: 10px;
    }

    tbody {
      height: 100%;
      text-align: center;
      overflow-y: scroll;
      overflow-x: hidden;
      .index-td {
        background-color: #3794ff;
        font-weight: bolder;
        color: white;
        border-radius: 10px;
      }
      .index-td-dull {
        background-color: #6fa2e3;
        font-weight: bolder;
        color: white;
        border-radius: 10px;
      }
      .td-border {
        border: 2px solid #c4e0ff;
        border-radius: 10px;
      }
      .td-name {
        text-align: left;
      }
      .red-percent {
        background-color: #e06666;
        border-radius: 10px;
        font-weight: bolder;
        color: white;
      }
      .green-percent {
        background-color: #93c47d;
        border-radius: 10px;
        font-weight: bolder;
      }
      .yellow-percent {
        background-color: #e7d173;
        border-radius: 10px;
        font-weight: bolder;
      }
    }
    td {
      border: none;
      height: 40px !important;
      font-size: 14px;
    }
  }
}

.clickable-div {
  &:hover {
    background-color: #0059c5;
  }
}

.custom-control {
  z-index: 0 !important;
}