/** @format */

/** Responsive => For all screens **/

.DateInput {
  width: 100px !important;
}

.DateInput_input {
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 5px !important;
}

.detail-main-cursor {
  cursor: pointer;
}

/** Responsive => End **/

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 0px !important;
}

.DateRangePickerInput_clearDates {
  padding: 12px !important;
  margin: 0px;
}

.DateRangePickerInput_clearDates {
  display: none;
}

.date-filter-default {
  border: 1.9px solid #dbdbdb !important;
  padding: 0 !important;
  margin-left: 15px;
  border-radius: 5px;
  max-width: 24% !important;
}

.date-filter {
  border: 1.9px solid #0f73ee;
  padding: 0 !important;
  border-radius: 5px;
  max-width: 24% !important;
}

.DateRangePickerInput__showClearDates {
  padding-right: 0px !important;
}

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: none !important;
}

.defaultBorderColor {
  border: 1px solid #ccc !important;
  font-weight: 300;
  border-radius: 3px;
}

.customStyle {
  background-color: #fff;
  color: #0f73ee;
  height: 35px;
  padding-left: 10px;
  border: 1px solid #0f73ee;
  border-radius: 5px;
  width: 100%;
  outline: none;
}

.Id_textfield {
  height: 38px;
  padding-left: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 100% !important;
  outline: none;
}

.selectedBorderColor {
  border: 1px solid #046ef2 !important;
  font-weight: 300;
}

.hover__for__btns {
  &:hover {
    background-color: #046ef21f;
    .cus-z-index {
      & > div {
        opacity: 1;
      }
    }
  }

  &:focus-within,
  &:focus {
    background-color: #046ef21f;
    .cus-z-index {
      z-index: 1;
      & > div {
        opacity: 1;
      }
    }
  }
}
.investment-lead-page-wrap {
  .cus-z-index {
    // z-index: 111;
    position: sticky;
    right: 0;
    padding: 0px !important;

    & > div {
      opacity: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      margin: 5px 0px;
      width: 115px;
      //background-color: #e0edfd;
    }
  }

  .refreshPage-main-wrap {
    position: absolute;
    top: -75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    right: 60px;
  }
  .message-wrap {
    box-shadow: 0 0 5px #a2a2a2;
    padding: 10px;
    border-radius: 5px;
  }
  .reload-btn {
    font-weight: 700;
    transition: 0.3s ease-in-out;
  }
  .reload-btn:hover {
    cursor: pointer;
    color: #0f73ee;
  }
}

.focus__tr {
  background-color: #046ef21f;
  .cus-z-index {
    z-index: 1;

    & > div {
      opacity: 1;
    }
  }
}

.phone__button {
  position: relative;
  padding: 5px 10px;
  background-color: #1173ef;
  outline: none !important;
  border-radius: 5px;
  box-shadow: 0 0 0 0 !important;

  img {
    width: 19px;
    position: relative;
    top: -3px;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }
}

.date-wraper {
  display: flex;
  margin-top: 20px;
  transition: 0.3s ease-in-out;
  justify-content: center;
}

.date-wraper-followup {
  display: flex;
  margin-top: 20px;
  transition: 0.3s ease-in-out;
}
.time-date-picker-cus-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal-content {
    width: 670px !important;
  }
}
.follow-up-popup {
  width: 100% !important;
  max-width: 480px !important;
}

.current-time {
  display: grid;
  grid-template-columns: auto auto;
}

.datepicker-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.btn-cus-add {
  border-radius: 10px;
  background: #fff;
  color: #046ef2;
  border: 1px solid #046ef2 !important;
  transition: 0.3s ease-in-out;
  font-size: 12px;
}
.btn-cus-add:hover {
  cursor: pointer;
  background: #046ef2;
  color: #fff;
}
.user-status-width {
  width: 7%;
}
.user-status-width-15 {
  width: 15%;
}
.inv-lead-table {
  /* border-collapse: collapse; */
  overflow-x: scroll;
  display: block;
  height: calc(100vh - 205px);
  /* text-align: left; */
  /* position: relative; */
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  &.changeHeightForAssign {
    height: calc(100vh - 408px);
  }

  &.changeHeight {
    height: calc(100vh - 360px);
  }
  &.changeHeightOfAssign {
    height: calc(100vh - 276px);
  }
}

.inv-lead-table thead tr {
  background-color: #f6f7fa;
  background-color: #f6f7fa;
  position: sticky;
  top: 0px;
  z-index: 2;
}
.inv-lead-table th {
  border: none !important;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: bold !important;
  height: 50px;
  font-size: 12px !important;
  color: #1d1e27;
  padding: 10px;
}
.inv-lead-table thead th:last-child {
  position: sticky;
  right: 0;
  z-index: 222;
}
.inv-lead-table tbody {
  overflow-y: scroll;
  overflow-x: hidden;
}
.inv-lead-table td {
  border: none !important;
  border-bottom: 1px solid #f2f2f4 !important;
  color: #50525e !important;
  padding: 4px 10px;
  height: 50.5px !important;
  font-size: 11px;
}
.investment-lead-page-wrap {
  .action-cus {
    background-color: #fafafa;
    border-radius: 5px;
    padding: 7px 5px;
    color: #1173ef;
    font-size: 12px;
    width: 70px;

    &::after {
      display: none;
    }

    i {
      margin-left: 2px;
    }
  }
}
.follow-up-cus {
  background-color: #fec107;
  color: #000;
  padding: 7px 5px;
  border-radius: 5px;
  border-color: #fec107;
  font-size: 12px;
}
.reject-cus {
  background-color: #dc3647;
  padding: 7px 5px;
  border-radius: 5px;
  color: #fff;
  border-color: #dc3647;
  font-size: 12px;
}
.reject-cus:hover {
  color: #fff;
}

.follow-up-popup {
  width: 100% !important;
  max-width: 600px !important;
}

.input-width {
  width: 320px;
}

.button-padding {
  padding: 4px;
}

.reject-popup {
  width: 100% !important;
  max-width: 700px !important;
}

.closed-radio-button-container {
  display: flex;
  flex-direction: row;
}

.closed-radio-button-container .radio-button-space {
  margin-top: 6px;
}

.close-icon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.close-icon-container .close-icon {
  color: #909090;
  font-size: 22px;
  cursor: pointer;
}

.closed-lost-button-container {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}

.closed-lost-button-container .closed-lost-button-success {
  background: #1073ee;
  border: 2px solid #1073ee;
  border-radius: 50px;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  height: 35px;
  padding: 2px;
  width: 150px;
  margin: 0 auto;
}

.action-container-main {
  position: absolute;
  right: 220px;
  top: 0;
  background: #fff;
  width: 230px;
  border: 1px solid #d9d9da;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.1607843137254902);
  padding: 15px;
  border-radius: 5px;
}

.icon-main {
  width: 18px;
  height: 18px;
}

span.statusText {
  border: 1px solid;
  border-radius: 11px;
  font-size: 10px;
  padding: 2px 5px;
  margin-left: 5px;
}

.action-content-main {
  margin-left: 14px;
  color: #000;
}

.modal-action-size {
  max-width: 500px !important;
  width: 100% !important;
}

.react-datepicker__input-container input {
  border: 1px solid #ddd !important;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  &:focus {
    outline: none;
  }
}

.z-index-value {
  z-index: 111;
}

.z-index {
  z-index: 9;
}

.attachment-popup-size {
  margin: 0 auto;
  width: 600px !important;
}

.attachment-popup {
  width: 100%;
  max-width: 700px !important;
}

.attach-model-body {
  background-color: #f5f5f5;
}

.attachment-main-container {
  padding: 0 10px 0 10px;

  .attachment-modal-header {
    margin-bottom: 20px;
    .attachment-header-title {
      font-size: 22px;
      font-weight: bold;
      color: #222222;
    }
  }

  .main-attachment-body {
    margin-top: 10px;
    .attachment-body-edges {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(192, 192, 192)' stroke-width='3' stroke-dasharray='5%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      padding: 20px;
      display: inline-block;
      width: 100%;
      border-radius: 5px;
      .attachment-body {
        padding: 30px 0 30px 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .upload-icon-size {
          width: 35px;
          height: 35px;
        }
        .drag-drop-text {
          font-size: 13px;
          color: #333333;
          padding: 8px 0 0 0;
        }
        .attached-file-text {
          font-size: 14px;
          font-weight: 600;
          color: #016ff2;
          cursor: pointer;
        }
      }
      &.dropdown-overlay {
        background-color: #f3f3f3cc;
        height: 197px;
        opacity: 0.4;
        .drag-drop-overlay-text {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex: 1;
          height: 100%;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .attachment-upload-container {
    margin: 40px 0 5px 0;
    text-align: center;
    .attachment-upload-button {
      background-color: #016ff2;
      color: #fff;
      border-radius: 50px;
      width: 150px;
      line-height: 35px;
      border: none;
    }
  }
  .attached-container {
    width: 100%;
    margin-top: 15px;
    overflow-y: auto;
    height: calc(100vh - 498px);
  }
}
.time-picker-wrap {
  width: 35%;
}

.follow-up-modal {
  margin: 0 auto;
  width: 700px !important;
}

.follow-up-active {
  padding: 6px;
  width: 250px;
  border-radius: 20px;
  text-align: center;
  &.active-link {
    background: #046ef2;
    color: #fff;
    margin-right: 10px;
  }
  &.none-active {
    background: white;
    border: 1px solid #e9e9e9;
    margin-right: 10px;
    cursor: pointer;
  }
}

.sortingBody {
  position: relative;

  .btn {
    display: block;
    width: 100%;
    padding: 12px;
    border-bottom: 1px solid #e8e8e8 !important;
    border-radius: 0px;
    outline: none;
    box-shadow: 0 0 0 0;
    font-size: 14px;
    margin-top: 0px;

    &.active {
      color: #046ef2;
      font-weight: bold;
    }
  }
}

.sortingModal {
  .modal-content {
    max-width: 60%;
    margin: auto !important;
  }

  .marg {
    margin-bottom: 0px !important;
  }

  .modal__footer {
    position: relative;
    text-align: center;

    .btn {
      position: relative;
      background-color: #6c757d;
      color: #fff;
      margin-top: 15px;
      font-size: 14px;
      padding: 8px 30px;
      letter-spacing: 0.4px;
      outline: none;
      box-shadow: 0 0 0 0;
    }
  }
}

.filter__area {
  position: relative;
  z-index: 22;
}

.pr-Or {
  margin-right: 4px;
}

.filter__Top {
  border: 1px solid #cccccc;
  border-radius: 4px;

  &.active__filter {
    border: 1px solid #046ef2 !important;
  }
  .css-aql0vd-control,
  .css-yk16xz-control {
    border: 0px !important;
    border-color: transparent !important;
    min-height: 36px;
    outline: none !important;
    box-shadow: none !important;
  }
}

button.filter__btn {
  background-color: transparent;
  border: 1px solid #046ef2;
  color: #046ef2;
  font-size: 14px;
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 0 0 !important;
  outline: none !important;

  i {
    margin-left: 5px;
  }
}

.sort__img__dropDown {
  display: inline-block;

  .sort__img {
    position: relative;
    margin-left: 15px;
    font-size: 14px;
    outline: none !important;
    box-shadow: 0 0 0 0 !important;
    border: 0px solid #d6d6d5 !important;
    padding: 0px 0px;
    border-radius: 5px;
    color: #b0b0b0;

    img {
      position: relative;
      width: 20px;
    }
  }

  .dropdown-menu.show {
    width: 200px;
    top: 10px !important;
    padding: 0px;

    .btn {
      text-align: left;
      border-top: 1px solid #eff0f0 !important;
      font-size: 14px;
      padding: 8px 10px;
      margin: 0px;
      outline: none !important;

      &:nth-child(1) {
        border-top: 0px !important;
      }

      &.active {
        color: #046ef2;
      }
    }
  }
}

.notch__center {
  text-align: center;
  color: #006ff2;
}

span.newLead {
  display: inline-block;
  font-size: 10px;
  border: 1px solid #0084f2 !important;
  color: #0084f2;
  border-radius: 20px;
  padding: 0px 6px;
  font-weight: bold;
  margin-left: 5px;
  position: relative;
}

.button {
  padding: 15px;
  color: #fff;
  display: inherit;
  font-weight: bold;
  flex-direction: row;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  .action {
    background-color: #1173ef;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: inherit;
    width: 120px;
  }
  .follow-up {
    background-color: #fec107;
    color: #000;
    padding: inherit;
    width: 120px;
  }
  .reject {
    background-color: #dc3647;
    padding: inherit;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 120px;
  }
}

.comment-popup-body-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .popup-body-header-title {
    font-size: 22px;
    font-weight: bold;
    color: #000;
  }
  .popup-body-header-icon {
    color: #909090;
    font-size: 22px;
    cursor: pointer;
  }
}

.comment-main-body {
  overflow-y: auto;
  height: calc(100vh - 324px);
  margin-bottom: 15px;
  .comment-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    .no-comments-title {
      color: #96969a;
      font-weight: bold;
      font-size: 23px;
    }
  }
  .not-found-image {
    width: 350px;
    height: 300px;
  }
}

.general-main-body {
  overflow-y: auto;
  margin-bottom: 15px;
}

.text-area-container {
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  resize: none;
  border: 1px solid #dededf !important;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
}

textarea:focus {
  outline: none;
}

.comment-button {
  background-color: #016ff2;
  color: #fff;
  text-align: center;
  width: 150px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  border: none;
  line-height: 35px;
  margin-bottom: 20px;
}

.comment-button:disabled {
  background-image: linear-gradient(#97beff, #97beff);
  border-radius: 100px;
  cursor: not-allowed;
}

.comment-button:disabled:hover {
  background-image: linear-gradient(#97beff, #97beff);
  border-radius: 100px;
  cursor: not-allowed;
}

.comment-button:focus {
  outline: none;
}

.share-lead-container {
  margin: 3px 5px 3px 5px;
  .share-lead-title {
    font-size: 22px;
    font-weight: bold;
    color: #212122;
  }
  .share-lead-subtitle {
    font-size: 16px;
    font-weight: normal;
    color: #373737;
  }

  .search-box {
    margin: 0px 0 0px 0;
    display: grid;
    grid-template-columns: auto auto;
    .search-box-style {
      border: 1px solid #a3a7ac;
      padding: 7px;
      border-radius: 3px;
      width: 100%;
      font-size: 15px;
      &:focus {
        outline: none;
      }
    }
    .dropdown-container {
      margin-left: 20px;
    }
  }
  .agents-main-area {
    height: 400px;
    overflow: scroll;
    .agents-grid-view {
      display: grid;
      grid-template-columns: auto auto;
      .checkbox-container {
        margin-right: 10px;
      }
      .grid-item-space {
        margin: 10px 0 10px 0;
      }
      .agents-grid-text {
        color: #383939;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .grid-loader-view {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
    }
  }
}

.task-image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .container-image {
    width: 250px;
    height: 250px;
  }
}

.diary-task-area {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
}

.gQGwkZ {
  border: 1px solid #dddddd !important;
  border-radius: 5px !important;
  padding: 10px !important;
  height: 2.8rem !important;
}

.task-button {
  margin-top: 10px;
  margin: 0 auto;
  color: #fff;
  background-color: #016ff2;
  padding: 10px;
  margin-top: 15px;
  border-radius: 50px;
  width: 120px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #0052b4;
  }
}

.task-button-disabled {
  margin-top: 10px;
  margin: 0 auto;
  color: #fff;
  background-image: linear-gradient(#97beff, #97beff);
  padding: 10px;
  margin-top: 15px;
  border-radius: 50px;
  width: 120px;
  text-align: center;
  cursor: not-allowed;
}

.closed-content-container {
  margin-top: 90px;
  .closed-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .closed-button-style {
      background: #fff;
      border: 2px solid #1073ee;
      border-radius: 50px;
      color: #1073ee;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 10px;
      width: 300px;
      margin-bottom: 20px;
      &:focus {
        outline: none;
      }
    }
  }
  .closed-success-container {
    display: flex;
    flex-direction: row;

    .closed-success-button {
      background: #1073ee;
      border: 2px solid #1073ee;
      border-radius: 50px;
      color: #fff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 22px;
      padding: 5px;
      width: 250px;
      margin-bottom: 20px;
      margin: 0 auto;
      &:focus {
        outline: none;
      }
    }
    .closed-back-button {
      margin-right: 10px;
      background: #fff;
      border: 1px solid #dddddd;
      border-radius: 50px;
      color: #000;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
      padding: 5px;
      width: 100px;
      margin-bottom: 20px;
      position: absolute;
      bottom: 0;
      left: 25px;
      &:focus {
        outline: none;
      }
    }
  }
}

.closed-radio-button-container {
  display: flex;
  flex-direction: row;

  .radio-button-space {
    margin-top: 6px;
  }
}

.closed-lost-button-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  .closed-lost-button-success {
    background: #dc3647;
    border: 2px solid #dc3647;
    border-radius: 50px;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    height: 35px;
    padding: 2px;
    width: 250px;
    margin: 0 auto;
    &:focus {
      outline: none;
    }
  }

  .closed-won-button-success {
    background: #1073ee;
    border: 2px solid #1073ee;
    border-radius: 50px;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    height: 35px;
    padding: 2px;
    width: 250px;
    margin: 0 auto;
    &:focus {
      outline: none;
    }
  }
  .closed-lost-button-back {
    margin-right: 10px;
    background: #fff;
    border: 1px solid #dddddd;
    border-radius: 50px;
    color: #000;
    height: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    padding: 5px;
    width: 150px;
    height: 35px;
    &:focus {
      outline: none;
    }
  }
}

.feedback-container {
  margin-top: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  .feedback-area {
    width: 100%;
    margin-top: 10px;
    max-height: 370px;
    overflow: auto;
    .space-between {
      font-size: 13px;
      margin: 5px;
      cursor: pointer;
      width: auto;
      float: left;
      padding: 5px 15px;
      border: 1px solid;
      border-radius: 14px;
    }
  }
}

.feedback-popup-width {
  width: 100%;
  max-width: 700px !important;
}

.feedback-width {
  width: 700px !important;
}

.center-align-feedback {
  margin: 0 auto;
}

.new-modal-content {
  margin: 0 auto;
  max-width: 990px !important;
}

.deletion-table {
  border-collapse: collapse;
  overflow-x: scroll;
  display: block;
}

.deletion-table th {
  border: none !important;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 12px !important;
  color: #1d1e27;
  background-color: #f6f7fa;
  padding: 10px;
  text-align: left;
}

.deletion-table thead,
.deletion-table tbody,
.deletion-table tfoot {
  display: block;
}

.deletion-table tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 280px);
}

.deletion-table td {
  border: none !important;
  border-bottom: 1px solid #f2f2f4 !important;
  color: #50525e !important;
  padding: 7px;
  text-align: left;
  height: 25px;
  font-size: 12px;
  border: 1px solid #dee2e6;
}

.modal-content {
  margin-top: 20px !important;
  width: 100% !important;
  margin-left: 0px !important;
}

.assign-task-modal {
  margin: 0 auto;
  width: 580px !important;
}

.notch__center {
  text-align: center;
  color: #006ff2;
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.followup-today {
  bordercolor: #036ef2;
  color: #036ef2;
}

// Global
.checkbox-position {
  position: relative;
  top: 3px;
  margin-right: 8px;
  cursor: pointer;
}
.projectLeadsTableContainer {
  .tableMultiSelectCB {
    margin: 0px 10px;
  }
}
