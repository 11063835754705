/** @format */

.blur-background {
  background: rgba(0, 0, 0, 0.1);
}

.black-color {
  color: #000;
}
.lead-creation-container {
  padding: 0;
  margin: 0 10px 0 10px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #212121;
    margin: 20px 0 30px 0;
  }
  .select-client-wrapper {
    padding: 5px;
    background-color: #fff;
    border-radius: 4px;
    color: #808080;
    border: 1px solid #cccccc;
    padding-left: 9px;
    cursor: pointer;
  }
  .client-wrapper-error {
    @extend .select-client-wrapper;
    border: #ff2420;
    border: 1px solid #ff2420 !important;
  }
  .subtitle {
    font-size: 14px;
    font-weight: bold;
    color: #4c4c4c;
    margin-bottom: 10px;
  }
  .lead-creation-button {
    background-color: #016ff2;
    color: #fff;
    text-align: center;
    width: 150px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    line-height: 40px;
    margin-bottom: 20px;
    &:focus {
      outline: none;
    }
    &:disabled:hover {
      background-image: linear-gradient(#97beff, #97beff) !important;
      border-radius: 100px;
      cursor: not-allowed;
    }
  }
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.close-icon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
