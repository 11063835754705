/** @format */

/** Responsive => For all screens **/

@media screen and (max-width: 1366px) {
  .btn-wrapper {
    left: 14.9% !important;
  }
  .btn-wrapper-right {
    left: 62px !important;
  }
  .collapseLeftSideFixedRightWidth {
    flex: 0 0 96.666667% !important;
    max-width: 92% !important;
  }
  .collapseChild {
    padding-right: 13px !important;
  }

  .collapseMenu {
    padding-right: 0px !important;
    padding-left: 10px !important;
  }

  .designation {
    font-size: 13px !important;
  }

  .hoverCollapseChild {
    padding-right: 13px !important;
  }

  .collapseLeftSideFixedWidth {
    max-width: 85px !important;
  }
}

@media screen and (max-width: 1280px) {
  .btn-wrapper {
    left: 14.9% !important;
  }
  .btn-wrapper-right {
    left: 63px !important;
  }
  .collapseLeftSideFixedRightWidth {
    flex: 0 0 96.666667% !important;
    max-width: 92% !important;
  }

  .collapseMenu {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .designation {
    font-size: 12px !important;
  }

  .collapseChild {
    padding-right: 13px !important;
  }

  .hoverCollapseChild {
    padding-right: 13px !important;
  }

  .collapseLeftSideFixedWidth {
    max-width: 90px !important;
  }
}

@media screen and (min-width: 1440px) {
  .btn-wrapper {
    left: 14.9% !important;
  }
  .btn-wrapper-right {
    left: 62px !important;
  }

  .collapseLeftSideFixedWidth {
    max-width: 90px !important;
  }

  .collapseMenu {
    padding-right: 12px !important;
    padding-left: 10px !important;
  }
}

@media screen and (min-width: 1600px) {
  .btn-wrapper {
    left: 243px !important;
  }
  .btn-wrapper-right {
    left: 61px !important;
  }
  .collapseMenu {
    padding-right: 13px !important;
  }

  .collapseLeftSideFixedWidth {
    max-width: 90px !important;
  }

  .collapseMenu {
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
}

@media screen and (min-width: 1680px) {
  .btn-wrapper {
    left: 255px !important;
  }
  .btn-wrapper-right {
    left: 61px !important;
  }

  .collapseLeftSideFixedWidth {
    max-width: 90px !important;
  }

  .collapseMenu {
    padding-right: 0px !important;
    padding-left: 10px !important;
  }
}

@media screen and (min-width: 1920px) {
  .btn-wrapper {
    left: 295px !important;
  }
  .btn-wrapper-right {
    left: 60px !important;
  }

  .collapseMenu {
    padding-right: 0px !important;
  }

  .collapseLeftSideFixedWidth {
    max-width: 90px !important;
  }

  .collapseMenu {
    padding-right: 0px !important;
    padding-left: 10px !important;
  }
}

@media screen and (min-width: 2048px) {
  .btn-wrapper {
    left: 317px !important;
  }
  .btn-wrapper-right {
    left: 61px !important;
  }

  .collapseMenu {
    padding-right: 0px !important;
  }

  .collapseLeftSideFixedWidth {
    max-width: 90px !important;
  }
}

/** Responsive => End **/

.parent-nav {
  display: inline-flex;
}

.nav-con {
  height: 65px;
  background-image: linear-gradient(#4a8cdd, #0f73ee);
}

.navbar {
  padding: 0px !important;
  margin-bottom: 5px;
}

.bg-light {
  background-color: #fff !important;
}

.nav-img {
  /* height: 65px;
    margin-left: 20px;
    padding: 8px 10px 8px 0; */
  margin-top: 7px;
  padding: 0px 0px;
}

.nav-container {
  padding: 0px;
}

.main-nav {
  height: 65px;
  border-bottom: 1px solid #dddddd;
}

a.nav-link {
  color: #1d1c25 !important;
  font-size: 13px;
  padding: 15px 15px;
}

.mainNotiDropdown {
  padding: 15px 15px !important;
  margin-right: 10px;
}

.mainNotiDropdown > .fa-bell:focus ~ .dropDownMain {
  display: block !important;
}

.dropDownMain:focus-within,
.dropDownMain:hover {
  display: block !important;
}

.mainNotiDropdown > .fa-bell {
  border: 0px;
  outline: none;
  position: relative;
  display: block;
  font-size: 12px;
  background: linear-gradient(#4a8cdd, #0f73ee);
  color: #fff;
  width: 35px;
  height: 35px;
  text-align: center;
  display: grid;
  align-items: center;
  border-radius: 50%;
  top: -5px;
}

.mainNotiDropdown > .fa-bell > span {
  position: absolute;
  top: -7px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 11px;
  text-align: center;
  display: grid;
  align-items: center;
  background: linear-gradient(#4a8cdd, #0f73ee);
  box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.05);
  color: #ffffff;
  right: -7px;
}

.dropDownMain {
  position: absolute;
  width: 350px;
  right: 15px;
  top: 91%;
  border-radius: 3px;
  min-height: 300px;
  z-index: 22;
  background-color: #fff;
  height: 350px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  display: none;
  font-size: 12px;
  border-bottom: 3px solid #0f73ee;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dropDownMain::-webkit-scrollbar {
  width: 5px;
}

.dropDownMain::-webkit-scrollbar-track {
  background: #fafafa;
}

.dropDownMain::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 50px;
}

.dropDownMain::-webkit-scrollbar-thumb:hover {
  background: #ddd;
  border-radius: 50px;
}

.notiData {
  padding: 15px;
  position: relative;
  border-bottom: 1px solid #dddddd45;
  transition: 0.4s;
  padding-left: 70px;
}

.notiSpan {
  position: absolute;
  width: 45px;
  height: 45px;
  left: 15px;
  border-radius: 50%;
  background-color: #f5f5f5;
  top: 11px;
  text-align: center;
  display: grid;
  -webkit-align-items: center;
  align-items: center;
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
  color: #3333337a;
}

.notiData:hover {
  background-color: #fafafa;
}

.notiData p {
  margin-bottom: 0px;
}

.notiData label {
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .navbar {
    padding: 15px !important;
    margin-bottom: 5px;
  }

  .nav-con {
    /* -webkit-filter: invert(88%) sepia(12%) saturate(1801%) hue-rotate(30deg) brightness(87%) contrast(98%); */
    filter: sepia(12%) saturate(3901%) hue-rotate(30deg) brightness(87%) contrast(98%);
    background-image: none;
  }

  /* .nav-img {
    padding: 10px 10px 10px 0px;
  } */
}

.toggleSpace {
  margin-left: 20px;
}

.toggleButton {
  background-color: transparent !important;
  border: none;
}

.calendarWrapper {
  margin-top: 30px;
  margin-left: -19px;
}

.activeTaskMainWrapper {
  width: 229px;
  margin-left: 6px;
}

.activeTaskHeaderWrapper {
  margin-top: 40px;
  text-align: left;
  margin-bottom: 40px;
  font-size: 20px;
}

.activeTaskText {
  text-align: left;
  font-size: 11.5px;
  margin-bottom: 16px;
  padding-left: 0px !important;
}

.mainTitle {
  font-size: 16px;
}

.diaryChild {
  padding: 10px 0 0 20px;
}

.descriptionTitle {
  font-size: 14px;
  color: #848887;
}

p {
  margin-bottom: 0;
}

.removeSpace {
  padding-left: 0px !important;
}

.dropdown-menu {
  right: 10px !important;
  left: auto;
}

.topDropdown {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -20px !important;
}
.navbar-cus {
  transition: 0.3s all;
  background-color: #000 !important;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 11;
  height: 100%;
  overflow-y: scroll;
  overflow: visible;
}
.col-sm-1 {
  transition: 0.3s all;
}

/* .main-logo-wrap{
  width: 100%;
  max-width: 16.666667%;
}
.img-wraper{
  background:#016ff2;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */
.main-user-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 9px;
  margin-right: 9px;
}
.userName {
  text-align: left;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: bold !important;
  color: #016ff2;
  margin-bottom: 0px !important;
}
.designation {
  color: #9b9b9b;
  font-size: 14px;
  text-transform: capitalize;
}
.user-img-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.cirle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-img {
  width: 50px;
  height: auto;
}
.info-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // margin-right: 10px;
}
.dots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dots > p {
  color: #9b9b9b;
  font-size: 25px !important;
  letter-spacing: 1px;
}
.cus-toggle {
  padding: 0px 15px !important;
}
.cus-toggle::after {
  content: '';
  display: none;
}

.display-none {
  transition: 0.3s all;
  display: none;
}
.toggle-btn-wrap {
  position: absolute;
  z-index: 11111;
  top: 0;
  left: 0;
}
.btn-wrapper {
  position: fixed;
  left: 216px;
  z-index: 222;
  top: 72px;
  width: 25px;
  height: 25px;
  background-color: #016ff2;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
}
.btn-wrapper-right {
  position: fixed;
  left: 98px;
  z-index: 222;
  top: 72px;
  width: 25px;
  height: 25px;
  background-color: #016ff2;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
}
.btn-wrapper > i {
  color: #fff;
  font-size: 15px;
}
.btn-wrapper-right > i {
  color: #fff;
  font-size: 15px;
}
.btn-wrapper-right:hover {
  cursor: pointer;
}
.btn-wrapper:hover {
  cursor: pointer;
}
@media screen and (min-width: 1500px) {
  .main-logo-wrap {
    max-width: 240px !important;
  }
  .navbar-cus {
    max-width: 240px !important;
  }
  .cus-col-sm-2 {
    max-width: 240px !important;
  }
  .hidden-wrap {
    max-width: 240px !important;
  }
}

/* width */
.navbar-cus::-webkit-scrollbar {
  width: 5px;
  display: none;
}

/* Track */
.navbar-cus::-webkit-scrollbar-track {
  background: #ffffff24;
}

/* Handle */
.navbar-cus::-webkit-scrollbar-thumb {
  background: #046ef230;
}

/* Handle on hover */
.navbar-cus::-webkit-scrollbar-thumb:hover {
  background: #212121;
}

.navbar-center-title {
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 900;
  font-size: 26px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.back-button-position {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  .back-button-image {
    width: 15px;
    height: 15px;
  }

  .back-button-text {
    color: #016ff2;
    font-size: 15px;
    margin-left: 2px;
  }
}

.navbar-pro-center {
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 900;
  font-size: 28px;
  position: relative;
  top: 42%;
  transform: translateY(-60%);
}

.lead-status {
  background-color: #d9fadc;
  padding: 8px;
  color: #4fa803;
  border: 1px solid #4fa803;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  margin-left: 40px;
  position: relative;
  text-transform: capitalize;
  top: 10px;
  text-align: center;
  min-width: 100px;
}

.navbar-subtitle {
  display: flex;
  flex-direction: column;
}

.left-menu-container {
  background-color: #000;
  height: 100vh;
}

.logout-container {
  position: fixed;
  bottom: 0px;
  max-height: 60px;
  padding-top: 14px;
  background-color: #046ef2;
  padding-bottom: 14px;
  width: 17%;
  margin-right: -17px;
  color: #fff;
  cursor: pointer;
}

.collapseLogoutContainer {
  max-height: 60px;
  padding-top: 14px;
  background-color: #046ef2;
  padding-bottom: 14px;
  color: #fff;
  width: 100%;
  cursor: pointer;
}

.logout-icon-container {
  margin-left: 13px;
}

.collapse-menu {
  font-size: 17px;
  font-weight: bold;
  color: #fff;
}

.mimize {
  position: absolute;
  right: 15px;
  top: -14px;
  bottom: 0;
  height: 49px;
  width: 50px;
  flex: unset;
  background-color: #046ef2;
  border: none;
}

.collapseMinize {
  position: absolute;
  right: 15px;
  top: -14px;
  bottom: 0;
  height: 49px;
  width: 50px;
  flex: unset;
  background-color: #046ef2;
  border: none;
}

.mimize:hover {
  position: absolute;
  right: 15px;
  top: -14px;
  bottom: 0;
  height: 50px;
  width: 50px;
  flex: unset;
  border: 1px solid #046ef2;
  background-color: rgba(0, 0, 0, 0.2);
}

.minize::before {
  color: #fff;
}

.nonCollapseSide {
  padding-left: 9px;
  padding-right: 24px;
}

.collapseMenu {
  padding-right: 21px;
  padding-left: 8px;
}

.mainContainer {
  margin-left: 10px;
}

.iconSize {
  font-weight: bold;
  font-size: 16px;
}

.collapseIconSize {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.collapseIconContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 50px;
}

.collapseFixedWidth {
  flex: 0 0 230px !important;
}

.FixedWidth {
  flex: 0 0 354px !important;
}

.collapseLeftSideFixedRightWidth {
  flex: 0 0 96.666667%;
  max-width: 93%;
}
.custom-link {
  text-decoration: none;
}
.custom-link:hover {
  text-decoration: none;
}

.collapseMenuLogout {
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-right: 20px;
}

.tooltip-wrap {
  transition: 0.3s linear;
  opacity: 0;
  position: absolute;
  top: 13px;
  left: -95px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #a2a2a2;
}
.userProfile{
  &:hover{
    cursor: pointer;
    .custom-link{
        .tooltip-wrap{
          opacity: 1;
          left: -65px;
        }
    }
  }

}

.circle-text {
  color: #fff;
  margin-bottom: 0px !important;
}

.status-container-from-edge {
  position: relative;
  top: 0px;
  left: 5px;
}
.navBarIcon {
  font-size: 22px;
}
.adDairyicon {
  height: 50px;
  position: absolute;
  right: 45px;
  bottom: 83px;
  cursor: pointer;
  z-index: 10;
}
.slidedairymain {
  // width: fit-content;
  // position: absolute;
  // z-index: 10;

  width: fit-content;
  position: absolute;
  z-index: 10;
  max-height: 278px;
  top: 115px;
}
.displayDiary {
}
//.hideDiary{display: none;}
.diarymainWrapper {
  position: absolute;
  right: 12px;
  z-index: 100;
}

/*slider Diary*/
.diarySlider {
  overflow: hidden;
}
.displayDiary {
  position: absolute;
  top: 0;
  right: 30px;
  z-index: 999;
  height: 100%;
  //transition: right 2s cubic-bezier(0.820, 0.085, 0.395, 0.895);
}
.hideDiary {
  right: -100px;
}
.dialer-top-icons{
/*  position: relative;*/
}

.diaryIconContainer {
  position: absolute;
  right: 27%;
  top: -25px;
  z-index: 99;
/*  background: #036ef2;*/
  display: flex;
  cursor: pointer;
  padding: 5px;
  /*border-radius: 50px;*/
  transition: top 1s ease;
  &.diaryActive {
    top: -110px;
  }
  .fal {
    color: #000;
    font-size: 1.5em;
    position: relative;
    left: 0;
    top: 34px;
    /*-webkit-animation: 1s wiggle ease infinite;
    animation: 1s wiggle ease infinite;*/
  }
  &.phone-icons-mws{
   /* z-index: 1 !important;*/
    right: 30%
  }
}
.phone-icons-mws{
 /* z-index: 1 !important;*/
}

@-webkit-keyframes wiggle {
  0% {
    transform: rotate(-3deg);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
}

.lead-status-container {
  display: flex;
  flex-direction: row;
}
