/** @format */

.component-container {
  margin: 40px 0 40px 0;
  .call-icon-color {
    color: #016ff2;
    background: #d5e6f9;
  }
  .attachment-icon-color {
    color: #7501c1;
    font-weight: bold;
    background: #e9d6f6;
  }
  .comment-icon-color {
    color: #4fa803;
    background: #d5f6da;
  }
  .meeting-icon-color {
    color: #c63a01;
    background: #f7dfd5;
  }
  .status-icon-color {
    background: #e4e4e4;
  }
  .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left-icon-size {
      width: 35px;
      height: 35px;
    }
    .icon-position-left {
      display: flex;
      align-items: flex-start;
      .icon-container-style {
        width: 30px;
        height: 30px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icon-title-container {
        margin-left: 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 5px;
        .icon-title-entity {
          font-size: 15px;
          margin-right: 3px;
        }
        .icon-title-entity-highlight {
          font-size: 15px;
          color: #2185d0;
          margin-right: 3px;
        }
        .timeline-bold .icon-title-text {
          font-size: 15px;
          color: black;
        }
      }
    }
    .icon-position-right {
      align-items: flex-end;
      margin-right: 15px;
      .time-main-container {
        background-color: white;
        width: 180px;
        vertical-align: middle;
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 2px;
        text-align: center;
        border: 1px solid #eeeeee;
        border-radius: 100px;
        .time-main-container-text {
          color: #7a7a7a;
          font-size: 12px;
        }
      }
    }
  }

  .card-container-field {
    position: relative;
    .card-container {
      position: absolute;
      width: 230px;
      left: 40px;
      border: 1px solid #e2e2e3;
      z-index: 1049;
      background-color: white;
      border-radius: 5px;
      -webkit-box-shadow: -0.5px 0px 5px -2.5px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -0.5px 0px 5px -2.5px rgba(0, 0, 0, 0.75);
      box-shadow: -0.5px 0px 5px -2.5px rgba(0, 0, 0, 0.75);
      .card-container-text {
        font-size: 13px;
        font-weight: normal;
      }
      .card-container-active-text {
        font-size: 13px;
        font-weight: bold;
        color: #016ff2;
      }
      .item-disabled {
        background: #f0f0f0;
        cursor: not-allowed;
      }
    }
  }

  .main-content {
    margin-left: 50px;
    .main-content-attachment {
      border-radius: 5px;
      position: relative;
      height: 60px;
      width: 60px;
      overflow: hidden;
      border: 1px solid #666;
      cursor: pointer;
    }

    .attachmentWrap:hover > p {
      height: auto;
    }
    .attachment-image-size {
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
    .main-content-text {
      font-size: 14px;
      width: 450px;
    }
  }
}

.payment-hyper {
  color: #2185d0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
