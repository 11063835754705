.calenderContainer {
    // border: $filterBorder;
    background: white;
    position: fixed;
    // top: $filtersHeight;
    z-index: 999999999;
    box-shadow: rgb(204, 202, 202) 0px 1px 2px 0px;
    // border: $filterBorder;
    padding: 5px;
    margin-top: 35px;
  }
.datesContainer{
    display: grid;
    grid-template-columns: 6fr 6fr 1fr;
    grid-column-gap: 15px;
    padding-left: 14px;
}