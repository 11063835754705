/** @format */

.filterMainWrap {
  position: relative;
  padding-bottom: 20px;
  .addRoleBtn {
    text-align: right;
    grid-template-columns: 1fr 3fr;
    // width: 125px;
    grid-gap: 10px;
    .searchBtn {
      width: auto;
      background-color: #016ff2;
      font-size: 14px;
      color: #fff;
      border-radius: 4px;
      box-shadow: 0 0 0 0;
      border: 1px solid #0f73ee !important;

      &.trans__btn {
        background-color: transparent !important;
        color: #0f73ee !important;
        border: 1px solid #0f73ee !important;
      }
    }
  }
  .filterBtn {
    text-align: right;
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 172px;
    float: right;
    grid-gap: 10px;
    .searchBtn {
      width: auto;
      background-color: #016ff2;
      font-size: 14px;
      color: #fff;
      border-radius: 4px;
      box-shadow: 0 0 0 0;
      border: 1px solid #0f73ee !important;

      &.trans__btn {
        background-color: transparent !important;
        color: #0f73ee !important;
        border: 1px solid #0f73ee !important;
      }
    }

    .clearBtn {
      width: auto;
      position: relative !important;
      color: #666666;
      border: none !important;
      background: #fff;
      font-size: 14.5px;
      padding: 5px 0px;
      margin-left: 12px;
      border-bottom: 1px solid #666666 !important;
      border-radius: 0px;
      box-shadow: 0 0 0 0;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  .filterInputWrap {
    position: relative;
    z-index: 4;
    .form-control {
      position: relative;
      font-size: 14px;
      min-height: 38px;
      border-radius: 4px !important;
      border-color: #cccccc;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.requiredCheck {
  font-size: 12px;
  color: red;
}

.hoverForTr {
  &:focus,
  &:hover {
    background-color: #046ef21f;
    outline: none;
  }

  &.red {
    background-color: #ff000021;
  }

  &.yellow {
    background-color: #ffff0021;
    color: #fff;
  }
  &.in-action {
    background-color: #046ef21f;
    color: #fff;
  }
}

.investLeadStatus {
  // &:focus,
  // &:hover {
  //   background-color: #046ef21f;
  //   outline: none;
  // }

  &.new {
    border-color: #006ff2;
    color: #006ff2;
  }

  //&.delay__30__60 {
  //  background-color: #fffae3;
  //}

  //&.delay__60__above {
  //  background-color: #ffe5e5;
  //}

  &.shared {
    border-color: #62b500;
    color: #62b500;
  }

  img.lead__reprecentation {
    width: 12px;
    object-fit: contain;
  }
}

.leadStatusTextColor {
  background: inherit;
  .pendingStatus {
    color: #32a6e8;
    font-weight: bold;
  }
  .open {
    color: #000;
    font-weight: bold;
  }
  .cleared {
    color: green;
    font-weight: bold;
  }
  .rejected {
    color: #f0352c;
    font-weight: bold;
  }
}

.buyRentLeadStatus {
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  .shortlisting {
    background: #fef3c6;
    color: #916121;
    border-radius: 5px;
    text-align: center;
    padding: 6.2% 1.7% !important;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 79px;
    margin: 0 auto;
  }
  .pending_token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: 6.2% 1.7%;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 105px;
    margin: 0 auto;
  }
  .token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: 6.2% 1.7%;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 75px;
    margin: 0 auto;
  }
  .closed_lost {
    background: #fde0e2;
    color: #7a3635;
    border-radius: 5px;
    text-align: center;
    padding: 6.2% 1.7%;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 75px;
    margin: 0 auto;
  }
  .open {
    background: #ceecfc;
    color: #28566a;
    border-radius: 5px;
    padding: 6.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 50px;
    margin: 0 auto;
  }
  .payment {
    background: #cefce2;
    color: #286a4f;
    font-weight: bold;
    padding: 6.2% 1.7% !important;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 68px;
    margin: 0 auto;
  }
  .closed_won {
    background: #caf4a4;
    color: #536d3a;
    padding: 6.2% 1.7%;
    border-radius: 5px;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 77px;
    margin: 0 auto;
  }
}

.leadStatusColorContainerAdditionalDetail {
  font-weight: 400;
  font-size: 14px;
  //align-items: center;
  .meeting {
    background-color: #e2effd;
    color: #5955a2;
    border-radius: 5px;
    text-align: center;
    padding: 1.2% 1.7%;
    text-transform: capitalize;
    font-weight: bold;
    width: 79px;
    // margin: 0 auto;
  }
  .closed_lost {
    background: #fde0e2;
    color: #7a3635;
    border-radius: 5px;
    text-align: center;
    padding: 1.2% 1.7%;
    text-transform: capitalize;
    font-weight: bold;
    width: 75px;
    // margin: 0 auto;
  }

  .intermall {
    background: #fde0e2;
    color: #7a3635;
    border-radius: 5px;
    text-align: center;
    padding: 1.2% 1.7%;
    text-transform: capitalize;
    font-weight: bold;
    width: 75px;
    // margin: 0 auto;
  }
  .pending_token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: 6.2% 1.7%;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 105px;
    margin: 0 auto;
  }
  .token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: 1.2% 1.7%;
    text-transform: capitalize;
    font-weight: bold;
    width: 58px;
    // margin: 0 auto;
  }
  .nurture {
    background: #fef3c6;
    color: #916121;
    border-radius: 5px;
    text-align: center;
    padding: 1.2% 1.7%;
    text-transform: capitalize;
    font-weight: bold;
    width: 79px;
    // margin: 0 auto;
  }
  .open {
    background: #ceecfc;
    color: #28566a;
    border-radius: 5px;
    padding: 1.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 50px;
    // margin: 0 auto;
  }
  .payment {
    background: #cefce2;
    color: #286a4f;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: 1.2% 1.7%;
    text-transform: capitalize;
    font-weight: bold;
    width: 68px;
    // margin: 0 auto;
  }
  .closed_won {
    background: #caf4a4;
    color: #536d3a;
    border-radius: 5px;
    padding: 1.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    // width: 77px;
    // margin: 0 auto;
  }
  .propsure {
    background: #ffd7c3;
    color: #953000;
    border-radius: 5px;
    padding: 1.2% 1.7%;
    // text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
    // margin: 0 auto;
  }
  .viewing {
    background: #fef3c6;
    color: #916121;
    border-radius: 5px;
    padding: 1.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
    // margin: 0 auto;
  }
  .offer {
    background: #80eff1;
    color: #007e81;
    border-radius: 5px;
    padding: 1.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
    // margin: 0 auto;
  }
  .propsure {
    background: #ffd7c3;
    color: #953000;
    border-radius: 5px;
    padding: 1.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
  }
  .viewing {
    background: #fef3c6;
    color: #916121;
    border-radius: 5px;
    padding: 1.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
  }
  .assigned {
    background: rgb(202, 244, 164);
    color: rgb(83, 109, 58);
    border-radius: 5px;
    padding: 1.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
  }
  .shortlisting {
    background: #fef3c6;
    color: #916121;
    border-radius: 5px;
    padding: 1.2% 1.7%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
  }
}

.leadStatusColorContainer {
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  .meeting {
    background-color: #e2effd;
    color: #5955a2;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 79px;
    margin: 0 auto;
  }
  .closed_lost {
    background: #fde0e2;
    color: #7a3635;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 75px;
    margin: 0 auto;
  }

  .intermall {
    background: #fde0e2;
    color: #7a3635;
    border-radius: 5px;
    text-align: center;
    padding: 1.2% 1.7%;
    text-transform: capitalize;
    font-weight: bold;
    width: 167px;
    // margin: 0 auto;
  }
  .pending_token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: 6.2% 1.7%;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 105px;
    margin: 0 auto;
  }
  .token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 58px;
    margin: 0 auto;
  }
  .nurture {
    background: #fef3c6;
    color: #916121;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 79px;
    margin: 0 auto;
  }
  .open {
    background: #ceecfc;
    color: #28566a;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 50px;
    margin: 0 auto;
  }
  .payment {
    background: #cefce2;
    color: #286a4f;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 68px;
    margin: 0 auto;
  }
  .closed_won {
    background: #caf4a4;
    color: #536d3a;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 77px;
    margin: 0 auto;
  }
  .propsure {
    background: #ffd7c3;
    color: #953000;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
    margin: 0 auto;
  }
  .viewing {
    background: #eae2ff;
    color: #421f92;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
    margin: 0 auto;
  }
  .offer {
    background: #80eff1;
    color: #007e81;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 78px;
    margin: 0 auto;
  }
}

.buyRentLeadTopMenuContainer {
  background: inherit;
  font-weight: 400;
  font-size: 14px;
  align-items: center;

  .shortlisting {
    background: #fef3c6;
    color: #916121;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    font-weight: bold;
    width: 110px;
    margin: 0 auto;
  }
  .pending_token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: 6.2% 1.7%;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 105px;
    margin: 0 auto;
  }
  .token {
    background: #fce7ce;
    color: #6a5628;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    font-weight: bold;
    width: 70px;
    margin: 0 auto;
  }
  .closed_lost {
    background: #fde0e2;
    color: #7a3635;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    font-weight: bold;
    width: 110px;
    margin: 0 auto;
  }
  .open {
    background: #ceecfc;
    color: #28566a;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    font-weight: bold;
    width: 70px;
    margin: 0 auto;
  }
  .payment {
    background: #cefce2;
    color: #286a4f;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    font-weight: bold;
    width: 85px;
    margin: 0 auto;
  }
  .closed_won {
    background: #caf4a4;
    color: #536d3a;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    font-weight: bold;
    width: 110px;
    margin: 0 auto;
  }
}

.leadTopMenuStatusContainer {
  background: inherit;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  .meeting {
    background-color: #e2effd;
    color: #5955a2;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 110px;
    margin: 0 auto;
  }

  .intermall {
    background: #fde0e2;
    color: #7a3635;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    font-weight: bold;
    width: 167px;
    margin: 0 auto;
  }

  .closed_lost {
    background: #fde0e2;
    color: #7a3635;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 90px;
    margin: 0 auto;
  }
  .pending_token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: 6.2% 1.7%;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 105px;
    margin: 0 auto;
  }
  .token {
    background: #fce7ce;
    color: #6a5628;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 60px;
    margin: 0 auto;
  }
  .nurture {
    background: #fef3c6;
    color: #916121;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 94px;
    margin: 0 auto;
  }
  .open {
    background: #ceecfc;
    color: #28566a;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 60px;
    margin: 0 auto;
  }
  .payment {
    background: #cefce2;
    color: #286a4f;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    padding: inherit;
    text-transform: capitalize;
    font-weight: bold;
    width: 75px;
    margin: 0 auto;
  }
  .closed_won {
    background: #caf4a4;
    color: #536d3a;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 94px;
    margin: 0 auto;
  }

  .propsure {
    background: #ffd7c3;
    color: #953000;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 93px;
    margin: 0 auto;
  }
  .viewing {
    background: #fef3c6;
    color: #916121;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 93px;
    margin: 0 auto;
  }
  .offer {
    background: #80eff1;
    color: #007e81;
    border-radius: 5px;
    padding: inherit;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    width: 93px;
    margin: 0 auto;
  }
}

.task-modal {
  width: 700px !important;
  margin-left: 75px !important;
  .task-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .container-image {
      width: 250px;
      height: 250px;
    }
  }
  .call-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .container-image {
      width: 228px;
    }
  }

  .task-form {
    padding-top: 21px;
    padding-bottom: 22px;
  }

  .task-description {
    width: 100%;
    resize: none;
    border: 1px solid #dededf !important;
    border-radius: 3px;
    padding: 8px;
    font-size: 14px;
  }

  .pos-absolute-t10-r0 {
    position: absolute;
    top: 10px;
    right: 0;
  }

  .task-button.disabled {
    background-image: linear-gradient(#97beff, #97beff);
  }

  .font-15 {
    font-size: 15px;
  }

  .task-button {
    width: 120px;
  }

  .task-list {
    font-size: 15px;
    max-height: 460px;
    overflow: auto;
    min-height: 282px;
    padding: 0 16px 8px 16px;
  }
}

.follow-up-modal {
  .task-button {
    width: 130px;
  }

  .task-list {
    font-size: 15px;
    max-height: 460px;
    overflow: auto;
    min-height: 285px;
    padding: 0 16px 8px 16px;
  }
}

.task-modal,
.follow-up-modal,
.after-call-action-modal {
  .task-button.outline {
    background-color: white;
    color: #016ff2;
    border: 2px solid;
    border-color: #016ff2;
    padding: 8px;
  }
}

.after-call-action-modal {
  .task-button.outline {
    &:hover {
      background-color: #016ff2;
      color: white;
    }
  }
  .task-button.reject {
    background-color: #dc3647 !important;
  }
  .task-button.followup {
    color: black;
    background-color: #fec107 !important;
  }
}

.task-modal,
.viewing-modal,
.follow-up-modal {
  .done-meeting {
    float: right;
    padding-left: 9px;
    position: relative;
    top: 3px;
  }

  .footer-btn {
    margin: 0 -30px;
    border-top: 1px solid #eee;
    padding-top: 2px;
  }

  .modal-header {
    background-color: white !important;
    border-bottom: 1px solid #f1f1f1;
    padding: 18px 52px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }

  .task-button {
    margin-top: 10px;
    margin: 0 auto;
    color: #fff;
    background-color: #016ff2;
    padding: 10px;
    margin-top: 15px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #0052b4;
    }
  }

  .task-input-container {
    padding: 10px;
    height: 44.8px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dddddd;
    &:focus {
      outline: none;
    }
  }

  .card-status-text {
    padding: 8px 12px;
    float: right;
    margin-right: 20px;
    color: #706eb1;
    background-color: #e2e8fd;
  }
}

.comment-main-wrapper {
  padding: 0 10px 0 10px;
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .comment-loader-area {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
}

.comment-popup-size {
  margin: 0 auto;
  width: 750px !important;
}

.comment-popup {
  width: 100%;
  max-width: 850px !important;
}

.general-popup {
  width: 100%;
  max-width: 690px !important;
}

.general-popup-normal-size {
  margin: 0 auto;
  width: 580px !important;
}

.general-popup-medium-size {
  margin: 0 auto;
  width: 650px !important;
}

.comment-popup-body-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .popup-body-header-title {
    font-size: 22px;
    font-weight: bold;
    color: #000;
  }
  .popup-body-header-icon {
    color: #909090;
    font-size: 22px;
    cursor: pointer;
  }
}

.comment-main-body {
  overflow-y: auto;
  height: calc(100vh - 324px);
  margin-bottom: 15px;
  .comment-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
  }
  .not-found-image {
    width: 350px;
    height: 300px;
  }
}

.propertycomment-main-body {
  overflow-y: auto;
  height: calc(100vh - 724px);
  margin-bottom: 15px;
  .comment-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
  }
  .not-found-image {
    width: 350px;
    height: 300px;
  }
}

.comment-button {
  background-color: #016ff2;
  color: #fff;
  text-align: center;
  width: 150px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  border: none;
  line-height: 35px;
  margin-bottom: 20px;
}

.comment-description {
  width: 100%;
  resize: none;
  border: 1px solid #dededf !important;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
}

.NextOfKin-modal-width {
  width: 850px !important;
  margin: 0 auto !important;
}

.comment-modal-width {
  width: 1050px !important;
  margin: 0 auto !important;
}
.propertyComment-modal-width {
  width: 700px !important;
  margin: auto !important;
}

.comment-button:disabled {
  background-image: linear-gradient(#97beff, #97beff);
  border-radius: 100px;
  cursor: not-allowed;
}

.comment-button:disabled:hover {
  background-image: linear-gradient(#97beff, #97beff);
  border-radius: 100px;
  cursor: not-allowed;
}

.comment-button:focus {
  outline: none;
}

.loaded-attachments-body {
  overflow: auto;
  font-weight: bold;
  height: calc(100vh - 230px);
  .icon-size {
    width: 30px;
    height: 30px;
  }

  .title {
    font-size: 15px;
    color: #3182f2;
    font-weight: bold;
  }

  .image-container {
    margin-top: 10px;
    position: relative;
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin-left: 56px;
    .image-size {
      width: 100%;
      height: 100%;
    }
    .icon-position-attachment {
      position: absolute;
      right: 3%;
      top: 3%;
      z-index: 99999999;
      color: #9c9c9c;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1 1;
      font-size: 17px;
      height: 100%;
      &:hover {
        color: #9c9c9c;
      }
    }
    &:hover {
      -webkit-filter: brightness(80%);
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
      color: #fff;
    }
  }
}

.search-box {
  margin: 10px 0 20px 0;
  display: grid;
  grid-template-columns: auto auto;
  .search-box-style {
    border: 1px solid #a3a7ac;
    padding: 7px;
    border-radius: 3px;
    width: 100%;
    font-size: 15px;
    &:focus {
      outline: none;
    }
  }
  .dropdown-container {
    margin-left: 20px;
  }
}

.share-lead-container {
  margin: 3px 5px 3px 5px;
  .share-lead-title {
    font-size: 22px;
    font-weight: bold;
    color: #212122;
  }
  .share-lead-subtitle {
    font-size: 16px;
    font-weight: normal;
    color: #373737;
  }

  .search-box {
    margin: 0px 5px 20px 0;
    display: grid;
    grid-template-columns: auto auto;
    .search-box-style {
      border: 1px solid #a3a7ac;
      padding: 7px;
      border-radius: 3px;
      width: 100%;
      font-size: 15px;
      &:focus {
        outline: none;
      }
    }
    .dropdown-container {
      margin-left: 20px;
    }
  }
  .agents-main-area {
    height: 400px;
    overflow: scroll;
    .agents-grid-view {
      display: grid;
      grid-template-columns: auto auto;
      .checkbox-container {
        margin-right: 10px;
      }
      .grid-item-space {
        margin: 10px 0 10px 0;
      }
      .agents-grid-text {
        color: #383939;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .grid-loader-view {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
    }
  }
}

.share-modal-table {
  border-collapse: collapse;
  overflow-x: scroll;
  display: block;
}

.share-modal-table th {
  border: none !important;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 12px !important;
  color: #1d1e27;
  background-color: #f6f7fa;
  padding: 10px;
  text-align: left;
}

.share-modal-table thead,
.share-modal-table tbody,
.share-modal-table tfoot {
  display: block;
}

.share-modal-table tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 280px);
}

.share-modal-table td {
  border: none !important;
  border-bottom: 1px solid #f2f2f4 !important;
  color: #50525e !important;
  padding: 7px;
  text-align: left;
  height: 25px;
  font-size: 12px;
  border: 1px solid #dee2e6;
}

// Global style
.p-2-1 {
  padding: 0.4rem !important;
}

.attachment-modal-width {
  width: 800px !important;
  margin: 0 auto !important;
}

//.share-modal-width {
//  width: 980px !important;
//  margin: 0 auto !important;
//}

.client-creation-modal-width {
  width: 775px !important;
  margin: 0 auto !important;
}
.text-blue {
  color: #036ef2;
}

.data-loader {
  justify-content: center;
  align-items: center;
  color: #016ff2;
  display: flex;
  font-size: 20px;
  height: 274px;
}

.cursor-pointer {
  cursor: pointer;
}

.time-main-container {
  background-color: white;
  min-width: 170px;
  max-height: 30px;
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 2px;
  text-align: center;
  border: 1px solid #eeeeee;
  border-radius: 100px;
  .time-main-container-text {
    color: #7a7a7a;
    font-size: 12px;
  }
}

.time-attachment-main-container {
  background-color: white;
  width: 167px;
  max-height: 30px;
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 2px;
  text-align: center;
  border: 1px solid #eeeeee;
  border-radius: 100px;
  .time-main-container-text {
    color: #7a7a7a;
    font-size: 12px;
  }
}

.comment-date {
  margin-left: 10px;
  font-size: 12.5px;
  font-weight: normal;
  color: #999999;
}

.comment-title {
  color: #075bc7;
  margin-left: 10px;
}

.comment-body {
  font-size: 13px;
  margin-left: 10px;
}

.comment-user-avatar {
  display: flex;
  flex-direction: column;
  .circle {
    background-color: rgb(4, 110, 242);
    .circle-text {
      color: #fff;
      margin-bottom: 0px !important;
    }
  }
}

.attachment-upload-container {
  margin: 40px 0 5px 0;
  text-align: center;

  .attachment-upload-button {
    background-color: #016ff2;
    color: #fff;
    border-radius: 50px;
    width: 150px;
    line-height: 35px;
    border: none;
  }
  .attachment-outline {
    @extend .attachment-upload-button;
    color: #016ff2;
    background: #fff;
    border: 1px solid #016ff2;
  }
}

.attachment-modal-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .attachment-header-title {
    font-size: 22px;
    font-weight: bold;
    color: #222222;
  }
  .attachment-close-icon {
    color: #909090;
    font-size: 22px;
    cursor: pointer;
  }
}

.no-attachment-image {
  width: 400px;
  height: 330px;
}
.disableHover {
  cursor: not-allowed !important;

  img {
    cursor: not-allowed !important;
  }
}

.unit_no_result {
  width: 300px;
  height: 250px;
  position: sticky;
  left: 40%;
  top: 40%;
  margin-top: 15vh;
}

.topAlertToggle {
  position: fixed;
  left: 0px;
  z-index: 10000;
  width: 100%;
  top: -70px;
  transition: 0.4s ease;

  &.showToggle {
    top: 10px;
    transition: 0.4s ease;
  }

  .read__only {
    width: 55%;
    margin: auto;
  }
}
.projectLeadButton {
  color: #0f73ee;
  border-radius: 2px;
  border: 1px solid #0f73ee;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: white;
  cursor: pointer;
  height: 30px;
}

.projectLeadButton:hover {
  background: #0f73ee;
  color: white;
}

.close-icon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  .close-icon {
    color: #909090;
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.category-modal {
  .modal-header {
    background: unset !important;
  }
}

.lead-category-status {
  text-align: center;
  max-width: 129px;
  font-weight: bold;
}

.tab-view-container {
  .lead-category-status {
    display: inline-block;
  }
}

.more-filter-btn {
  background-color: transparent;
  border: 1px solid #1173ef;
  color: #1173ef;
  font-size: 14px;
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 0 0 !important;
  outline: none !important;
  white-space: nowrap;

  span {
    background-color: #016ff2;
    color: white;
    border-radius: 100%;
    min-width: 16px;
    font-size: 11px;
    display: inline-block;
  }

  &.active {
    //background-color: #016ff2;
    //color: white;

    //span {
    //  background-color: white;
    //  color: #016ff2;
    //}
  }
}

.sort__img__dropDown {
  display: inline-block;

  .sort__img {
    position: relative;
    margin-left: 15px;
    font-size: 14px;
    outline: none !important;
    box-shadow: 0 0 0 0 !important;
    border: 0px solid #d6d6d5 !important;
    padding: 0px 0px;
    border-radius: 5px;
    color: #b0b0b0;

    img {
      position: relative;
      width: 20px;
    }
  }

  .dropdown-menu.show {
    width: 200px;
    top: 10px !important;
    padding: 0px;

    .btn {
      text-align: left;
      border-top: 1px solid #eff0f0 !important;
      font-size: 14px;
      padding: 8px 10px;
      margin: 0px;
      outline: none !important;

      &:nth-child(1) {
        border-top: 0px !important;
      }

      &.active {
        color: #046ef2;
      }
    }
  }

  .sort-icon {
    cursor: pointer;
  }
  .sort-icon.active {
    color: #016ff2;
  }
}

.no-result-container {
  display: block;
}

.text-decoration-underline {
  text-decoration: underline;
}

/*GoogleMap Modal*/
.pac-container {
  z-index: 1000000 !important;
}
/*GoogleMap Modal End*/
@mixin button-bg($bg) {
  background: $bg;
  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($bg, 25%);
  }
}

.propertyListbtn {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 600;
  border: none;
  //  font-size:2em;
}

.btn-green {
  @include button-bg(#2ecc71);
}

.btn-gray {
  @include button-bg(#757575);
}

.btn-blue {
  @include button-bg(blue);
}

.btn-yellow {
  @include button-bg(#f1c40f);
}

.btn-red {
  @include button-bg(#e74c3c);
}

/*Other Stylez*/
.wrap {
  margin: 0 auto;
  width: 800px;
  text-align: center;
}

.BtnfloatRight {
  float: right;
}
.PropertytextSize {
  font-size: 19px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 1rem;
}

.swalConfirmBtn {
  background: #026ff2;
  color: white;
  padding: 5px;
  border: 1px solid #026ff2;
}
.denyConfirmBtn {
  background: #fff;
  color: black;
  padding: 5px;
  border: 1px solid #d3d3d3;
}

.react-pdf__Page__canvas {
  height: 100px !important;
  width: 100px !important;
}
.AdditionalInfoMargin {
  margin-top: 50%;
}

.cancel {
  background-color: #fff;
  color: #046ef2;
  padding: 5px 10px;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  border: 1px solid #046ef2;
  margin-top: 20px;
}

.add-button {
  background-color: #046ef2;
  color: #fff;
  padding: 5px 10px;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  border: 1px solid #046ef2;
  margin-top: 20px;
}

.flex-flow-column {
  flex-flow: column;
}

.w-15 {
  width: 15% !important;
}

.isToast .swal2-icon-success .swal2-timer-progress-bar {
  width: 100%;
  height: 1.45em !important;
  background: #27ae60 !important;
}

.isToast .swal2-icon-error .swal2-timer-progress-bar {
  width: 100%;
  height: 1.45em !important;
  background: red !important;
}
.isToast .swal2-icon.swal2-success [class^='swal2-success-line'] {
  display: block;
  position: absolute;
  z-index: 5;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #ffffff !important;
}
.isToast .swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 0em !important;
  background-color: #27ae60 !important;
}

.isToast .swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 1 !important;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid #27ae60 !important;
  border-radius: 50%;
  background: #27ae60 !important;
}

.isToast .swal2-popup.swal2-toast .swal2-icon {
  margin: 5% 0.5em 0 0 !important;
}

.isToast .swal2-popup.swal2-toast .swal2-title {
  line-height: 1.6rem !important;
  text-align: start;
  justify-content: flex-start;
}

.account-table-tr {
  font-weight: 400;
  font-size: 14px;
  .leadStatus {
    border-radius: 5px;
    text-align: center;
    padding: 4% 6%;
    text-transform: capitalize;
    font-weight: bold;
    display: block;
  }
  //align-items: center;
  .meeting {
    background-color: #e2effd;
    color: #5955a2;
    width: 79px;
    // margin: 0 auto;
  }
  .closed_lost {
    background: #fde0e2;
    color: #7a3635;
    width: 75px;
    // margin: 0 auto;
  }

  .intermall {
    background: #fde0e2;
    color: #7a3635;
    width: 75px;
    // margin: 0 auto;
  }
  .pending_token {
    background: #fce7ce;
    color: #6a5628;
    width: 105px;
    margin: 0 auto;
  }
  .token {
    background: #fce7ce;
    color: #6a5628;
    width: 58px;
    // margin: 0 auto;
  }
  .nurture {
    background: #fef3c6;
    color: #916121;
    width: 79px;
    // margin: 0 auto;
  }
  .open {
    background: #ceecfc;
    color: #28566a;
    width: 50px;
    // margin: 0 auto;
  }
  .payment {
    background: #cefce2;
    color: #286a4f;
    width: 68px;
    // margin: 0 auto;
  }
  .closed_won {
    background: #caf4a4;
    color: #536d3a;
    // width: 77px;
    // margin: 0 auto;
  }
  .propsure {
    background: #ffd7c3;
    color: #953000;
    width: 78px;
    // margin: 0 auto;
  }
  .viewing {
    background: #fef3c6;
    color: #916121;
    width: 78px;
    // margin: 0 auto;
  }
  .offer {
    background: #80eff1;
    color: #007e81;
    width: 78px;
    // margin: 0 auto;
  }
  .propsure {
    background: #ffd7c3;
    color: #953000;
    width: 78px;
  }
  .viewing {
    background: #fef3c6;
    color: #916121;
    width: 78px;
  }
  .assigned {
    background: rgb(202, 244, 164);
    color: rgb(83, 109, 58);
    width: 78px;
  }
  .shortlisting {
    background: #fef3c6;
    color: #916121;
    width: 78px;
  }
}
.mws-steps {
  header {
    padding-bottom: 5px;
    // border-bottom: 1px solid #dadada;
    ul {
      li {
        position: relative;
        a {
          padding: 15px 50px 15px 50px;
          font-size: 16px;
          background-color: #b9d3ef;
          color: #fff !important;

          &:hover {
            background-color: #0f73ee;
            color: #fff !important;
          }
        }
        .arrow-top,
        .arrow-bottom {
          display: none;
        }
        &.done {
          .arrow-top {
            position: absolute;
            height: 1px;
            width: 42px;
            display: block;
            background-color: #fff;
            right: -38px;
            top: 13px;
            -webkit-transform: rotate(42deg);
            transform: rotate(42deg);
            z-index: 1;
          }
          .arrow-bottom {
            position: absolute;
            height: 1px;
            width: 42px;
            display: block;
            background-color: #fff;
            right: -38px;
            bottom: 13px;
            -webkit-transform: rotate(-42deg);
            transform: rotate(-42deg);
            z-index: 1;
          }
        }
        &.active {
          .arrow-top {
            position: absolute;
            height: 1px;
            width: 42px;
            display: block;
            background-color: #fff;
            right: -38px;
            top: 13px;
            -webkit-transform: rotate(42deg);
            transform: rotate(42deg);
            z-index: 1;
          }
          .arrow-bottom {
            position: absolute;
            height: 1px;
            width: 42px;
            display: block;
            background-color: #fff;
            right: -38px;
            bottom: 13px;
            -webkit-transform: rotate(-42deg);
            transform: rotate(-42deg);
            z-index: 1;
          }
        }

        &.done {
          a {
            background: #0f73ee;
            color: #fff !important;
          }
          &:after {
            position: absolute;
            content: '';
            top: -1px;
            right: -31px;
            width: 0;
            height: 0;
            border-top: 28px solid transparent;
            border-bottom: 28px solid transparent;
            border-left: 31px solid #0f73ee;
            z-index: 1 !important;
          }
        }
        &.active {
          a {
            background: #0f73ee !important;
            color: #fff !important;
          }

          &:after {
            position: absolute;
            content: '';
            top: -1px;
            right: -31px;
            width: 0;
            height: 0;
            border-top: 28px solid transparent;
            border-bottom: 28px solid transparent;
            border-left: 31px solid #0f73ee;
            z-index: 1;
          }
        }

        &:last-child {
          &:after {
            display: none;
          }
          .arrow-top,
          .arrow-bottom {
            display: none;
          }
        }
      }
    }
  }
}

.mws-steps-new {
  header {
    padding-bottom: 5px;
    position: relative;
    hr {
      margin-left: 1.2%;
      width: 98%;
      border-top: 2px solid grey;
      position: absolute;
      top: 14px;
    }
    // border-bottom: 1px solid #DADADA;
    ul {
      width: 100%;
      justify-content: space-between;
      li {
        position: relative;
        a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        padding: 15px 0px;
        }
        .circle-outer {
          position: relative;
          border: 2px solid #0F73EE !important;
          height: 33px;
          width: 33px;
          background-color: white !important;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
        }
        .circle-inner {
          display: none;
        }
        &.done {
        }
        &.active {
          .circle-inner {
            height: 17px;
            width: 17px;
            display: block;
            background-color: #0F73EE !important;
          }
        }
      }
    }
  }
}

.modal-style {
  .modal-header {
    width: 100%;
    display: block;
  }
}

.table {
  tr {
    &.active {
      background-color: #0f73ee !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
}

.customer-information {
  position: relative;
  /*min-height: 500px;*/

  .mws-loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9;
    color: #000;
    font-size: 20px;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

.customer-autocomplete {
  padding: 10px 0 10px 0;
  position: relative;

  .close-icons {
    position: absolute;
    right: 17px;
    top: 22px;
    cursor: pointer;
    font-size: 16px;
  }
  .form-control {
  }
  .mws-scroll {
    overflow: hidden;
    ul {
      position: absolute;
      left: 0;
      top: 50px;
      width: 100%;
      z-index: 10;
      overflow-y: scroll;
      max-height: 250px;
      margin-right: -12px;

      li {
        padding: 4px;
        cursor: pointer;

        &:hover {
          background-color: #0f73ee;
          color: #fff;
        }
      }
    }
  }
}
.mws_products_types {
  .card {
    b {
      float: right;
    }
  }
}
.mws_dialer_wrapper {
  overflow: hidden !important;
}
.transaction.badge {
  text-transform: capitalize;
  color: #fff;
  padding: 5px;
  &.pending {
    background-color: #0f73ee;
  }
  &.in_progress,
  &.InProgress {
    background-color: sandybrown;
  }
  &.approved,
  &.Completed {
    background-color: #2ecc71;
  }
  &.rejected {
    background-color: red;
  }
}
.transactions-table {
  table {
    td {
      padding: 4px !important;
    }
  }
}
.mws_scroll {
  width: 100%;
  padding: 0;
  height: calc(100vh - 100px);

  .steps-mws {
    overflow-y: scroll;
    height: calc(100% - 80px);
    padding-bottom: 10px;
  }
}
.container-separator:last-child {
  background-color: #fff !important;
  border-right: 1px solid #eff0f0;
  border-bottom: 1px solid #eff0f0;
  border-left: 1px solid #eff0f0;
  color: #676873 !important;
}
.inventory-filters {
  padding-bottom: 15px;
  border-bottom: 1px solid #eff0f0;
}
.MwsdisabledLink {
  pointer-events: none;
  cursor: crosshair;
}

.Inputsearch-box {
  width: 100% !important;
  border: 1px solid #dbdbdb;
  height: 38px;
  outline: none;
  padding-left: 10px;
  border-radius: 5px;
}

.contract{
  .row{
     // margin-bottom: 50px;
  }
}


.contractHeader {
  padding: 10px 20px;
  border-radius: 5px 5px 0px 0px;
  color: white !important;
  background-color: #2F6DEA;
  box-shadow: 0px 3px 6px #00000040;
  h6 {
    color: white !important;
    margin: 0px !important;
    font-size: 16px;
  }
}

// .customerHeader {
//   padding: 10px 20px;
//   color: white !important;
//   // background-color: #2F6DEA;
//   h6 {
//     // color: white !important;
//     margin: 0px !important;
//     font-size: 18px;
//   }
// }

.has-search .form-control {
  padding-right: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  right: 0.5rem;
}


.table-resize {
  overflow: auto;
  height: calc(100vh - 250px);
  padding: 11px;
  border-radius: 5px;
}
.accordion {
  width: 100% !important;

  .float-right {
    .fa-angle-down {
      color: #212529 !important;
    }
  }
}

.mws_products_types {
  .card{
    .card-body{
      .list-group{
        .list-group-item{
          margin-right: -1px;
          margin-left: -1px;
        }
      }
    }
  }
}

.add-client-button {
  width: max-content !important;
  cursor: pointer;
  height: max-content !important;
  display: block;
  opacity: 1;
  padding: 6px 12px;
  background: rgb(17, 115, 239);
  text-align: center;
  border-radius: 5px;
  color: #fff;
  padding: 6px 12px;
  border: 1px solid rgb(17, 115, 239);
  white-space: nowrap;
  font-size: 14px;
}
