/** @format */

.buy-rent-tile {
  margin: 15px 10px 15px 0;
  display: block;
  height: calc(100vh - 170px);
  overflow-x: auto;
  padding-left: 0;
}

.properties-modal-scroll {
  margin: 15px 10px 15px 0;
  display: block;
  // height: calc(100vh - 500px) !important;
  height: 55vh;
  overflow-x: auto;
}

.comment-modal-width-new {
  height: 75vh;
}
