/** @format */

.nextOfKin {

  .filterInputMain {
    margin-bottom: 10px;

    input {
      width: 100% !important;
    }

    .form-control{
      border-radius: 4px!important;
      border-color: #eaeaea;
      font-size: 14px;
      &.invalid {
        border-color: red;
      }
    }

    .select-control div:first-child {
      border-radius: 4px!important;
      border-color: #eaeaea;
    }
  }

  .filterInputWrap {
    font-size: 14px;
    min-height: 38px;
    border-radius: 4px !important;
    border-color: #cccccc;
    z-index: 0 !important;
    position: unset !important;
    margin-bottom: 10px;

    .form-control{
      border-radius: 4px!important;
      border-color: #eaeaea;
      font-size: 14px;
      &.invalid {
        border-color: red;
        +.flag-dropdown{
          border-color: red;
        }
      }
    }

    .select-control div:first-child {
      border-radius: 4px!important;
      border-color: #eaeaea;
    }

  }

  .createClientModal {
    position: relative;

    .modal-body {
      padding: 0px;

      .padding__modal {
        padding: 15px;
      }
    }

    .addPageHeading {
      padding: 15px 20px !important;
      background: #f2f2f2 !important;
      color: #454b4d !important;
      justify-content: space-between;
      border-bottom: none;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .basicInfoContainer{
    /*display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7,1fr);
    width: 650px;
    grid-column-gap: 22px;*/
  }

  .basicInfoContainer label{
    font-size: 14px;
    margin-bottom: 5px !important;
  }

  .mailAddressContainer{
    /*display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3,1fr);
    width: 650px;
    grid-column-gap: 22px;*/
  }
  .addressGrid{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  textarea {
    resize: none;
  }
  .mailAddressContainer label{
    font-size: 12px;
    margin-bottom: 5px !important;
  }
  .permAddressContainer{
    /*display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3,1fr);
    width: 650px;
    grid-column-gap: 22px;*/
  }
  .permAddressContainer label{
    font-size: 12px;
  }
  .clientMainContainer{
    &.kinForm{
      height: calc(100vh - 250px);
      overflow: auto;
      .css-26l3qy-menu{
        position: absolute !important;
        z-index: 999999 !important;
      }
      .css-g1d714-ValueContainer{
        z-index: 9999 !important;
      }

    }

  }
  .headers{
    font-size: 22px;
  }
  .calenderContainer {
    background: white;
    position: absolute !important;
    margin-top: 35px !important;
    top: unset !important;
    width: 100%;
    left: 0;
    button[data-id="button-icon-element"]:disabled{
      color: rgba(215,217,226,1) !important;
      cursor: default;
      background: transparent;
    }
  }
  .registerButtonsNextOfkin{
    padding: 0px 15px;
    display: grid;
    grid-template-columns: 100px 100px !important;
    column-gap: 15px !important;
    margin-top: 14px;
    .bgClr{
      background-color: rgb(2, 111, 242) !important
    }
    .cancelBtn{
      background:#FFFFFF !important;
      color :black !important;
      border:1px solid #D3D3D3 !important ;
      &:focus {

      }
    }

  }

  .clientDateContainer {
    position: relative;
    .select-control {
      padding: 5px;
      border-radius: 4px !important;
      border-color: #eaeaea;
      font-size: 14px;
    }
  }

  .createClientModal{
    &.hasScroll{
      height: calc(100vh - 90px);
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
  .headerKIN{
    position: sticky;
    z-index: 9999;
    top: 0;
  }

}

