/** @format */

/** Responsive => For all screens **/

@media screen and (max-width: 1366px) {
  .col-1-6 {
    flex: 0 0 15% !important;
    max-width: 15% !important;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-3-5 {
    flex: 0 0 28% !important;
    max-width: 28% !important;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 1280px) {
}

@media screen and (min-width: 1440px) {
}

@media screen and (min-width: 1600px) {
}

@media screen and (min-width: 1680px) {
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 2048px) {
}

/** Responsive => End **/

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
body {
  font-family: 'Lato', sans-serif !important;
  background-color: #e7ecf0 !important;
}
.App {
  text-align: center;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #444;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}
label {
  margin-bottom: 0px !important;
  font-weight: 600;
}
.css-bg1rzq-control {
  border-radius: 2px !important;
}
.bold700 {
  font-weight: 700;
}
.inline-block {
  display: inline-block;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.form-control {
  border-radius: 2px !important;
  -webkit-appearance: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-padding {
  padding: 0px !important;
}

.space-1 {
  margin-bottom: 5px;
}

.space-2 {
  margin-bottom: 10px !important;
}

.space-3 {
  margin-bottom: 15px;
}

.space-4 {
  margin-bottom: 20px !important;
}

.space-5 {
  margin-bottom: 25px;
}

.font-sm {
  font-size: 12px;
}
.btn-hollow {
  color: #0f73ee;
  border-radius: 2px;
  border: 1px solid #0f73ee;
  text-align: center;
  padding: 5px 10px;
  transition: all 0.2s;
  background: white;
  cursor: pointer;
}

.search-button {
  color: #0f73ee;
  border-radius: 4px;
  border: 1px solid #0f73ee;
  width: 90px;
  padding: 5px 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: white;
  cursor: pointer;
}

.moreFilterButton {
  -webkit-box-align: baseline;
  align-items: baseline;
  border-width: 0px;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #046ef2;
  border-radius: 3px;
  color: #046ef2;
  cursor: pointer;
  margin-right: 20px;
  height: 2em;
  line-height: 1.9em;
  padding: 0px 8px;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  vertical-align: middle;
  width: auto;
  outline: none !important;
}

.moreFilterCollapseButton {
  -webkit-box-align: baseline;
  align-items: baseline;
  border-width: 0px;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #046ef2;
  border-radius: 3px;
  color: #046ef2;
  cursor: pointer;
  margin-right: 20px;
  height: 2em;
  line-height: 1.7em;
  padding: 0px 8px;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  vertical-align: middle;
  width: auto;
  outline: none !important;
}

.moreFilterButtonCrown {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid;
  padding: 3px;
  font-size: 10px;
}

.moreFilterText {
  font-size: 15px;
}

.moreFilterDownArrow {
  font-size: 13px;
  padding-left: 11px;
}

.moreFilterSearchButton {
  font-size: 17px;
  font-weight: bold;
  vertical-align: middle;
}

.moreFilterCloseButton {
  font-size: 17px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 15px;
}

.moreFilterCount {
  background-color: #046ef2;
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  min-width: 1px;
  padding: 0.166667em 0.5em;
  text-align: center;
  margin-left: 8px;
}

.search-button-filter {
  color: #fff;
  border-radius: 4px;
  border: 1px solid #0f73ee;
  width: 90px;
  padding: 5px 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: #0f73ee;
  cursor: pointer;
}

.search-button-filter:focus {
  outline: none;
}

.search-button:hover {
  background: #0f73ee;
  color: white;
}

.clear-button {
  color: #0f73ee;
  border-radius: 4px;
  border: 1px solid #0f73ee;
  width: 90px;
  padding: 5px 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: white;
  cursor: pointer;
}

.clear-button:hover {
  background: #0f73ee;
  color: white;
}

.reduce-space-right {
  margin-right: -11px;
}

.search-button {
  color: #0f73ee;
  border-radius: 4px;
  border: 1px solid #0f73ee;
  width: 90px;
  padding: 5px 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: white;
  cursor: pointer;
}

.search-button-filter {
  color: #fff;
  border-radius: 4px;
  border: 1px solid #0f73ee;
  width: 90px;
  padding: 5px 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: #0f73ee;
  cursor: pointer;
}

.search-button-filter:focus {
  outline: none;
}

.search-button:hover {
  background: #0f73ee;
  color: white;
}

.clear-button {
  color: #0f73ee;
  border-radius: 4px;
  border: 1px solid #0f73ee;
  width: 90px;
  padding: 5px 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: white;
  cursor: pointer;
}

.clear-button:hover {
  background: #0f73ee;
  color: white;
}

.reduce-space-right {
  margin-right: -11px;
}

.table-responsive {
  font-size: 12px;
}

.btn-hollow:hover {
  background: #0f73ee;
  color: white;
}

.btn-green {
  color: white;
  border-radius: 2px;
  border: 1px solid #46bc50;
  text-align: center;
  padding: 5px 10px;
  transition: all 0.2s;
  background: #46bc50;
  cursor: pointer;
}

.btn-green:hover {
  background: #48be52;
  color: white;
  opacity: 0.7;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.loader-parent {
  transform: translate(50%, 0%);
  padding-top: 50vh;
}

.save-button {
  color: #46bc50;
  border-radius: 5px;
  border: 1px solid #46bc50;
  text-align: center;
}
.req-button {
  margin-left: 142px;
  font-size: 16px;
  padding: 4px 12px 5px 12px;
}
.req-button2 {
  margin-left: 86px;
  font-size: 16px;
  padding: 4px 12px 5px 12px;
}
.no-border {
  border: 0px !important;
}
.mainBgBoder {
  border: 1px solid #ddd;
  border-radius: 2px;
}
.headingBg h3 {
  font-size: 22px;
  padding: 12px 0 10px 14px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}
.infoLeftMain p {
  margin: 0px;
  padding: 5px 10px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}
.navMainWrap {
  margin: 20px 0px;
}
.navMainWrap .nav-pills .nav-link.active,
.navMainWrap .nav-pills .show > .nav-link {
  color: #0f73ee !important;
  border-bottom: 2px solid #0f73ee;
  border-radius: 0px;
  background-color: transparent;
}
.navMainWrap a.nav-link {
  color: #1d1d26 !important;
  text-align: center;
}
.mainContentTab .tab-pane {
  padding: 0px;
}
.tableMainApp td,
.tableMainApp th {
  font-size: 12px;
}
p.tableAddAnchor {
  border: 1px solid #7ac953;
  color: #7ac953;
  display: inline-block;
  padding: 3px 10px;
  font-weight: bold;
  border-radius: 3px;
  text-decoration: none !important;
}
p.tableAddAnchor span {
  margin-right: 5px;
  font-weight: lighter;
}
.reqs {
  overflow-y: scroll;
  height: 308px;
}
.dimgray {
  color: #1d1d26;
}
.requirment-edit-button {
  position: relative;
  top: -50px;
  right: 10px;
  font-size: 12px;
  float: right;
}

button:disabled,
button[disabled] {
  border: 1px solid #fff !important;
  background-image: linear-gradient(#97beff, #97beff);
  color: #fff !important;
  border-radius: 4px;
}

button:disabled,
button[disabled]:hover {
  border: 1px solid #fff;
  background-image: linear-gradient(#97beff, #97beff);
  color: #fff;
}
.rc-pagination-item {
  margin-right: 0px !important;
  border-radius: 0px !important;
  height: 35px !important;
  width: 35px !important;
  border: 1px solid #dfdfdf !important;
  color: #4e505c !important;
  border-left: 0px !important;
  line-height: 35px !important;
}

.col-3-1 {
  flex: 0 0 26%;
  max-width: 26%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.rc-pagination-item-active {
  background: #046ef2 !important;
  border: 1px solid #046ef2 !important;
  color: #fff !important;
  border-radius: 0px !important;
  margin-right: 0px !important;
  height: 35px !important;
  border-right: 0px !important;
  line-height: 35px !important;
}

.rc-pagination-item:focus {
  outline: none;
}

.pagination-container {
  padding-left: 36%;
}
.discountPrice {
  /* position: absolute;  */
  top: 11px;
  padding: 2px;
  right: 15px;
  -webkit-transition: right 0.2s;
  transition: right 0.2s;
  text-align: center;
  background-color: #ddd;
  box-sizing: border-box;
}

.bookSubmit button.btn,
.bookInput button.btn {
  border: 1px solid #7ac953;
  margin-right: 10px;
  color: #7ac953;
  margin-top: 10px;
  box-shadow: 0 0 0 0 !important;
}
.customer-form {
  padding: 25px 20px 7px 20px !important;
}

.inputMain {
  width: 100%;
}

.form-check-label {
  margin-right: 90px;
}
input[type='radio'] {
  margin: 0 10px 0 10px;
}

.shareButton {
  margin-top: 15px;
  margin-left: 20px;
  border: none;
}

.mainPageShareButton {
  margin-left: 20px;
  border: none;
}

.content {
  background-color: #fff;
  padding: 10px;
  width: 150px;
  font-weight: bold;
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  display: none;
}

input[type='image'] {
  color: transparent;
  text-shadow: 0 0 0 #000;
  padding: 6px 12px;
  width: 47px;
  cursor: pointer;
  position: relative;
}
input[type='image']:focus {
  outline: none;
}
input:focus + div.content {
  display: block;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  z-index: 2;
}

.content:hover {
  display: block;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  z-index: 2;
}

.graanaShare {
  background: linear-gradient(90deg, #ef5350 0, #ff775c);
  border: 0px solid #ddd;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  padding: 0px;
  width: 35px;
  height: 35px;
  font-weight: bold;
}

.graanaShareDisable {
  background: linear-gradient(90deg, #cccccc 0, #cccccc);
  border: 0px solid #999999;
  border-radius: 50%;
  color: #666666;
  font-size: 20px;
  padding: 0px;
  width: 35px;
  height: 35px;
  font-weight: bold;
}

.DayPicker-wrapper {
  font-size: 14px;
  width: 260px;
}

.DayPicker-Day--today {
  color: white !important;
  background-color: #66c851;
  border-radius: 50%;
  width: 34px;
}

.customerSelection {
  display: block;
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #e5e5e5;
  outline: none;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-size: 14px;
  padding: 0 12px;
  margin-bottom: 13px;
  color: #808080;
}

.custom-select {
  width: none !important;
  margin-bottom: 13px;
  background-color: #fff !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  padding: 0 12px !important;
  height: 40px !important;
  margin-bottom: 13px;
  color: #808080 !important;
  outline: none;
}

.custom-select:hover {
  background-color: #f1f3f4 !important;
}

.rc-time-picker {
  /* width: 100%; */
  width: 173px !important;
}

.rc-time-picker-input {
  margin-bottom: 13px;
  background-color: #fff !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 0 !important;
  font-size: 14px !important ;
  padding: 0 12px !important;
  height: 40px !important;
  margin-bottom: 13px;
  color: #808080 !important;
}

.rc-time-picker-panel-inner {
  width: 280px !important;
}

.rc-time-picker-panel-select {
  min-height: 321px;
  width: 93px !important;
  margin-bottom: 0;
}

.rbc-header {
  border-bottom: 0;
  min-height: 60px;
  cursor: not-allowed;
}

.rbc-month-header {
  min-height: 34px;
}

.rbc-day-bg {
  cursor: pointer;
}
.popup-button-style {
  float: right;
}

.modal-header {
  padding: 15px 20px !important;
  background: #f2f2f2 !important;
  color: #454b4d !important;
  justify-content: space-between;
  border-bottom: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.form-control:focus {
  border-color: #e5e5e5 !important;
  box-shadow: none !important;
}

.form-group {
  margin-bottom: 0 !important;
}

.modal-footer {
  border-top: none !important;
  padding-top: 0px !important;
}

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #1d1c25 !important;
}

.DateInput_input {
  color: #1d1c25 !important;
  font-weight: 300 !important;
}

.focusText {
  color: #000 !important;
}

ul {
  margin-bottom: 0px !important;
}

.disabledButtonWidth {
  width: 150px;
}

.disabledButtonProjectWidth {
  width: 100px;
}
.dashboard-sec > .row > .col-sm-10 {
  padding-top: 14px !important;
  background-color: #e7ecf0;
}
.dashboard-sec > .row > .col-md-10 {
  padding-top: 20px !important;
  background-color: #e7ecf0;
}
.dashboard-sec > .row > .col-md-10 {
  padding-top: 44px !important;
  background-color: #e7ecf0;
}
.dash-container {
  background-color: #fff;
}
.dashboard-sec > .row {
  height: 100vh;
}

.customSelectHeight {
  max-height: 5px;
}

.tableHeight {
  height: calc(100vh - 100px);
}

::-webkit-scrollbar {
  height: 8px;
  overflow: visible;
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #21252947;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 5px !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.formHeight {
  height: calc(100vh - 97px) !important;
}

.newFormButton {
  background-color: #fff;
  border-radius: 100px;
  margin-left: 25px;
  margin-top: 3px;
  padding: 6px;
  font-size: 14px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
  color: #016ff2;
  border: 1px solid #016ff2;
}

.rc-pagination-prev {
  font-size: 14px !important;
  color: #016ff2 !important;
  border-radius: 0px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  line-height: 35px !important;
  width: 100px !important;
  margin-right: 0px !important;
  height: 35px !important;
}

.rc-pagination-next {
  font-size: 14px !important;
  border-radius: 0px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100px !important;
  margin-right: 0px !important;
  line-height: 35px !important;
  height: 35px !important;
  border-left: 0px !important;
}

.btn-custom {
  color: #0f73ee;
  border-radius: 2px;
  border: 1px solid #0f73ee;
  text-align: center;
  transition: all 0.2s;
  background: white;
  cursor: pointer;
}

.btn-custom:hover {
  background: #0f73ee;
  color: white;
}

.totalCountStyle {
  font-size: 13px;
  color: #1d1e27;
  margin-left: 13px;
}

.totalCountAlignment {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.totalCountSubStyle {
  color: #016ff2;
  margin-left: 5px;
}

.pagination-position {
  margin-left: 18%;
}

.dashboard-sec > .row > .col-sm-1 {
  width: 100%;
  max-width: 80px !important;
}

.dashboard-sec > .row > .col-sm-11 {
  padding-top: 14px !important;
  background-color: #e7ecf0;
}

.verticalScrollAdmin {
  display: block;
  height: calc(100vh - 88px);
  overflow: scroll;
}

.newFormSearch {
  background-color: #016ff2;
  height: 33px;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35px;
  font-weight: bold;
  border: none !important;
  color: #fff;
  border-radius: 4px;
}

.newFormSearch:focus {
  outline: none;
}

.newAccountSearch {
  background-color: #016ff2;
  height: 33px;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  font-weight: bold;
  border: none !important;
  color: #fff;
  border-radius: 4px;
}

.newAccountSearch:focus {
  outline: none;
}

.newFormSearchIcon {
  font-size: 12px;
}

.newFormClear {
  color: #000;
  border: none !important;
  background: #fff;
  font-size: 14.5px;
  border-bottom: 1px solid #000 !important;
  margin-left: 12px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.newClearDisabledButton {
  color: #666666;
  border: none !important;
  background: #fff;
  font-size: 14.5px;
  margin-left: 12px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #666666 !important;
  cursor: not-allowed;
}

.newFormClear:focus {
  outline: none;
}

.filterInputSelected {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  box-sizing: border-box;
  border-color: #046ef2;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  transition: all 100ms ease 0s;
  outline: 0px !important;
}

.filterInputStyle {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;
  color: #046ef2;
  flex: 1 1 0%;
  padding: 2px 8px;
  overflow: hidden;
  border: none;
  outline: none;
}

.user-form {
  padding: 13px 10px 7px 10px !important;
  /* background: #e7ecf0; */
}

.BankAccountAndNextOfkin-form {
  padding: 0px 10px 7px 10px !important;
  /* background: #e7ecf0; */
}

.col-1-5 {
  flex: 0 0 10%;
  max-width: 10%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-0-5 {

  flex: 0 0 7.6%;
  max-width: 7.6%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-1-6 {
  flex: 0 0 13%;
  max-width: 13%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2-2 {
  flex: 0 0 17.7%;
  max-width: 17.7%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-4-5 {
  flex: 0 0 30%;
  max-width: 30%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
}

.col-2-3 {
  flex: 0 0 18.7%;
  max-width: 18.7%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-5-3 {
  flex: 0 0 37.666667%;
  max-width: 37.666667%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
}

.col-5-3 {
  flex: 0 0 47.666667%;
  max-width: 47.666667%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
}


.col-2-5 {
  flex: 0 0 20.3%;
  max-width: 20.3%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2-6 {
  flex: 0 0 22%;
  max-width: 22%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.defaultBorderColor {
  border: 1px solid #ccc !important;
  font-weight: 300;
  border-radius: 3px;
}

.col-3-5 {
  flex: 0 0 28%;
  max-width: 28%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-3-6 {
  flex: 0 0 30%;
  max-width: 30%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-6-5 {
  flex: 0 0 53.333333%;
  max-width:53.333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-7-5 {
  flex: 0 0 60.333333%;
  max-width: 60.333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-3-8 {
  flex: 0 0 37.8%;
  max-width: 37.8%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.assignUnassignButton {
  border: 1px solid #fff;
  background-image: linear-gradient(#97beff, #97beff);
  color: #fff !important;
  padding: 3px;
  border: none !important;
  border-radius: 4px;
  cursor: not-allowed;
  border-bottom: none !important;
  margin-right: 5px;
}

.disabledButton {
  border: 1px solid #fff;
  background-image: linear-gradient(#97beff, #97beff);
  color: #fff;
  border-radius: 4px;
}

.columnMaxHeightZero {
  max-height: 0px;
}

.status-text {
  font-weight: bold;
  font-size: 14px;
}

.text-primary-payment {
  color: #007bff;
}



.overide-placeholder .css-1wa3eu0-placeholder {
  color: #046ef2;
}

.btn-hollow-activate {
  color: #0f73ee;
  border-radius: 2px;
  border: 1px solid #0f73ee;
  text-align: center;
  transition: all 0.2s;
  background: white;
  cursor: pointer;
}

.textAlignmentFromLeft {
  padding-left: 24px !important;
}

.loaderAlignment {
  padding-left: 28px !important;
  display: inline-block;
}

.parent_container {
  position: relative;
}

.table-5-width {
  max-width: 55px !important;
  min-width: 55px !important;
  padding: 7px !important;
}

.table-8-width {
  max-width: 78px !important;
  min-width: 78px !important;
  padding: 7px !important;
}

.table-10-width {
  max-width: 100px !important;
  min-width: 100px !important;
  padding: 7px !important;
}

.table-15-width {
  max-width: 120px !important;
  min-width: 120px !important;
  padding: 7px !important;
}

.table-20-width {
  max-width: 150px !important;
  min-width: 150px !important;
  padding: 7px !important;
}

.table-25-width {
  max-width: 170px !important;
  min-width: 170px !important;
  padding: 7px !important;
}

.table-35-width {
  max-width: 230px !important;
  min-width: 230px !important;
  padding: 7px !important;
}

.table-20-percen-width {
  width: 20%;
  padding: 7px !important;
}

.spaceFromRight {
  margin-right: 20px;
}

.box {
  min-width: 250px;
  min-height: 40px;
  position: absolute;
  top: 0;
  text-align: left;
  right: 37px;
  border: 1px solid #e2e2e3;
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: -0.5px 0px 5px -2.5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -0.5px 0px 5px -2.5px rgba(0, 0, 0, 0.75);
  box-shadow: -0.5px 0px 5px -2.5px rgba(0, 0, 0, 0.75);
}

.box_icon {
  font-size: 18px;
  cursor: pointer;
  width: 38px;
  color: #565863;
}

.paymentBox_icon {
  font-size: 18px;
  cursor: pointer;
  width: 22px;
  color: #565863;
  top: 4px;
}

.paymentBox_iconOne {
  font-size: 18px;
  cursor: pointer;
  width: 5px;
  color: #565863;
  top: 4px;
}

.overFlowHidden {
  overflow: hidden !important;
}

.box_icon:hover {
  color: #007bff;
}

.box_active_color {
  color: #007bff;
}

.container_separator:last-child {
  border-bottom: none;
}

.container_separator {
  border-bottom: 1px solid #eff0f0;
  padding-bottom: 6px;
  padding-top: 6px;
  font-size: 14px;
  padding-left: 15px;
  color: #676873;
  cursor: pointer;
}

.container_separator:hover {
  background-color: #f6f7fa;
}
.stack-top {
  z-index: 9;
  margin: 20px;
}

.newClassButton {
  position: sticky;
  right: 0px;
  width: 100%;
  background-color: #f6f7fa;
}

.c {
  position: sticky;
  right: 0px;
  background-color: #f6f7fa;
}

.footerClassButton {
  position: sticky;
  right: 0px;
  width: 100%;
  background-color: #f6f7fa;
}

.unitActionButtonFixed {
  position: absolute;
  right: 15px;
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.popupButtonStyle {
  background: none;
  width: 100%;
  border: none;
  text-align: left;
  border-bottom: 1px solid #eff0f0;
}

.popupButtonStyle:focus {
  outline: none;
}

.max-table-width {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.backgroundOnClick {
  background-color: #046ef21f;
}

.css-1uccc91-singleValue {
  font-size: 15px;
}

.filter__Top:not(.active__filter) .css-1uccc91-singleValue {
  color: #000 !important;
}
.filter__Top.active__filter .css-1uccc91-singleValue {
  color: #046ef2 !important;
}


.css-oo9zya-singleValue {
  color: #000 !important;
  font-size: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.user-first-index-width {
  width: 6.5%;
  padding-left: 13px;
}

.pt-2-5 {
  padding-top: 0.8rem !important;
}

.pr-6 {
  padding-right: 1.7rem !important;
}

.user-table-first {
  width: 7%;
}

.activity-table-30 {
  width: 30%;
}

.over-ride-class .css-26l3qy-menu {
  position: absolute !important;
  z-index: 1111 !important;
}