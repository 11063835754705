
.mainWrapper{
 position: relative;
.addCompainBtn{
    border: none;
    padding: 7px;
    border-radius: 8px;
    position: absolute;
    right: 45px;
    z-index: 10;
    top: 18px;
}
}
.ModalHeader{background: 'none !important'
    ;border : 'none'}


    .buttonIn {
        margin-bottom: 1rem;
        position: relative;
    }
    .inputB {
        margin: 0px;
        padding: 0px;
        width: 100%;
        outline: none;
        padding: 0.375rem 0.75rem;
        border-radius: 5px;
    }
      
    .addNobutton {
        position: absolute;
    top: 0;
    border-radius: 5px;
    right: 19px;
    z-index: 2;
    border: none;
    top: 10.5px;
    height: 33px;
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
    }

    .PhoneNodeleteIcon{
        color: #db3737;
        font-size: 19px;
        cursor: pointer;
        bottom: 62px;
        margin-left: 2px;
    }
    .PhoneNoEditIcon{
        color: #969595;
        font-size: 17px;
        cursor: pointer;
        bottom: 62px;
        margin-left: 2px;
    }
    .PhoneNoContainer{
        max-height: 200px;
        overflow-y: auto;
        padding: 10px 16px;
    }


    .callCenterDateRange {
        .DateRangePicker {
          width: 100%;
        }
        .DateRangePickerInput {
          height: 38px !important;
          width: 225px;
          overflow: hidden;
          display: flex !important;
          align-items: center;
          border: 1px solid #dbdbdb !important;
          border-radius: 5px !important;
        }
        &.selectedDateRange {
          .DateRangePickerInput {
            color: #046ef2 !important;
            border: 1px solid #2684ff !important;
            box-shadow: 0 0 0 1px #2684ff !important;
            min-width: "50%";
          }
          #wanted_date_input_start,
          #wanted_date_input_end {
            color: #2684ff !important;
          }
        }
        #wanted_date_input_start,
        #wanted_date_input_end {
          font-size: 16px !important;
          padding: 0px;
          text-align: center;
          font-weight: 400 !important;
          color: #787878 !important;
          &:focus {
            outline: none;
            border: none;
          }
        }
        .DateRangePicker_picker {
          top: 40px !important;
        }
      }

      .ccmc-clearFilter{
        min-width: 80px;
        gap: 4px;
      }