
.time-date-picker-cus-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal-content{
    width:570px !important;
  }
}

.cus-follow-up > div > div > label > span {
  margin-right: 4px !important;
}
