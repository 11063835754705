.card-box {

  border: none;
  box-shadow: 0px 1px 10px 5px #ececec;
  margin-top:15px;
  border-radius: 10px;

  .text-blue{
    color:#046ef2
  }
}

.dropDownForViwing {
  position: inherit;
  width: 30px !important;
  opacity: 1 !important;
  max-height: 1px !important;
}

.diary-item {
  position: relative;

  .icon-position-right {
    align-items: flex-end;
    margin-right: 15px;
    float: right;
    .time-main-container {
      background-color: white;
      width: 180px;
      vertical-align: middle;
      padding-right: 5px;
      padding-left: 5px;
      padding-bottom: 2px;
      text-align: center;
      border: 1px solid #eeeeee;
      border-radius: 100px;
      .time-main-container-text {
        color: #7a7a7a;
        font-size: 12px;
      }
    }
  }
  .diary-icon {
    width: 35px;
    height: 35px;
  }
  .action-dropdown {
    padding-right: 1px;
    cursor: pointer;
    top: 5px;
    i.fa-ellipsis-v {font-size: 22px}
  }
  .diary-feedback {
    margin-left: 44px;
    width:515px;
  }
}
