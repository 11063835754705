.close-lost-modal {
  .feedback-container {
    margin-top: 20px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;

    .feedback-area {
      width: 100%;
      margin-top: 10px;
      max-height: 370px;
      overflow: auto;

      .space-between {
        /*word-wrap: break-word;
        font-size: 13px;
        border: 1px solid #e2e2e3;
        border-bottom: none;
        min-width: 60px;
        margin-right: 10px;
        height: 42px;
        cursor: pointer;
        padding: 10px;
        &:first-child {
          border-top-right-radius: 4px;
          border-top: 1px solid #e2e2e3;
        }
        &:last-child {
          border-bottom: 1px solid #e2e2e3;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        &:nth-child(even) {
          background: #fafafb;
        }*/
        font-size: 13px;
        margin: 5px;
        cursor: pointer;
        width: auto;
        float: left;
        padding: 5px 15px;
        border: 1px solid;
        border-radius: 14px;
      }
    }
  }

  .feedback-popup-width {
    width: 100%;
    max-width: 700px !important;
  }

  .feedback-width {
    width: 700px !important;
  }

  .closed-lost-button-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .closed-lost-button-success {
      background: #dc3647;
      border: 2px solid #dc3647;
      border-radius: 50px;
      color: #fff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      height: 35px;
      padding: 2px;
      width: 250px;
      margin: 0 auto;

      &:focus {
        outline: none;
      }
    }

    .closed-won-button-success {
      background: #1073ee;
      border: 2px solid #1073ee;
      border-radius: 50px;
      color: #fff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      height: 35px;
      padding: 2px;
      width: 250px;
      margin: 0 auto;

      &:focus {
        outline: none;
      }
    }

    .closed-lost-button-back {
      margin-right: 10px;
      background: #fff;
      border: 1px solid #dddddd;
      border-radius: 50px;
      color: #000;
      height: 20px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 15px;
      padding: 5px;
      width: 150px;
      height: 35px;

      &:focus {
        outline: none;
      }
    }
  }

  .close-icon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    .close-icon {
      color: #909090;
      font-size: 22px;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  textarea {
    width: 100%;
    resize: none;
    border: 1px solid #dededf !important;
    border-radius: 3px;
    padding: 8px;
    font-size: 14px;
  }

  textarea:focus {
    outline: none;
  }
}
