/** @format */

.read__only {
  position: relative;
  background-color: #fffce4;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #f0e15a;
  font-size: 14px;
  color: #000;
  padding-left: 20px;

  img {
    position: relative;
    width: 20px;
    margin-right: 15px;
    top: -1px;
    object-fit: contain;
  }

  i {
    position: absolute;
    font-size: 16px;
    color: #000;
    z-index: 20;
    cursor: pointer;
    top: 11px;
    right: 11px;
  }
}
