/** @format */

.buy-rent-tile-card {
  background: #fff;
  padding: 10px;
  height: 148px;
  border: 1px solid #e0e0e1;
  border-radius: 5px;

  .card-grid {
    display: grid;
    grid-template-columns: 15% 35% 17% 33%;
  }

  .slider-card-img {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 3px;
    .not-found-wrap {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50px;
      .not-found-img {
        width: auto;
        height: 50px;
      }
    }

    .img-card-cus {
      height: 130px;
      width: 100%;
    }
    .no-image-buy-rent {
      height: 100px;
      width: auto;
      // margin-top: 15px;
    }
    .property-img-card {
      height: 110px;
    }
  }

  .card-price-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    color: #016ff2;
    text-transform: uppercase;
  }

  .description-font-size {
    font-size: 15px;
  }

  .loc_image {
    width: 20px;
    height: 20px;
  }

  .check-image {
    width: 18px;
    height: 18px;
  }

  .bed-and-bath-img {
    width: 25px;
    height: 25px;
  }

  .agent-image {
    width: 20px;
    height: 20px;
  }
  .card-price-currency {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
  }

  .capitilized-text {
    text-transform: capitalize;
  }

  .property-info {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .right-options-text {
    font-size: 12px;
    margin-bottom: 7px;
    text-align: left;
  }

  .property-location {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .location-icon {
    color: #056ef2;
    margin-right: 3px;
  }

  .property-bath-bed-info {
    font-size: 14px;
  }

  .bed-icon {
    color: #056ef2;
    margin-right: 2px;
  }

  .bath-icon {
    color: #056ef2;
    margin-right: 1px;
    margin-left: 15px;
  }

  .check-circle-icon {
    color: #056ef2;
    margin-right: 7px;
    margin-left: 40px;
  }
}

.checkbox {
  margin-left: 50%;
  margin-top: 115%;
}

.checkbox-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.one-edge-shadow {
  -webkit-box-shadow: 0 4px 2px -2px #e7ecf0;
  -moz-box-shadow: 0 4px 2px -2px #e7ecf0;
  box-shadow: 0 4px 2px -2px #e7ecf0;
}

.read-only-mode {
  pointer-events: none;
}
