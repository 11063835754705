.feedback-header{
  .feedback-msg , p{
    text-align: center;
  }
}
.devices-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 410px;
  .feedback-msg{
    text-align: center;
  }
  .single-device:not(.selected):hover {
    background-color: #eaeaea;
    cursor: pointer
  }

  .device-call-button {
    display: none;
  }

  .single-device:hover .device-call-button {
    display: block;
  }

  .device-call-button {
    position: absolute;
    bottom: 0;
    color:white!important;
    top: 0;
    right: 30px;
    margin: auto;
    height: 40px;
  }
  .ok-button{
    position: absolute;
    bottom: 0;
    color:white!important;
    top: 0;
    right: 30px;
    margin: auto;
    height: 40px;
  }

  .preferred-device {
    height: 15px;
    position: absolute;
    right: 43px;
    top: -4px;
  }

  .device-img {
    width: 30px;
    height: 60px;
  }

  .no-device-img {
    height: 170px;
  }
}
