.geo-tag-modal{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  .modal-content{
    width: 90vw !important;
    margin-left: 0px !important;
  }  
}
.map-main-wrap{
  display: flex;
    flex-direction: row;
    width:70%;
}
.main-modal-map-wrap{
  display: flex;
  flex-direction: row;
}
.select-wrap{
  width:30%;
  display: flex;
  flex-direction:column;
}
.cus-card{
  margin-top: 25px;
    box-shadow: 0 0 5px #d3d3d3;
    border-radius:10px;
    width:70%;
      .main-wrap-card{
        padding: 20px;
        .title-card{
          p{
            font-size: 16px;
          }
        }
        .other-info{
          display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    width: 100%;
          .info-cus{
            display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
            &:last-child{
              margin-bottom: 0px;
            }
            .value{
              color: red;
            }
          }
        }
      }
}