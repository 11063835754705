/** @format */

.reason-container {
  color: #1d1e26;
  font-size: 13px;
  font-weight: 500;
}

.status-container {
  color: #000;
  .status-wrapper {
    padding: 4px 15px 4px 15px;
    font-size: 11px;
    font-weight: normal;
    width: 150px;
    border-radius: 100px;
    text-transform: capitalize;
  }
  .no-response-status {
    background-color: #ffc9c8;
    border: 1px solid #cf5c5a;
    color: #cf5c5a;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 10px;
  }
  .success-response-status {
    background-color: #ccfcf6;
    color: #119d9b;
    border: 1px solid #119d9b;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 10px;
  }

  .middle-response-status {
    background-color: #aad1ff;
    color: #046ef2;
    border: 1px solid #046ef2;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 10px;
  }
}
