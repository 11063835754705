/** @format */

.wantedLeadsHeader {
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #1d1e27;
  padding: 10px;
}
.wantedLeadsTable {
  width: -webkit-fill-available !important;
  min-width: max-content !important;
}
.wantedLeadsTableContainer {
  max-height: calc(100% - 50px);
  overflow: auto;
}
.wantedLeadsRow {
  font-size: 11px;
  color: #50525e;
  cursor: pointer;
}

.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.wlRequirementLabelWidth {
  width: 230px;
}
.noColor {
  background-color: transparent;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 60%;
  margin-left: 40px;
}
.ddIsOpen {
  opacity: 1 !important;
}

.wantedDateRange {
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    height: 38px !important;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    border: 1px solid #dbdbdb !important;
    border-radius: 5px !important;
  }
  &.selectedDateRange {
    .DateRangePickerInput {
      color: #046ef2 !important;
      border: 1px solid #2684ff !important;
      box-shadow: 0 0 0 1px #2684ff !important;
    }
    #wanted_date_input_start,
    #wanted_date_input_end {
      color: #2684ff !important;
    }
  }
  #wanted_date_input_start,
  #wanted_date_input_end {
    font-size: 16px !important;
    padding: 0px;
    text-align: center;
    font-weight: 400 !important;
    color: #787878 !important;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .DateRangePicker_picker {
    top: 40px !important;
  }
}
.wantedFilterPopup {
  .modal-content {
    overflow: visible !important;
  }
}
.wanted-lead-link {
  color: #007bff !important;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}
