.inv-table {
  height: calc(100vh - 260px) !important;
  width: 100%;
  position: relative;
  table {
    /* border-collapse: collapse; */
    overflow-x: scroll;
    display: block;
    /* text-align: left; */
    /* position: relative; */
    border-collapse: separate;
    border-spacing: 0;
    thead tr {
      background-color: #f6f7fa;
      position: sticky;
      top: 0px;
      z-index: 2;
    }
    th {
      border: none !important;
      border-radius: 2px;
      text-transform: uppercase;
      font-weight: bold !important;
      height: 50px;
      font-size: 12px !important;
      color: #1d1e27;
      padding: 10px;
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
      display: inline;
    }
    td {
      border: none !important;
      border-bottom: 1px solid #f2f2f4 !important;
      color: #50525e !important;
      padding: 4px 10px;
      height: 50.5px !important;
      font-size: 11px;
    }
  }
}

.linkStylePropsure {
  color: #046ef2;
}

.hoverForTr:hover img {
  opacity: 1 !important;
}
.hoverForTr:hover .phone__button {
  opacity: 1 !important;
}
 
.customWidth{
  width: 20%
}