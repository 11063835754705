/** @format */

.no-result-image {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
