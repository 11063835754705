/**
 * /*
 * .navbar-center-title{display:none}
 *
 * @format
 */

.inprogressBooking {
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    height: 38px !important;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    border: 1px solid #dbdbdb !important;
    border-radius: 5px !important;
  }
  &.selectedDateRange {
    .DateRangePickerInput {
      color: #046ef2 !important;
      border: 1px solid #2684ff !important;
      box-shadow: 0 0 0 1px #2684ff !important;
    }
    #project_date_input_start,
    #project_date_input_end {
      color: #2684ff !important;
    }
  }
  #project_date_input_start,
  #project_date_input_end {
    font-size: 16px !important;
    padding: 0px;
    text-align: center;
    font-weight: 400 !important;
    color: #787878 !important;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .DateRangePicker_picker {
    top: 40px !important;
  }
}

.MoreFilterphonenumberwrapper {
  position: relative;
  .react-tel-input {
    // padding-bottom: 3px;
  }
  input.MoreFilterinputText {
    position: absolute;
    top: 0px;
    right: 0px;
    /* padding-bottom: 6px; */
    padding: 3px 5px;
    width: 85% !important;
    border: 1px solid #dbdbdb;
    // height: 38px;
    height: 35px;
    outline: none;
    padding-left: 10px;
    border-radius: 0px 5px 5px 0px;
  }
}
