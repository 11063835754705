/** @format */
/** Responsive => For all screens **/
.DateInput {
  width: 100px !important;
}

.DateInput_input {
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 5px !important;
}

@media screen and (max-width: 1366px) {
  .br-lead-page-wrap {
    .DateInput {
      width: 76px !important;
    }
    .DateInput_input {
      font-size: 15px !important;
      line-height: 20px !important;
      padding: 5px !important;
    }
    .moreFilterPosition {
      margin-right: 26px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .br-lead-page-wrap {
    .DateInput {
      width: 70px !important;
    }

    .DateInput_input {
      font-size: 15px !important;
      line-height: 20px !important;
      padding: 5px !important;
    }

    .moreFilterPosition {
      margin-right: 10px;
    }
  }
}
@media screen and (min-width: 1440px) {
  .br-lead-page-wrap {
    .DateInput {
      width: 83px !important;
    }

    .DateInput_input {
      font-size: 15px !important;
      line-height: 20px !important;
      padding: 5px !important;
    }

    .moreFilterPosition {
      margin-right: 44px;
    }
  }
}
@media screen and (min-width: 1600px) {
  .br-lead-page-wrap {
    .DateInput {
      width: 95px !important;
    }

    .DateInput_input {
      font-size: 15px !important;
      line-height: 20px !important;
      padding: 5px !important;
    }

    .moreFilterPosition {
      margin-right: 85px;
    }
  }
}
@media screen and (min-width: 1680px) {
  .br-lead-page-wrap {
    .DateInput {
      width: 100px !important;
    }

    .DateInput_input {
      font-size: 15px !important;
      line-height: 20px !important;
      padding: 5px !important;
    }

    .moreFilterPosition {
      margin-right: 135px;
    }
  }
}
@media screen and (min-width: 1920px) {
  .br-lead-page-wrap {
    .DateInput {
      width: 120px !important;
    }

    .DateInput_input {
      font-size: 15px !important;
      line-height: 20px !important;
      padding: 5px !important;
    }
  }
}
@media screen and (min-width: 2048px) {
  .br-lead-page-wrap {
    .DateInput {
      width: 130px !important;
    }

    .DateInput_input {
      font-size: 15px !important;
      line-height: 20px !important;
      padding: 5px !important;
    }

    .moreFilterPosition {
      margin-right: 165px;
    }
  }
}

.Id_textfield {
  height: 35px;
  padding-left: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 100% !important;
  outline: none;
}

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: none !important;
}

.DateRangePickerInput__showClearDates {
  padding-right: 0px !important;
}

.br-lead-page-wrap {
  .css-26l3qy-menu {
    z-index: 3;
  }
  .link-style {
    cursor: pointer;
  }

  .inputStyle {
    height: 36px;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    outline: none;
    padding-left: 10px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    flex: 1 1 0%;
    padding: 2px 8px;
    overflow: hidden;
    border: none;
    outline: none;
  }

  .selection-style {
    border: none !important;
    padding: 0px !important;
    outline: none !important;
    border-bottom: 0px !important;
    height: 23px !important;
    color: black !important;
    font-size: 12px !important;
  }

  .br-lead-table {
    border-collapse: collapse;
    overflow-x: scroll;
    display: block;

    td {
      border: none !important;
      border-bottom: 1px solid #f2f2f4 !important;
      color: #50525e !important;
      padding: 4px 10px;
      height: 50.5px;
      font-size: 11px;
    }

    thead {
      tr {
        position: sticky;
        z-index: 2;
        top: 0;
      }

      th {
        border-bottom: 2px solid #dee2e6;
      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th {
      border: none !important;
      border-radius: 2px;
      text-transform: uppercase;
      font-weight: bold !important;
      height: 50px;
      font-size: 12px !important;
      color: #1d1e27;
      background-color: #f6f7fa;
      padding: 10px;
    }
  }

  .customBtn {
    position: relative;
    color: #0f73ee;
    font-size: 12px;
    border: 1px solid #0f73ee;

    &:hover {
      color: #fff;
    }
  }

  .dropdownStyle {
    border-radius: 5px !important;
    border: 1px solid #b3b3b3 !important;
    color: black !important;
    height: 35px !important;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;

    .tooltiptext {
      width: 120px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      margin-left: -60px;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
    }
  }

  .projectLeadButton {
    color: #0f73ee;
    border-radius: 2px;
    border: 1px solid #0f73ee;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background: white;
    cursor: pointer;
    height: 30px;

    &:hover {
      background: #0f73ee;
      color: white;
    }
  }

  .floorAndUnitBtn {
    color: #0f73ee;
    border-radius: 2px;
    border: 1px solid #0f73ee;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background: white;
    cursor: pointer;
    height: 20px;

    &:hover {
      background: #0f73ee;
      color: white;
    }
  }

  .floorCustomBtn {
    color: #0f73ee;
    border-radius: 2px;
    border: 1px solid #0f73ee;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background: white;
    cursor: pointer;
    height: 35px;

    &:hover {
      background: #0f73ee;
      color: white;
    }
  }

  .floorDisableButtonStyle {
    height: 36px;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    outline: none;
    padding-left: 10px;
    background-color: #e6e5e5;
    cursor: not-allowed;
  }

  input {
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .mainTable {
    border: 1px solid hsla(0, 0%, 86.7%, 0.87);
    border-radius: 3px;
    margin-top: -26px;
  }

  .mainTableHeader {
    border-bottom: 1px solid #ddd;
    background-color: #f5f5f5;
    padding: 10px 0 10px 14px;
  }

  .DateRangePickerInput_clearDates_default {
    &:focus {
      background: #dbdbdb;
      border-radius: 0px !important;
    }

    &:hover {
      background: #dbdbdb;
      border-radius: 0px !important;
    }
  }

  .DateRangePickerInput_clearDates {
    padding: 12px !important;
    margin: 0px;
    display: none;
  }

  .custom-select {
    width: none !important;
    background-color: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    padding: 0 12px !important;
    height: 40px !important;
    margin-bottom: 13px;
    color: grey !important;
    outline: none;
    border-radius: 5px !important;
  }

  .inputStyleContainer {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    position: relative;
    box-sizing: border-box;
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    transition: all 100ms ease 0s;
    outline: 0px !important;
  }

  .menuContainer {
    top: 100%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-top: 8px;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
  }

  .menuList {
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-top: 8px;
    width: 90%;
    z-index: 1;
    box-sizing: border-box;
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: absolute;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }

  .menuListOption {
    background-color: transparent;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    border: none;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;

    &:hover {
      background-color: rgb(222, 235, 255);
    }
  }

  button {
    &:disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
  }

  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  .loader-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .loader-color {
    color: #046ef2;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }

  .comment-title {
    color: #075bc7;
  }

  .comment-body {
    font-size: 13px;
  }

  .comment-date {
    margin-left: 10px;
    font-size: 12.5px;
    font-weight: normal;
    color: #999999;
  }

  .customStyle {
    background-color: #fff;
    color: #0f73ee;
    height: 35px;
    padding-left: 10px;
    border: 1px solid #0f73ee;
    border-radius: 5px;
    width: 100%;
    outline: none;
  }

  .clickedDropShadow {
    background-color: #c2dbff !important;
    color: black;
  }

  .paddingTop {
    padding-top: 3px;
  }

  .date-filter-default {
    border: 1.9px solid #dbdbdb !important;
    padding: 0 !important;
    margin-left: 15px;
    border-radius: 5px;
    max-width: 24% !important;
  }

  .date-filter {
    border: 1.9px solid #0f73ee;
    padding: 0 !important;
    border-radius: 5px;
    max-width: 24% !important;
  }
  .filter-hollow-button {
    color: #0f73ee;
    border-radius: 2px;
    border: 1px solid #0f73ee;
    text-align: center;
    padding: 5px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background: white;
    cursor: pointer;

    &:hover {
      background: #0f73ee;
      color: white;
    }
  }

  .customSelectHeight {
    max-height: 5px;
  }

  .user_search {
    border: none !important;
    width: 100%;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }

  .deletion-table {
    border-collapse: collapse;
    overflow-x: scroll;
    display: block;

    th {
      border: none !important;
      border-radius: 2px;
      text-transform: uppercase;
      font-weight: bold !important;
      font-size: 12px !important;
      color: #1d1e27;
      background-color: #f6f7fa;
      padding: 10px;
      text-align: left;
    }

    thead {
      display: block;
    }

    tbody {
      display: block;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tfoot {
      display: block;
    }

    td {
      border: none !important;
      border-bottom: 1px solid #f2f2f4 !important;
      color: #50525e !important;
      padding: 7px;
      text-align: left;
      height: 25px;
      font-size: 12px;
      border: 1px solid #dee2e6;
    }
  }

  .selectedBorderColor {
    border: 1px solid #046ef2 !important;
    font-weight: 300;
  }

  .defaultBorderColor {
    border: 1px solid #ccc !important;
    font-weight: 300;
    border-radius: 3px;
  }

  .refreshPage-main-wrap {
    position: absolute;
    top: -75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    right: 60px;
  }

  .message-wrap {
    box-shadow: 0 0 5px #a2a2a2;
    padding: 10px;
    border-radius: 5px;
  }

  .reload-btn {
    font-weight: 700;
    transition: 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      color: #0f73ee;
    }
  }

  .btn-view {
    border: 1px solid #046ef2 !important;
    border-radius: 3px;
    color: #046ef2;
    background-color: #fff;
    font-size: 12px;
    padding: 2px 10px !important;

    &:hover {
      border: 1px solid #046ef2 !important;
      border-radius: 3px;
      color: #046ef2;
      background-color: #fff;
      font-size: 12px;
      padding: 2px 10px !important;
    }
  }

  .css-1wa3eu0-placeholder {
    font-size: 14px;
  }

  .hover__for__btns {
    &:hover {
      background-color: #046ef21f;
      .cus-z-index {
        & > div {
          opacity: 1;
        }
      }
    }

    &:focus-within,
    &:focus {
      background-color: #046ef21f;
      .cus-z-index {
        z-index: 1;
        & > div {
          opacity: 1;
        }
      }
    }
  }

  .cus-z-index {
    position: sticky;
    right: 0;
    padding: 0px !important;

    > div {
      padding: 8px 0;
      opacity: 0;
      border-radius: 5px;
    }
  }

  .action-cus {
    background-color: #fafafa;
    border-radius: 5px;
    padding: 7px 5px;
    color: #1173ef;
    font-size: 12px;
    width: 70px;

    &::after {
      display: none;
    }
  }

  .icon-main {
    width: 18px;
    height: 18px;
  }

  .buyrent-action-menu.dropdown-menu.show {
    width: 200px;
    top: 10px !important;
    padding: 0px;
  }

  .dropdown-menu.show {
    width: 200px;
    top: 10px !important;
    padding: 0px;

    .btn {
      text-align: left;
      border-top: 1px solid #eff0f0 !important;
      font-size: 14px;
      padding: 8px 10px;
      margin: 0px;
      outline: none !important;
    }

    &:nth-child(1) {
      border-top: 0px !important;
    }

    .active {
      color: #046ef2;
    }
  }

  .quick-filter {
    span {
      font-size: 14px;
      padding: 11px;
      background: #f6f6f7;
      cursor: pointer;
      color: #43546e;
      margin-right: 10px;
    }

    span.selected {
      background-color: #016ff2;
      color: white;
    }

    .team-icon {
      color: white;
      margin-right: -5px;
      padding: 0;
      width: 39px;
      border: 2px solid white;
      border-radius: 100%;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .team-more {
      font-size: 13px;
      color: black;
      background-color: #eee;
    }

    .dropdown-menu.show {
      width: 280px;
      height: 400px;
      overflow-y: auto;
      background: white;
    }
  }

  span.statusText {
    border: 1px solid;
    border-radius: 11px;
    font-size: 10px;
    padding: 2px 5px;
    margin-left: 5px;
  }
}

.modal-content {
  margin-top: 20px !important;
  width: 100% !important;
  margin-left: 0px !important;
}
.modal-backdrop {
  background-color: #0000005c !important;
}
.new-modal-content {
  margin: 0 auto;
  max-width: 1300px !important;
}
.delete-popup {
  margin: 0 auto;
  max-width: 700px !important;
}

.close-icon {
  position: absolute;
  right: 20px;

  &:focus {
    outline: none;
  }
}
.leadSelection .br-lead-table td {
  height: 35px !important;
  cursor: pointer !important;
}
