.dialer-mws{
  .sidebar-header{
    display: block;
    padding: 5px 5px 5px 5px;
    border-bottom: 1px solid rgb(220, 220, 220);
    width: 100%;
    overflow: hidden;
    .btn-close{
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background: #007bff;
      float: left;
      padding: 3px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-size: 11px;
      line-height: 24px;
    }
    .total-agents{
      display: inline-block;
      font-size: 11px;
      line-height: 28px;
      float: right;
      padding-right: 30px;
      span{
        color: #fff;
      }
    }
    .status{
      float: right;
      line-height: 28px;
      font-size: 11px;
      .socket-status{
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 50%;
        float: right;
        box-shadow: 0px 1px 1px #dcdcdc;
      }
      .connected{
        background: green;
      }
      .disconnected{
        background: red;
      }
    }
    .lbl-agent{
    padding: 0px;
    margin: 0px;
    margin-left: 1rem;
    }
    .connectedlbl{
      color: green;
    }
    .disconnectedlbl{
      color: red;
    }
  }

  .dialer-content{
    display: block;
    float: left;
    width: 100%;
    // line-height: 2rem;
    .transfer-calls{
      border: 1px solid #dcdcdc;
    }
    .badge{
      color: #fff;
    }
  }

}
.sidebar-content{
  display: block;
  float: left;
  overflow-y: scroll;
  padding: 15px 0;

  .MuiPaper-root.MuiDrawer-paper{
    width: 100%;
    position: relative !important;
  }
  .MuiDrawer-paperAnchorDockedRight{
    border: none !important;
  }
  .makeStyles-root-1{
    padding-top: 0 !important;
  }
}

.transferCall{
  position: relative;
  .transfer-popup{
    position: absolute;
    border: 1px solid #dcdcdc;
    padding: 10px;
    width: 300px;
    left: -200px;
    right: 0;
    z-index: 9 !important;
    top: -50px;
    background: #fff;

    .transfer-header{
      border-bottom: 1px solid #dcdcdc;
      padding: 5px;


      .fa_close{
        border-radius: 10px;
        background: blue;
        color: #fff;
        display: block;
        padding: 5px;
        float: right;
        cursor: pointer;
      }
    }
    .transfer-body{

    }
  }
}

.mwsHideButton{
  position: absolute;
  z-index: 9999 !important;
}
.phonewrapper{
  position: relative;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
  // text-align: center;
  .phone{
    padding-bottom: 10px;
    border-bottom:1px ridge green;
  }

  .labels{
    width: 100%;
    display: flex;
    padding: 12px 20px;
    margin: 8px 0;
    font-variant-numeric: oldstyle-nums;
    text-align: center;
    font-size: 125%;
    line-height: 20px;
    letter-spacing: 2px;
  }

  .btn-block{
    width: 100%;
    display:inline-flex;
    justify-content: space-around;
  }
  .btns {
    border: 2px solid black;
    background-color: white;
    color: black;
    border-radius: 4px;
    padding: 8px 8px;
    font-size: 14px;
    cursor: pointer;
    min-width: 3rem;
    &:disabled{
      border-color: #f44336 !important;
      border: 2px dotted #f44336 !important;
      background-image: none;
    }
    img{
      width: 40px;
    }
  }
  .calling-info{
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin:auto;
    width: 100%;
    // border:1px solid red;
    padding:3rem;
    border-radius: 5px;
    // margin-bottom: 0px;
    background-color: hsla(120,60%,70%,0.3);
    label{
      font-size: 75%;
    }
    span{
      color:#04AA6D;
      font-size: 100%;
    }
  }
  .btn-accept {
    border-color: #04AA6D;
    color: green;
  }

  .btn-accept:hover {
    background-color: transparent !important;
    color: white;
  }
  .btn-reject {
    border-color: #f44336;
    color: red;
  }

  .btn-reject:hover {
    background: transparent !important;
    color: white;
  }
  .input-dial{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px ridge black;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    font-variant-numeric: oldstyle-nums;
    text-align: center;
    font-size: 125%;
    line-height: 20px;
    letter-spacing: 2px;
  }
  .input-dial:focus{
    box-sizing: border-box;
    border: 1px solid #04AA6D;
  }
  .btn-dial{
    width: 100%;
    padding: 12px 20px;
    background-color: #04AA6D;
    color: white;
  }
  .btn-dial:hover {
    background-color: #04AA6D;
    color: white;
  }

  .btns.btn-accept:disabled{
    border-color: #dcdcdc;
    color: darkgray;
  }
  .transfer-calss{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    border: 2px solid #dcdcdc;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;

    .table-res{
      overflow-y: scroll;
      max-height: 400px;
      width: 100%;

      table{
        tr{
          td,th{
            padding: 2px 5px !important;
            font-size: 12px;
            vertical-align: middle;
          }
        }
      }
    }

    .close-btn{
      float: right;
      margin-bottom: 5px;
    }
    .custom-select{
      box-sizing: border-box;
      border: 1px solid #04AA6D;
    }
  }
}
.phone-icons-mws{
  z-index: 99999 !important;
}
.modal-open {
  .diaryIconContainer.phone-icons-mws{
    right: 4px !important;
    top:45% !important
  }
  .phone-icons-mws {
    &.call_connected{
      i {
        border: 1px solid green;
        padding: 5px;
        background-color: green;
        color: #fff !important;
      }
    }
    &.call_disconnected{
      i {
        border: 1px solid red;
        padding: 5px;
        background-color: red;
        color: #fff !important;
      }
    }
  }
}
.call-logs {
  // padding:0.5rem;
  line-height: 2rem;

  .lsting-logs {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .heading-lbl {
    border-bottom: 1px solid black;
  }

  .img-icon{
    display: flex;
    flex-direction: column;
    img{
      width: 20px;
    }
  }
  .phone-time {
    display: flex;
    flex-direction: column;


    .dtm {
      font-size: 12px;
    }
  }

  .incoming-call {
    color: green
  }

  .outgoing-call {
    color: red
  }
}
.mws-tabs{
  padding-top: 10px;
  position: relative;
  .agent-buttons{
    position: static;
    float: right;
    margin-top: 21px;

    button{

    }

  }
  .panel-content{
    h6{
      font-size: 15px;
    }
  }
}

.callCenterDateRange {
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    height: 38px !important;
    width: 225px;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    border: 1px solid #dbdbdb !important;
    border-radius: 5px !important;
  }
  &.selectedDateRange {
    .DateRangePickerInput {
      color: #046ef2 !important;
      border: 1px solid #2684ff !important;
      box-shadow: 0 0 0 1px #2684ff !important;
      min-width: "50%";
    }
    #wanted_date_input_start,
    #wanted_date_input_end {
      color: #2684ff !important;
    }
  }
  #wanted_date_input_start,
  #wanted_date_input_end {
    font-size: 16px !important;
    padding: 0px;
    text-align: center;
    font-weight: 400 !important;
    color: #787878 !important;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .DateRangePicker_picker {
    top: 40px !important;
  }
}
.dialer-open{
  &.modal-open{
    .modal-dialog{
      margin-right: 23% !important;
     &.modal-xl{
       max-width: 68% !important;
     }
      &.modal-lg{
        max-width: 800px !important;
      }
    }
  }
}


.call-stats-logs{
  .header-filter{
    text-align: center;
    label{
      font-weight: normal;
    }
  }
  h3{
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  .mws-class-card{

    .card-header{
      text-transform: uppercase;
      text-align: center;
      padding: 10px;
    }
    .card-body{
      text-transform: uppercase;
      text-align: center;
      padding: 10px !important;
      font-weight: bold;
    }
  }
}
.call_action_buttons{
  margin-right: 28px !important;
  font-size: 9px !important;
}
.apexcharts-xaxis-texts-g{
  text{
    width: 200px;
    tspan{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
