/**
 * /* .input-box{
 *
 * @format
 */

/* width: 100%;
    height: 55px;
    border-radius: 3px;
    box-sizing: border-box;
    padding-left: 10px;
    border: 1px solid #e0e0e0;
    color: #757575;
    margin: 5px 0;
     */
/* } */
/* input.input-box::placeholder {
    /* font-size: 12px; */
/* } */

.react-select-container {
  color: unset;
}

.DateInput_input {
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 7px !important;
}

.DateRangePickerInput__showClearDates {
  border: none !important;
}

.DateRangePickerInput_clearDates {
  display: none !important;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 0px !important;
}

.DateRangePickerInput_clearDates {
  padding: 12px !important;
  margin: 0px;
}

/* .css-vj8t7z {
        border: none !important;
        border-width: 0px !important;
    } */

.text-area-field {
  width: 100%;
  resize: none;
  border: 1px solid #dededf !important;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
}
.text-area-field:focus {
  outline: none;
}
.user-validation{
  color: red !important;
}
.price-box {
  right: 10px !important;
  border-radius: 0% !important;
  text-align: center !important;
  margin-top: 4px !important;
  top: 8px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left !important;
}
.small-input-box {
  width: 50%;
  height: 55px;
  border-radius: 3px;
  box-sizing: border-box;
  padding-left: 10px;
  border: 1px solid #e0e0e0;
  color: #757575;
  margin: 5px 0;
}

.defaultBorderColor {
  border: 1px solid #ccc !important;
  font-weight: 300;
  border-radius: 3px;
}

/* input.small-input-box::placeholder { */
/* font-size: 12px; */
/* } */
.textarea-box {
  height: 100px !important;
}

.imagePreview {
  height: 100%;
  width: 100%;
  background-position: 50%;
  background-size: cover;
  border-radius: 3px;
  border-radius: 5px;
  overflow: visible;
  position: relative;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.grid-item {
  padding: 20px;
}

.closeButton {
  border-radius: 100%;
  position: absolute;
  background: #fafafa;
  font-weight: 600;
  color: #757575;
  border: none;
  height: 24px;
  width: 24px;
  margin-left: -7px;
  margin-top: -9px;
  z-index: 99999;
  box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

select option {
  padding: 20px !important;
}

select {
  color: #6c757c !important;
}

.selectedText {
  color: black !important;
}
.dropdownSelection {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fileUploadStyle {
  border: 1px solid #c2cfd6;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

input[type='file'] {
  width: 100px;
  color: transparent;
}

.clearBtn {
  position: absolute;
  right: 15.5px;
  top: 0px;
  height: 37.5px;
  width: 16%;
  background-color: #e2dfdf;
  z-index: 1;
  font-size: 12px;
  overflow: hidden;
  color: #000;
  display: grid;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 15px;
  border: 1px solid #eee;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/*
.newForm {
  background-color: #fff;
}

.newForm input {
  width: "100%";
  padding: 10px;
}

.newForm input:focus + label {
  opacity: 1;
}

.newForm input:focus + label:after {
  content: ":";
}

.newForm input:not(:focus) {
  content: ":";
}

.newForm label {
  opacity: 1;
  position: absolute;
  top: -10px;
  left: 26px;
  color: #0f73ee;
  font-size: 12px !important;
  background: #fff;
  transition: 0.5s;
} */

.css-7czn8s-placeholder {
  position: absolute;
  top: -10px;
  left: 10px;
  color: #8f9396 !important;
  font-size: 13px !important;
  font-weight: bold;
  background: #e7ecf0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 5px;
  padding-right: 5px;
}

.label-container {
  position: absolute;
  top: -17px;
  left: 26px;
  color: #8f9396;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px !important;
  background: #e7ecf0;
}

.label-container-disabled {
  position: absolute;
  top: -10px;
  left: 26px;
  color: #8f9396;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px !important;
  background: #fff;
}

.floating-label {
  -webkit-appearance: none !important;
}

.fieldHeight {
  height: 44px;
}

.custom-placeholder {
  color: rgb(128, 128, 128);
  margin-left: 15px;
  font-weight: 600;
  margin-right: 2px;
  position: absolute;
  top: -9px;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 13px;
  transition: top 0.1s ease 0s, font-size 0.1s ease 0s;
  background-color: #e7ecf0;
  z-index: 9999;
  padding-left: 4px;
  padding-right: 4px;
}

.description-area {
  border-radius: 3px;
  width: 100%;
  resize: none;
  padding: 5px;
  border: 1px solid #ced4da;
}

.description-area:focus {
  outline: none;
}
.shadowCheckbox{
  text-align: left !important;
}
.shadowLabel{
  font-weight: normal !important;
}
.accesslink{
  color: #007bff;
  font-size: 14px;
  text-decoration: none !important;
}
.frequency-investment {
  color: '#026ff2';
  border-right: '1px solid #026ff2';
  padding-right: '68px ' !important;
  border-right: 1px solid #b3b3b3;
  color: #4e4e4e;
  font-weight: bold;
  width: 65px;
}

.frequency-investment-text{
 color: '#026ff2' ;
  position: relative;
  left:35px
}
