.lead-categories {
  .lead-category {
    width: 200px;
    border-radius: 10px;
    margin: 15px auto;
    text-align: center;
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid
  }

  .lead-category:hover {
    transform: scale(1.05);
  }
}
