/** @format */

@media screen and (max-width: 1366px) {
  .collapseMenuHover {
    background-color: #000;
    position: fixed;
    left: 70px;
    z-index: 99999;
    width: 300px;
    padding-bottom: 10px;
    margin-top: -6px;
  }
}

@media screen and (max-width: 1280px) {
  .collapseMenuHover {
    background-color: #000;
    position: fixed;
    left: 75px;
    z-index: 99999;
    width: 300px;
    padding-bottom: 10px;
    margin-top: -6px;
  }
}

@media screen and (min-width: 1440px) {
  .collapseMenuHover {
    background-color: #000;
    position: fixed;
    left: 75px;
    z-index: 99999;
    width: 300px;
    padding-bottom: 10px;
    margin-top: -6px;
  }
}

@media screen and (min-width: 1600px) {
  .collapseMenuHover {
    background-color: #000;
    position: fixed;
    left: 75px;
    z-index: 99999;
    width: 300px;
    padding-bottom: 10px;
    margin-top: -6px;
  }
}

@media screen and (min-width: 1680px) {
  .collapseMenuHover {
    background-color: #000;
    position: fixed;
    left: 75px;
    z-index: 99999;
    width: 300px;
    padding-bottom: 10px;
    margin-top: -6px;
  }
}

@media screen and (min-width: 1920px) {
  .collapseMenuHover {
    background-color: #000;
    position: fixed;
    left: 75px;
    z-index: 99999;
    width: 300px;
    padding-bottom: 10px;
    margin-top: -6px;
  }
}

@media screen and (min-width: 2048px) {
  .collapseMenuHover {
    background-color: #000;
    position: fixed;
    left: 75px;
    z-index: 99999;
    width: 300px;
    padding-bottom: 10px;
    margin-top: -6px;
  }
}

/** Responsive => End **/
.width50 {
  width: 50%;
}
.new-add {
  color: #fff !important;
}

.expanded-area {
  max-height: 100px;
  overflow: scroll;
}

.expanded-area-text {
  font-size: 13px;
  color: #1d1d26;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 5px;
}
.expanded-area-text:hover {
  color: #0f73ee;
}

.selected-menu-txt {
  color: #0f73ee;
}
.dash-container-parent {
  border: 0px;
  margin-top: 50px;
}
.transitionAll {
  transition: 0.3s all;
}
.posRelative {
  position: relative;
}
.sec-nav-elements {
  position: absolute;
  width: 240px;
  left: 80px;
  top: -30px;
  background: #000;
  overflow-y: scroll;
}
.dash-child {
  padding: 0px !important;
  .innerChild-div {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    .element {
      width: 100%;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 0px !important;
      padding-bottom: 0px;
      position: relative;
      .iconSmall {
        position: absolute !important;
        left: 13px;
        top: -5px;
      }
      p {
        position: relative;
        left: 35px;
        color: #fff;
        margin-left: 8px;
        font-size: 14px;
      }
      span {
        position: relative !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: none !important;
        margin-right: 10px;
        padding-top: 5px;
      }
    }
    .prop-child {
      ul {
        padding-top: 10px;
        li {
          .active-class {
            color: #fff;
            padding: 5px 0px;
          }
        }
        &:hover .new-add {
          color: #fff !important;
        }
        &:hover .active-class {
          color: #fff !important;
        }
        .new-add {
          color: #9d9d9d !important;
          &:hover {
            color: #fff !important;
          }
        }
      }
    }
  }
}
.innerChild-div-blue {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  background: #0f73ee !important;
  .element {
    width: 100%;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0px !important;
    padding-bottom: 0px;
    position: relative;
    .iconSmall {
      position: absolute !important;
      left: 20px;
      top: -5px;
    }
    p {
      position: relative;
      left: 35px;
      color: #fff;
      margin-left: 20px;
      font-size: 14px;
    }
    span {
      position: relative !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: none !important;
      margin-right: 10px;
      padding-top: 5px;
    }
  }
  .prop-child {
    ul {
      padding-top: 10px;
      li {
        .active-class {
          color: #fff;
          padding: 5px 0px;
        }
      }
      &:hover .new-add {
        color: #fff !important;
      }
      &:hover .active-class {
        color: #fff !important;
      }
      .new-add {
        color: #9d9d9d !important;
        &:hover {
          color: #fff !important;
        }
      }
    }
  }
}
.prop-child {
  ul {
    padding-top: 10px;
    padding-left: 4px;
    box-sizing: border-box;
    li {
      box-sizing: border-box;
      padding: 0px 0px 0px 43px;
      transition: 0.3s ease-in-out;
      text-transform: uppercase;
      position: relative;
      &::before {
        content: '';
      }
      a {
        transition: 0.3s ease-in-out;
        text-decoration: none;
        color: #9d9d9d;
        font-size: 13px;
        width: 100%;
        display: inline-block;
        padding: 5px 0px;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
      &:hover {
        background: #3c3c3c;
        cursor: pointer;
        &::after {
          content: '' !important;
          position: absolute !important;
          width: 4px !important;
          height: 30px !important;
          top: 0;
          left: -4px;
          background: #fff !important;
        }
        a {
          color: #fff;
          text-decoration: none;
          &:hover {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
    div {
      .expanded-area-text {
        color: #9d9d9d;
        margin-left: 40px;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #fff;
        }
        .selected-menu-txt {
          color: #fff;
        }
      }
    }
  }
}
.innerBg {
  background: #212121;
  transition: 0.3s all;
}
.coloredTab {
  background-color: #016ff2;
  transition: 0.3s all;
}
.cus-innerBg:hover {
  background-color: #016ff2;
  transition: 0.3s all;
}
.active-class {
  background: #3c3c3c;
  cursor: pointer;
  &::after {
    content: '' !important;
    position: absolute !important;
    width: 4px !important;
    height: 30px !important;
    top: 0;
    left: 0;
    background: #fff !important;
  }
  .active-class {
    color: #fff;
    padding: 5px 0px;
  }
}
.new-add {
  background-color: #3c3c3c;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 30px;
    background: #fff;
  }
  .new-add {
    &::after {
      content: '';
      display: none;
    }
  }
}
.selected-menu-txt {
  position: relative;
}
span.selected-menu-txt::before {
  content: '';
  width: 4px;
  height: 20px;
  background: #fff;
  position: absolute;
  top: -2px;
  left: -40px;
}
.main-logo-wrap {
  width: 100%;
}
.img-wraper {
  background: #016ff2;
  height: 70px;
  padding-right: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iconsLarge-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  img {
    width: 20px;
    margin-left: 17px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1250px) {
  .element {
    .iconSmall {
      left: 10px !important;
    }
    p {
      left: 25px !important;
      font-size: 11px !important;
      top: 3px !important;
      margin-left: 12px !important;
    }
    span {
      margin-right: 5px !important;
    }
  }
  .prop-child {
    ul {
      li {
        padding: 0 0 0 39px !important;
        a {
          font-size: 11px;
        }
        &::after {
          height: 27px !important;
        }
      }
    }
  }
}
@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .element {
    .iconSmall {
      left: 10px !important;
    }
    p {
      left: 25px !important;
      font-size: 13px !important;
      top: 1px !important;
      margin-left: 20px !important;
    }
  }
}
.cus-innerBg {
  .collapsedSide {
    span {
      opacity: 0 !important;
    }
  }
  &:hover {
    .collapsedSide {
      span {
        opacity: 1 !important;
      }
    }
  }
}

.left-side-container {
  margin-top: 50px;
  height: calc(95vh - 200px);
  position: relative;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;
}

.collapse-left-side-container {
  margin-top: 50px;
  height: calc(100vh - 164px);
  position: relative;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;
}

.collapse-left-side-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.uncollapse-left-side-container {
  margin-top: 10px;
  height: calc(100vh - 130px);
  position: relative;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.left-side-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.collapseChild {
  height: 45px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding-top: 6px;
}

.hoverCollapseChild {
  height: 45px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  background-color: #046ef2;
  padding-top: 6px;
  cursor: pointer;
}

.icon-size {
  width: 18px;
  height: 18px;
}

.create-button{
  width: 100%!important;
  height: 35px;
  margin-left: 3%!important;
  color: #ffffff!important;
  background-color: #0078ee!important;
}
.dropdown-create{
  display: inline-block;
}
.dropdown-create .dropdown-menu{
  top: 0;
  left: 210px;
  margin: 0;
  padding: 0;
}
.dropdown-create .dropdown-menu>li{
  border-bottom: 1px solid lightgrey;
  padding: 10px 0;
}
.dropdown-create .dropdown-menu>li>a {
  padding: 10px 50px 10px 20px;
  color: #000;

}
.dropdown-create .dropdown-menu>li>a:hover {
  text-decoration: none;
  color: #000;

}
.hero:after {
  left: -14px;
  top: 28px;
  border: solid transparent;
  content: " ";
  position: absolute;
  border-color: rgba(58, 188, 61, 0);
  border-right-color: #ffffff;
  border-width: 7px;
  margin-top: -20px;
}
