/** @format */

.comment-main-wrapper {
  padding: 0 10px 0 10px;
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .comment-loader-area {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
}

.comment-popup-size {
  margin: 0 auto;
  width: 750px !important;
}

.comment-popup {
  width: 100%;
  max-width: 850px !important;
}

.general-popup {
  width: 100%;
  max-width: 690px !important;
}

.general-popup-normal-size {
  margin: 0 auto;
  width: 580px !important;
}

.general-popup-medium-size {
  margin: 0 auto;
  width: 650px !important;
}

.comment-popup-body-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .popup-body-header-title {
    font-size: 22px;
    font-weight: bold;
    color: #000;
  }
  .popup-body-header-icon {
    color: #909090;
    font-size: 22px;
    cursor: pointer;
  }
}

.comment-main-body {
  overflow-y: auto;
  height: calc(100vh - 324px);
  margin-bottom: 15px;
  .comment-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
  }
  .not-found-image {
    width: 350px;
    height: 300px;
  }
  .no-comments-title {
    color: #96969a;
    font-weight: 700;
    font-size: 23px;
  }
}

.general-main-body {
  overflow-y: auto;
  margin-bottom: 15px;
}

.text-area-container {
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  resize: none;
  border: 1px solid #dededf !important;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
}

textarea:focus {
  outline: none;
}

.comment-button {
  background-color: #016ff2;
  color: #fff;
  text-align: center;
  width: 150px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  border: none;
  line-height: 35px;
  margin-bottom: 20px;
}

.comment-button:focus {
  outline: none;
}

/** =============  Comment Modal End  ========== **/
/** ============================================ **/

.attachment-popup-size {
  margin: 0 auto;
  width: 600px !important;
}

.attachment-popup {
  width: 100%;
  max-width: 700px !important;
}

.attach-model-body {
  background-color: #f5f5f5;
}

.attachment-main-container {
  padding: 0 10px 0 10px;
  height: calc(100vh - 110px);
  .attachment-modal-header {
    margin-bottom: 20px;
    .attachment-header-title {
      font-size: 22px;
      font-weight: bold;
      color: #222222;
    }
  }

  .main-attachment-body {
    margin-top: 10px;
    .attachment-body-edges {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(192, 192, 192)' stroke-width='3' stroke-dasharray='5%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      padding: 20px;
      display: inline-block;
      width: 100%;
      border-radius: 5px;
      .attachment-body {
        padding: 30px 0 30px 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .upload-icon-size {
          width: 35px;
          height: 35px;
        }
        .drag-drop-text {
          font-size: 13px;
          color: #333333;
          padding: 8px 0 0 0;
        }
        .attached-file-text {
          font-size: 14px;
          font-weight: 600;
          color: #016ff2;
          cursor: pointer;
        }
      }
      &.dropdown-overlay {
        background-color: #f3f3f3cc;
        height: 197px;
        opacity: 0.4;
        .drag-drop-overlay-text {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex: 1;
          height: 100%;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .attachment-upload-container {
    margin: 40px 0 5px 0;
    text-align: center;
    .attachment-upload-button {
      background-color: #016ff2;
      color: #fff;
      border-radius: 50px;
      width: 150px;
      line-height: 35px;
      border: none;
    }
    .attachment-outline {
      @extend .attachment-upload-button;
      color: #016ff2;
      background: #fff;
      border: 1px solid #016ff2;
    }
  }
  .attached-container {
    width: 100%;
    margin-top: 15px;
    overflow-y: auto;
    height: calc(100vh - 498px);
  }
}

.customModal {
  padding: 10px;
  .modal-body {
    padding: 0px;
    border: 0px;
    box-shadow: 0 0 0 0;
    border-radius: 0px;
  }

  .modal-content {
    border: 0px;
    border-radius: 5px;
    overflow: hidden;
    height: calc(100vh - 40px);
  }

  .table-header-main {
    position: relative;
    background-color: #f5f5f5;
    margin-bottom: 15px;
    padding: 15px;
    font-weight: bold;
    font-size: 18px;
    .image-close {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .children {
    padding: 10px;
  }

  .fa-spin {
    color: #046ef2;
  }

  .table-footer-main {
    text-align: right;
    padding: 15px;
    padding-top: 0px !important;

    .btn {
      font-size: 12px;
    }
  }
}

.modal-10 {
  width: 10%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-15 {
  width: 15%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-20 {
  width: 20%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-25 {
  width: 25%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-30 {
  width: 30%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-34 {
  width: 34%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-35 {
  width: 35%;
  margin: 0 auto !important;
  max-width: none;
}

.modal-37 {
  width: 37%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-custom {
  min-width: 672px;
}
.modal-40 {
  width: 40%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-45 {
  margin: 0 auto !important;
  width: 45%;
  max-width: none;
}
.modal-50 {
  width: 50%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-55 {
  width: 55%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-60 {
  width: 60%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-65 {
  width: 65%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-70 {
  width: 70%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-75 {
  width: 75%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-80 {
  width: 80%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-85 {
  width: 85%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-90 {
  width: 90%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-95 {
  width: 95%;
  margin: 0 auto !important;
  max-width: none;
}
.modal-100 {
  width: 100%;
  margin: 0 auto !important;
  max-width: none;
}
