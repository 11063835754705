/** @format */

.timelineModal {
  padding: 10px;
  .modal-body {
    padding: 0px;
    border: 0px;
    box-shadow: 0 0 0 0;
    border-radius: 0px;
  }

  .modal-content {
    border: 0px;
    border-radius: 5px;
    overflow: hidden;
  }

  .table-header-main {
    position: relative;
    background-color: #f5f5f5;
    margin-bottom: 15px;
    padding: 15px;
    font-weight: bold;
    font-size: 18px;
  }

  .fa-spin {
    color: #046ef2;
  }

  .table-footer-main {
    text-align: right;
    padding: 15px;

    .btn {
      font-size: 12px;
    }
  }

  .table-main {
    position: relative;
    margin: 15px;
    height: calc(100vh - 300px);
    overflow: scroll;

    table {
      thead {
        tr {
          th {
            text-transform: uppercase;
            font-size: 12px;
            border: 0px;
            background-color: #f5f5f5;
            padding: 10px 15px;
            position: -webkit-sticky;
            position: sticky;
            top: 0px;
            z-index: 2;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 12px;
            border: 0px;
            padding: 10px 15px;

            .timeline-attachment {
              width: 40px;
            }
          }
        }
      }
    }
  }
}
