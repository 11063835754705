.class1{
border: 2px solid;
}
.modalwidth{
    max-width:1049px ;
    width: 100%;
}

.labelWidth{
    width: 45%;
}
.fieldWidth
{
width: 55%;
}
.client-add-bank-account-form1{
    padding: 13px 10px 7px 10px !important;
    height: 350px;
   
}

.client-add-bank-account-formError{
  padding: 13px 10px 7px 10px !important;
  height: 397px;
 
}

// .client-bank-account-form{
//     thead{
//         background-color: #f6f7fa;
//     }
// }

// .ClientBankAccountModal td {
//     border: none !important;
//     border-bottom: 1px solid #f2f2f4 !important;
//     color: #50525e !important;
//     padding: 10px;
//     text-align: left;
//     height: 25px;
//     font-size: 12px;
//     border: 1px solid #dee2e6;
//   }

  .ClientBankAccountModal {
    border-collapse: collapse;
    //overflow-x: scroll;
    display: block;
  }
  
  .ClientBankAccountModal th {
    border: none !important;
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 12px !important;
    color: #1d1e27;
    background-color: #f6f7fa;
    padding: 10px;
    // text-align: left;
  }
  
  .ClientBankAccountModal thead,
  .ClientBankAccountModal tbody,
  .ClientBankAccountModal tfoot {
    display: block;
  }
  
  .ClientBankAccountModal tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 302px);
  }
  
  .ClientBankAccountModal td {
    border: none !important;
    border-bottom: 1px solid #f2f2f4 !important;
    color: #50525e !important;
    padding: 10px;
    height: 25px;
    font-size: 12px;
    border: 1px solid #dee2e6;
  }

.saveBtn{
    background-color:#046ef2;
    color:  #fff ;
    padding:5px 19px;
    transition: 0.3s ease-in-out;
    border-radius: 5px;
    border:1px solid #046ef2;
    margin-top:20px;
      &:hover{
        background-color: #046ef2;
        color: #fff;
      }
  }
  .addBankAccountBtn{
   
        background-color: #fff;
        border-radius: 100px;
        margin-left: 25px;
        padding: 3px 25px !important;
        font-size: 14px;
        font-weight: 600;
        padding-left: 10px;
        color: #016ff2;
        border: 1px solid #016ff2;
        cursor: pointer
    
  }
  .client-Bank-account-modal-width {
    width: 842px !important;
    margin: 0 auto !important;
}

.form-control{
  border-radius: 4px!important;
  border-color: #eaeaea;
  font-size: 14px;
  &.invalid {
    border-color: red;
  }
}

.invaliddropdown {
  border: 1px solid red;
  border-radius: 5px !important;
}


.newClientThreeDotButton {
  position: sticky;
  right: 0px;
  width: 100%;
  background-color: #f6f7fa;
}



.newClientThreeDotButton {
  border: 0px;
  border-top: 1px solid #f2f2f4 !important;
  font-size: 12px;
  padding: 0px 10px !important;
  vertical-align: middle !important;
  width: 100px;
  min-width: 80px;
  text-align: center;

  .btn {
    box-shadow: 0 0 0 0 !important;

    &.disabled{
      opacity: 0.3;
    }
  }

  .dropdown-menu {
    padding: 0px;
    border-color: #ccc;
    overflow: hidden;
    max-width: 100px;
    z-index: 2;
    left: -80px !important;
    top: -30px !important;

    button {
      font-size: 12px;
      position: relative;
      border: 0px;
      border-top: 1px solid #ccc;
      padding: 5px 10px;
      box-shadow: 0 0 0 0 !important;
      outline: none;

    

      span {
        font-size: 10px;
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
        border-right: 1px solid #ccc;
        padding-right: 5px;
        width: 20px;
      }

      &:nth-child(1) {
        border-top: 0px;
      }
    }
  }
}
.titlemaxwidth{
max-width: 60%;
}
.orClass{
  font-size: 12px;
    margin-top: 6.5% !important;
    padding: 4px;
}