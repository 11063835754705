/** @format */
$borderSize: 1px;
$borderStyle: solid;
$borderColor: #dbdbdb;
$borderRadius: 5px;
$fontSize: 12px;
.borderCollapse {
  border-collapse: separate;
  border-spacing: 0px 5px;
}
.borderLeft {
  border-left: $borderSize $borderStyle $borderColor;
}
.borderRight {
  border-right: $borderSize $borderStyle $borderColor;
}
.borderTop {
  border-top: $borderSize $borderStyle $borderColor;
}
.borderBottom {
  border-bottom: $borderSize $borderStyle $borderColor;
}
.borderRadiusLeft {
  border-top-left-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
  overflow: hidden;
}
.borderRadiusRight {
  border-top-right-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

.padding-0 {
  padding: 0;
}
.background-fafafa {
  background: #fafafa;
}
.backgroundColorfff {
  background: #fff !important;
}
.minWidthDiaryTime{

  min-width: 85px;
}

.detailsBtn {
  color: #2e96f5;
  border: 1px solid #2e96f5;
}
.searchBtn {
  border: 1px solid #026ff2;
}
.diaryFilterPopup {
  margin-top: 12%;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 60%;
  margin-left: 40px;
}

$hotColor: #d30404;
$warmColor: #ff6600;
$coldColor: #0082eb;
$notDefinedColor: #9a9a9a;
@mixin applyColor($color) {
  border: 1px solid $color !important;
  color: $color !important;
}
@mixin getDDStyle {
  &[data-value='hot'] {
    @include applyColor($hotColor);
  }
  &[data-value='warm'] {
    @include applyColor($warmColor);
  }
  &[data-value='cold'] {
    @include applyColor($coldColor);
  }
  &[data-value='not defined'] {
    @include applyColor($notDefinedColor);
  }
}

.inputDD {
  border: 0 !important;
  // width: fit-content;
  padding: 5px;
  &:hover:not(.inputDDdisabled) {
    @include getDDStyle();
    & .inputDDicon {
      margin-left: 5px;
      opacity: 1;
    }
  }
  .inputDDicon {
    margin-left: 5px;
    opacity: 0;
  }
}

// .rowHover {
//   &:hover {
//     .inputDD:not(.inputDDdisabled) {
//       @include getDDStyle();
//       .inputDDicon {
//         opacity: 1;
//       }
//     }
//   }
// }

// .rowSelected {
//   .inputDD:not(.inputDDdisabled) {
//     @include getDDStyle();
//     .inputDDicon {
//       opacity: 1;
//     }
//   }
// }
.hot {
  color: $hotColor;
}
.warm {
  color: $warmColor;
}
.cold {
  color: $coldColor;
}
.diaryContainer {
  max-height: calc(100% - 40px);
  overflow: auto;
}
.completedTask {
  // td:nth-child(1),
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6),
  td:nth-child(7),
  td:nth-child(8) {
    opacity: 0.5;
  }
}
.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.reasonLabelWidth {
  width: 100px;
  vertical-align: middle;
}
.reasonTd > div {
  vertical-align: middle !important;
}
.responseLabelWidth {
  max-width: 150px;
}
.responseLabelWidthForType {
  max-width: 80px;
}
//for filter date picker
.diary-filter-date-picker {
  .gQGwkZ {
    width: 100% !important;
    height: 38px !important;
    outline: none !important;
    padding-left: 10px !important;
    border: 0px !important;
    border-radius: 5px !important;
  }
  .gQGwkZ::placeholder {
    color: #808080;
  }
}
.diary-filter-date-picker {
  border: 1px solid #dbdbdb !important;
  border-radius: 5px !important;
}
.diary-filter-date-picker-selected {
  border-radius: 5px !important;
  box-shadow: 0 0 0 2px #2684ff !important;

  .gQGwkZ {
    color: #046ef2 !important;
    height: 38px !important;
  }
}
.break-spaces {
  white-space: break-spaces;
}
.timeLabel {
  font-size: 10px;
  display: inline-block;
  line-height: 12px;
  // text-align: center;
  vertical-align: middle;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */
}
.ddIsOpen {
  opacity: 1 !important;
}
.diaryLeadLink {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}
.leadSelectionFilter__menu {
  z-index: 99 !important;
}

.followUpDateRange {
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    height: 35px !important;
    margin-left: -2px;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    border: 1px solid #dbdbdb !important;
    border-radius: 5px !important;
  }
  &.selectedDateRange {
    .DateRangePickerInput {
      color: #046ef2 !important;
      border: 1px solid #2684ff !important;
      box-shadow: 0 0 0 1px #2684ff !important;
    }
    #client_date_input_start,
    #client_date_input_end {
      color: #2684ff !important;
    }
  }
  #client_date_input_start,
  #client_date_input_end {
    font-size: 16px !important;
    padding: 0px;
    font-weight: 400 !important;
    color: #787878 !important;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .DateRangePicker_picker {
    top: 40px !important;
  }

  &:not(.selectedDateRange) {
    .DateRangePickerInput {
      &:first-child {
        width: 100% !important;
        margin-left: 0;
      }
      .DateInput_1 {
        &:first-child {
          width: 100% !important;
          padding-left: 4px;
        }
        &:nth-child(3) {
          display: none;
        }
      }
    }
    .DateRangePickerInput_arrow {
      display: none;
    }
  }
}
