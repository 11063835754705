/** @format */

$text_content_color: #016ff2;
$text_label_color: #1d1e27;
$border_color: hsl(210, 12%, 90%);

.mainContainer {
  margin-top: 15px;

  .tab-view-container {
    padding-top: 5px;
    padding-bottom: 5px;
   // border-bottom: 1px solid $border_color;
    .label-color {
      color: $text_label_color;
      font-size: 15px;
      font-weight: normal;
    }
    .space-from-edge {
      margin-left: 14px;
    }
    .content-color {
      color: $text_content_color;
      font-size: 16px;
      font-weight: bold;
      text-transform: capitalize;
    }
.LeadAdditionalInfoWidth{
  max-width: 170px;
}

    .button {
      background-color: #fff;
      border: 1px solid $text_content_color;
      padding: 2px;
      color: $text_content_color;
      border-radius: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      text-align: center;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .icon-position {
    position: absolute;
    right: 295px;
    top: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    .button-circle {
      background: #016ff2;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      .icon-color {
        color: #fff;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding: 1px;
        display: flex;
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .icon-position {
    position: absolute;
    right: 275px;
    top: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    .button-circle {
      background: #016ff2;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      .icon-color {
        color: #fff;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding: 1px;
        display: flex;
        font-size: 17px;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .icon-position {
    position: absolute;
    right: 308px;
    top: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    .button-circle {
      background: #016ff2;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      .icon-color {
        color: #fff;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding: 1px;
        display: flex;
        font-size: 17px;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .icon-position {
    position: absolute;
    right: 340px;
    top: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    .button-circle {
      background: #016ff2;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      .icon-color {
        color: #fff;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding: 1px;
        display: flex;
        font-size: 17px;
      }
    }
  }
}

@media screen and (min-width: 1680px) {
  .icon-position {
    position: absolute;
    right: 380px;
    top: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    .button-circle {
      background: #016ff2;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      .icon-color {
        color: #fff;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding: 1px;
        display: flex;
        font-size: 17px;
      }
    }
  }
}

@media screen and (min-width: 1792) {
  .icon-position {
    position: absolute;
    right: 368px;
    top: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    .button-circle {
      background: #016ff2;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      .icon-color {
        color: #fff;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding: 1px;
        display: flex;
        font-size: 17px;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .icon-position {
    position: absolute;
    right: 408px;
    top: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    .button-circle {
      background: #016ff2;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      .icon-color {
        color: #fff;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding: 1px;
        display: flex;
        font-size: 17px;
      }
    }
  }
}

@media screen and (min-width: 2048px) {
  .icon-position {
    position: absolute;
    right: 435px;
    top: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    .button-circle {
      background: #016ff2;
      border-radius: 100%;
      width: 21px;
      height: 21px;
      .icon-color {
        color: #fff;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        padding: 1px;
        display: flex;
        font-size: 17px;
      }
    }
  }
}

.content-color {
  color: #016ff2;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
}

.edit__client {
  padding: 0px;
  margin-left: 15px;
  margin-top: -6px;

  i {
    position: relative;
    color: #016ff2;
  }
}
.clientDetails {
  width: 100% !important;
  .parent-scroller-container {
    height: fit-content !important;
  }
  .icon-position {
    display: none;
  }
}
.parent-scroller-container {
  overflow-y: scroll;
  // border-left: 1px solid #e5e5e5;
  height: calc(100vh - 89px);
  padding: 0 10px 0 10px;

  &.changeHeight {
    height: calc(100vh - 140px);
  }
  .description-container {
    margin-top: 10px;
    .container-style {
      border-radius: 5px;
      background-color: #f4f5f6;
      padding-right: 30px;
    }
    .icon-edit {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: relative;
      top: 28px;
      right: 6px;
      color: #016ff2;
    }
    .icon-pointer {
      cursor: pointer;
    }
  }
}

.payment-scroller-readonly {
  @extend .parent-scroller-container;
  height: calc(100vh - 136px);
}

.loader-spinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.schedule-table-wrapper {
  border: 1px solid #e2e2e3;
  max-width: 100%;
  display: block;
  border-radius: 4px;
  font-weight: normal;
  margin-top: 15px;
  .main-body {
    display: block;
    overflow: auto;
    height: calc(100vh - 230px);
    &.changeHeight {
      height: calc(100vh - 230px);
    }
    .loader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .image {
      margin: 0 auto;
      display: flex;
      margin-top: 100px;
      width: 300px;
      height: 250px;
    }
  }
  .header-wrapper {
    background-color: #f9fafb;
    border-bottom: 1px solid #e2e2e3;
    width: 100%;
    display: block;
    th {
      height: 40px;
      padding: 5px;
      font-weight: bold;
      font-size: 15px;
      color: #323232;
    }
  }

  tbody td {
    height: 40px;
    padding: 5px;
    font-size: 14px;
    color: #333333;
  }
}

// Globals
.client-text-color {
  color: #23242c;
}

.sub__heading_c {
  font-size: 18px !important;
}

.modal-body {
  padding: 20px 30px 30px 30px;
}

.main__customerDetails {
  font-size: 15px;

  .col-md-9.client-text-color.font-weight-bold.client-text-color {
    color: #016ff2;
  }
}
.use-flex {
  flex: 0 0 20%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  max-width: 25%;
  padding-left: 0 !important;
}

.generate-loader-height {
  height: 27px;
}

.additionalContentWidth{
  width: max-content;
}
.uploadedFileName{
  width: 100%;
  display:inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 14px;
  // white-space: nowrap;
}
// .uploadedFileWrapper{
//   width: 120px;
//   overflow-wrap: break-word;
// }


.read-only-mode {
    pointer-events: none;
}
