/** @format */

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}
.resizer:hover,
.resizing {
  border-right: 2px dotted gray;
}

//to show button on hover, set 'showOnHover = true' in config column
.rowHover {
  &:hover {
    .showOnHover {
      & > div {
        opacity: 1 !important;
      }
    }
  }
}

.table-loader-container {
  margin: 20px 0 20px 10px;
  color: #016ff2;
}

//to show button on row select, set 'showOnRowSelect = true' in config column

.rowSelected {
  .showOnSelect {
    & > div {
      opacity: 1 !important;
    }
  }
}

.slider-img-wrap {
  width: 50px;
  height: 50px;
  overflow: hidden;
  .not-found-wrap {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    .not-found-img {
      width: auto;
      height: 50px;
    }
  }
  .img-card-cus {
    height: 50px;
    width: 100%;
  }
}

.not-found-wrap {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  .not-found-img {
    width: auto;
    height: 100px;
  }
}

.disabled-checkbox-icon {
  color: #cccdd0;
  font-size: 14.5px;
  cursor: not-allowed;
}
