/** @format */

.dash-parent {
  color: #555555;
  /* margin: 100px 0 0 120px; */
  border: 1px solid #454545;
}
.dash-container > h4 {
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
  padding: 10px 0 10px 14px;
  color: #1d1d26;
}

.dash-container > h5 {
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
  padding: 10px 0 10px 14px;
  font-weight: 300;
}
.dash-child {
  padding: 0 0 0 14px;
}
.dashboard-sec {
  /* width: 90%;
    margin: 30px auto; */
}

.dash-container-parent {
  border: 1px solid #dddd;
  border-radius: 3px;
  margin-top: 20px;
}

.dash-container-parent > h4 {
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
  padding: 10px 0 10px 14px;
  font-weight: 300;
}

.dash-container {
  border: 1px solid #dddd;
  border-radius: 3px;
}
.element {
  position: relative;
  padding-left: 25px;
}
.element > span {
  color: white;
  position: absolute;
  left: 0px;
  top: 4px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
}
.element:hover {
  color: rgb(104, 102, 102);
  cursor: pointer;
}

ul {
  list-style: none;
}

.prop-child li::before {
  content: '•';
  color: #cacaca;
  display: inline-block;
  width: 1em;
  margin-bottom: 5px;
  margin-left: -1em;
}
