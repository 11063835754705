/** @format */

.modal-action-size {
  max-width: 700px !important;
  width: 100% !important;
}

.call__logs__container {
  position: relative;

  h4 {
    position: relative;
  }

  i {
    position: absolute;
    font-size: 22px;
    color: #909090;
    z-index: 20;
    cursor: pointer;
    top: 0px;
    right: 0px;
  }
}

.customer-phone-number {
  position: relative;

  i {
    position: relative;
    font-size: 18px;
    margin-right: 5px;
    top: 2px;
    color: #006ff2;
  }

  img {
    width: 25px;
    margin-right: 5px;
  }
}

.flex__call__wrap {
  .call__logs__scroll {
    // margin-top: 20px;
    margin-bottom: 30px;
    max-height: 482px;
    min-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 35px;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ccc;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ccc;
    }
  }

  .main__call__logs {
    position: relative;
    padding: 15px;
    padding-left: 50px;
    margin-bottom: 15px;
    margin-top: 15px;
    min-height: 40px;
    box-shadow: 0px 1px 10px 5px #ececec;
    border-radius: 10px;

    .callLogs__icon {
      position: absolute;
      left: 15px;
      z-index: 1;
      top: 16px;

      img {
        width: 25px;
      }
    }

    .callLogs__content {
      position: relative;
      color: #1d1e1f;
      font-weight: bold;
      font-size: 14px;

      span {
        margin-right: 2px;
        color: #006ff2;

        &.phoneNumber{
          color: #000;
          font-size: 12px;
        }
      }

      p {
        font-size: 12px;
        width: fit-content;
        border: 1px solid #fff;
        color: #888888;
        border-radius: 18px;
        font-weight: lighter;
      }
    }

    .callLogs__time {
      position: absolute;
      right: 15px;
      font-size: 12px;
      top: 15px;
      color: #888888;
    }
  }

  .container-body {
    margin-top: 20px;

    .call-connect {
      position: relative;
      font-size: 14px;
      border: 0px solid #eff0f0;
      font-weight: bold;
      color: #006ff2;
      border-radius: 25px;
      padding-left: 35px;
      width: max-content;
      // margin: auto;
      margin-bottom: 20px;

      span {
        font-weight: 400;
        color: #000;
        font-weight: bold;
        border: 1px solid #ccc;
        padding: 5px 10px;
        border-radius: 15px;
        cursor: pointer;
      }

      .call__icons {
        position: absolute;
        top: -2px;
        left: 4px;

        img {
          width: 25px;
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .call-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;

    img {
      width: 300px;
    }
  }
}
.borderLine{
  border-bottom: 2px solid #edebeb;
  width: 96%;
  margin-bottom: 1%;
}

.colNotDataFound{color: #046ef2;
  font-size: 16px;
  margin-top: -3%;
  text-transform: capitalize;}
