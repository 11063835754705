/** @format */

.comment-container {
  display: flex;
  flex-direction: row;
  margin: 20px 0 20px 0;
  .icon-size {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
  .user-container {
    margin-left: 10px;
    .user-name-title {
      font-size: 15px;
      color: #333333;
      .date-title {
        margin-left: 10px;
        font-size: 12.5px;
        font-weight: normal;
        color: #999999;
      }
    }

    .comment-section {
      color: #333333;
      font-size: 14px;
      font-family: 'Arial';
      padding-top: 3px;
    }
  }
}
