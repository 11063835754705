.sub-menu {
  .menu-item {
    position: relative;
  }
  .menu-item.selected a{
    color: #007bff;
  }
  .menu-item:first-child a{
    padding-left: 3px;
  }

}
