/** @format */

.payment-ranger {
  display: grid;
  grid-template-columns: 40px 300px;
  border: 1px solid #b3b3b3;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  background: #fff;
  font-size: 14px;
  border-radius: 3px;
  .currency-border {
    border-right: 1px solid #b3b3b3;
    color: #808080;
    font-weight: bold;
  }
  .size-border {
    border-right: 1px solid #b3b3b3;
    //color: #808080;
    color: #4e4e4e;
    font-weight: bold;
    width: 45px;
  }
  .button_text {
    margin-left: 10px;
    //color: #808080;
    width: 425px;
  }

  .button_text_black {
    margin-left: 10px;
    color: #000;
  }
}

.payment-duration-plan {
  display: grid;
  grid-template-columns: 68px 300px;
  border: 1px solid #b3b3b3;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  background: #fff;
  font-size: 14px;
  border-radius: 3px;
  .currency-border {
    border-right: 1px solid #b3b3b3;
    color: #808080;
    font-weight: bold;
  }
  .size-border {
    border-right: 1px solid #b3b3b3;
    color: #808080;
    font-weight: bold;
    width: 65px;
  }
  .size-border-focus {
    border-right: 1px solid #999999;
    color: #999999;
    font-weight: bold;
    width: 65px;
  }
  .button_text {
    margin-left: 10px;
    color: #808080;
  }

  .button_text_black {
    margin-left: 10px;
    color: #999999;
  }
}

.error-show-font {
  color: #ff2420;
  font-size: 15px;
  margin-top: 5px;
}

.ranger-payment-plan-body {
  background: #fff;
  position: absolute;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 20px;
  width: 340px;
  height: 124px;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 6px 10px 0px;
  .label {
    color: #333;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .input-field {
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
    margin-bottom: 5px;
    align-items: center;
    color: #000;
    display: block;
    font-size: 14px;
    font-weight: bold;
    width: 90%;
    &:focus {
      outline: none;
    }
  }
  .below-label {
    color: #046ef2;
    font-size: 13px;
    padding-left: 0px;
    margin-top: 10px;
    padding-right: 13px;
  }
  .button-container {
    text-align: right;
    bottom: 20px;
    right: 20px;
    position: absolute;
    font-size: 18px;
    .button {
      background-color: #046ef2;
      border: 1px solid #046ef2;
      color: rgb(255, 255, 255);
      border-radius: 5px;
      padding: 3px 7px;
    }
  }
}

.ranger-modal-body {
  background: #fff;
  position: absolute;
  margin-top: 10px;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 12px 15px;
  //width: 340px;
  //height: 235px;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 6px 10px 0px;
  .label {
    color: #333;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .input-field {
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
    margin-bottom: 5px;
    align-items: center;
    color: #000;
    display: block;
    font-size: 14px;
    font-weight: bold;
    width: 90%;
    &:focus {
      outline: none;
    }
  }
  .below-label {
    color: #046ef2;
    font-size: 13px;
    padding-left: 0px;
    margin-top: 10px;
    padding-right: 13px;
  }
  .button-container {
    display: flex;
    justify-content: right;
    padding-top: 10px;
    .button {
      background-color: #046ef2;
      border: 1px solid #046ef2;
      color: rgb(255, 255, 255);
      border-radius: 5px;
      padding: 3px 7px;
    }
  }
}

.input-range__track--active {
  background: #046ef2 !important;
}

.input-range__slider {
  background: #046ef2 !important;
  border: 1px solid #046ef2 !important;
}

.input-range__label-container {
  display: none !important;
}

.disabled-focus-text {
  color: #999999;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f2f2f2;
  opacity: 1;
}
.officeMulti {
  .css-1hwfws3 {
    max-height: 65px !important;
    overflow-y: scroll !important;
  }
  .css-g1d714-ValueContainer {
    max-height: 65px !important;
    overflow-y: scroll !important;
  }
}
.accessMulti {
  .css-g1d714-ValueContainer {
    max-height: 65px !important;
    overflow-y: scroll !important;
  }
  .css-1hwfws3 {
    max-height: 65px !important;
    overflow-y: scroll !important;
  }
}
.disableRanger{
  background: #f2f2f2;
}

.over-ride-class .css-26l3qy-menu {
  position: absolute !important;
  z-index: 1111 !important;
}
