
.flex-btns{
  display: flex;
  flex-direction: row;
  .updateInfo{
    margin-left :10px;
  }
}
.joint-applicant{
  margin-top: 15px;
}
.applicant-form {
  border: 1px solid #e2e2e3;
  padding: 0px 15px 0px 15px;
}
.input__main{
  margin-bottom: 25px;
}
.bank-info{
  align-items: center;
}
.applicants-form-body {

  .filterInputWrap {
    font-size: 14px;
    min-height: 38px;
    border-radius: 4px !important;
    border-color: #cccccc;
    z-index: 0 !important;
    position: unset !important;
    margin-bottom: 10px;
    .form-control{
      border-radius: 4px!important;
      border-color: #eaeaea;
      font-size: 14px;
      &.invalid {
        border-color: red;
      }
    }
  }

  .applicant-form{
    margin-top: 15px;
  }
  .update-applicant{
    margin-bottom: 15px;
  }
  .applicant-no{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      font-size: 1.25rem;
    }
    .icon-main {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
    // font-weight: normal !important;
  }
  .main-title {
    color: #2b2c33;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .field-wrapper {
    line-height: 30px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    .title {
      color: #494a50;
      font-size: 15px;
    }
    .sub-title {
      color: #016ff2;
      font-weight: bold;
      font-size: 14px;
    }
  }
  label {
    font-size: 12px;
  }
  .bankDetails{
    flex-flow: column;
  }
}
.investment-form-body {
    .main-title {
      color: #2b2c33;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .field-wrapper {
      line-height: 30px;
      margin-bottom: 10px;
      width: 100%;
      .title {
        color: #494a50;
        font-size: 15px;
        font-weight: 500;
      }
      .sub-title {
        color: #016ff2;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
