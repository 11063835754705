.attachment-main-container {
    padding: 0 10px 0 10px;
    height: auto !important;
    .attachment-modal-header {
      margin-bottom: 20px;
      .attachment-header-title {
        font-size: 22px;
        font-weight: bold;
        color: #222222;
      }
    }
  
    .main-attachment-body {
      margin-top: 10px;
      .attachment-body-edges {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(192, 192, 192)' stroke-width='3' stroke-dasharray='5%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        padding: 20px;
        display: inline-block;
        width: 100%;
        border-radius: 5px;
        .attachment-body {
          padding: 30px 0 30px 0;
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .upload-icon-size {
            width: 35px;
            height: 35px;
          }
          .drag-drop-text {
            font-size: 13px;
            color: #333333;
            padding: 8px 0 0 0;
          }
          .attached-file-text {
            font-size: 14px;
            font-weight: 600;
            color: #016ff2;
            cursor: pointer;
          }
        }
        &.dropdown-overlay {
          background-color: #f3f3f3cc;
          height: 197px;
          opacity: 0.4;
          .drag-drop-overlay-text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex: 1;
            height: 100%;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
    .attachment-upload-container {
      margin: 40px 0 5px 0;
      text-align: center;
      .attachment-upload-button {
        background-color: #016ff2;
        color: #fff;
        border-radius: 50px;
        width: 150px;
        line-height: 35px;
        border: none;
      }
      .attachment-outline {
        @extend .attachment-upload-button;
        color: #016ff2;
        background: #fff;
        border: 1px solid #016ff2;
      }
    }
    .attached-container {
      width: 100%;
      margin-top: 15px;
      overflow-y: auto;
      height: calc(100vh - 498px);
    }
  }